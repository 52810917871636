import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LoginService } from "../../../pages/login/login.service";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-gym-nav',
  templateUrl: './gym-nav.component.html',
  styleUrls: ['./gym-nav.component.scss'],
  providers: [LoginService]
})
export class GymNavComponent implements OnInit {
  public showItem: boolean = false;
  isLoggedIn: any;

  constructor(public service: LoginService,  public afAuth: AngularFireAuth, ) {
    this.afAuth.authState.subscribe(user => {
      this.isLoggedIn = localStorage.getItem('user');
    })
  }

  ngOnInit() {
  }

}
