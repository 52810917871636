import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Products } from '../shop/product.model';
import { ProductsService } from '../shop/products.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../shared/service/email.service';

@Component({
  selector: 'app-quick-purchase',
  templateUrl: './quick-purchase.component.html',
  styleUrls: ['./quick-purchase.component.scss'],
  providers: [EmailService]
})
export class QuickPurchaseComponent implements OnInit {
  rfid: any;
  public products: Products[] = [];
  public items: Products[] = [];
  public allItems: Products[] = [];
  public tagsFilters: any[] = [];
  public tags: any[] = [];
  public categoryFilters: any[] = [];
  public category: any[] = [];
  public colors: any[] = [];
  public sortByOrder: string = '';   // sorting
  public animation: any;   // Animation

  lastKey = ''      // key to offset next query from
  finished = false  // boolean when end of data is reached

  sidebaron: boolean = false;
  show: boolean = false;
  open: boolean = false;
  public listView: boolean = false;
  //Grid 4
  public col_xl_3: boolean = true;
  //Grid 3
  public col_xl_4: boolean = true;
  //Grid 2
  public col_md_6: boolean = true;
  //List View
  public col_xl_12: boolean = false;
  public gridOptions: boolean = true;
  public active: boolean = false;
  userData: any;
  orderData = [];
  selectedProducts = [];
  totalPrice = 0;

  isLoggedIn = false;
  paymentMsg = '';
  isDisabled = false;
  userName = '';
  userImage: any;
  rfidUserData: any;
  closeResult: string;

  constructor(private route: ActivatedRoute, private emailService: EmailService,
    public productsService: ProductsService, public toastService: ToastrService, private modalService: NgbModal,
    private firestore: AngularFirestore,) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    if (this.userData) this.getUsersUpdatedData();
  }

  ngOnInit() {
    this.productsService.getProducts().subscribe(products => {
      // this.items = products.filter(a => a.category == "Consumables");
      // this.products = products.filter(a => a.category == "Consumables").slice(0, 8);
      this.items = products;
      this.products = products.slice(0, 8);
    })
    this.getOrdersData();
  }

  getOrdersData() {
    this.orderData = [];
    this.firestore.collection('orders', ref => ref.where('clubId', '==', this.userData.clubId).orderBy("orderDate", "desc")).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.orderDate = new Date(sData.orderDate.seconds * 1000);
          sData.id = doc.id;
          this.orderData.push(sData);
        });
      });
  }

  // Initialize filetr orders
  public filterOrders(): any[] {
    return this.userData.roles == "clubAdmin" ? this.orderData : this.orderData.filter(a => a.uid == this.userData.uid);
  }

  // Initialize filetr Items
  public filterItems(): Products[] {
    return this.items.filter((item: Products) => {
      return (this.tagsFilters.length > 0 ? this.tagsFilters.indexOf(item.brand) > -1 : true) && (this.categoryFilters.length > 0 ? this.categoryFilters.indexOf(item.category) > -1 : true);
    });
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
  }

  openFilter() {
    if (this.show == true && this.sidebaron == true) {
      this.show = false
      this.sidebaron = false
    }
    else {
      this.show = true
      this.sidebaron = true
    }
  }

  openMediaFilter() {
    if (this.show == false && this.sidebaron == false && this.open == false) {
      this.show = true
      this.sidebaron = true
      this.open = true
    } else {
      this.show = false
      this.sidebaron = false
      this.open = false
    }
  }

  listOpen() {
    this.gridOptions = false
    this.listView = true;
    this.col_xl_12 = true;
  }

  grid2() {
    this.gridOptions = true;
    this.col_md_6 = true;
    this.col_xl_3 = false;
    this.listView = false
    this.col_xl_4 = false;
    this.col_xl_12 = false;

  }

  grid3() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_4 = true;
    this.col_xl_3 = false;
    this.col_md_6 = true;
    this.col_xl_12 = false;
  }

  grid4() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_3 = true;
    this.col_md_6 = true;
    this.col_xl_4 = false;
    this.col_xl_12 = false;
  }

  selectProduct(product, event) {
    this.totalPrice = 0;
    if (event)
      this.selectedProducts.push(product)
    else {
      this.selectedProducts.forEach((item, index) => {
        if (item === product) this.selectedProducts.splice(index, 1);
      });
    }
    this.selectedProducts.forEach(e => {
      this.totalPrice += e.price;
    });
  }

  submitDetails(rfid) {
    this.isDisabled = true;
    this.isLoggedIn = false;
    this.rfidUserData = {};
    if (!rfid) {
      this.toastService.error('Enter RFID', 'Error');
      this.isDisabled = false;
    } else if (this.selectedProducts.length == 0) {
      this.toastService.error('Please select product', 'Error');
      this.rfid = '';
      this.isDisabled = false;
    }
    else {
      this.firestore.collection("users", ref => ref.where('club_rfid', '==', this.userData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.toastService.error("User not found. Please contact site administrator.", 'Error');
            this.rfid = '';
            this.isDisabled = false;
          }
          else {
            snapshot.forEach(doc => {
              let user = doc.data();
              this.rfidUserData = doc.data();
              let totalprice = this.totalPrice * 1;
              if (user.balance > 0 && user.balance >= totalprice) {
                this.loop('credit');
                this.isDisabled = false;
                this.rfid = '';
                this.toastService.success("Your order has been placed successfully.", 'Success');
              } else {
                this.isLoggedIn = true;
                this.paymentMsg = "Not enough credit for order. Please use below options.";
                this.isDisabled = false;
                this.rfid = '';
                this.userName = user.firstName + ' ' + user.lastName;
                this.userImage = user.imageUrl ? user.imageUrl : '';
              }
            });
          }
        });
    }
  }

  topUpAmount(amount) {
    this.isDisabled = true;
    this.addCashTransaction(this.rfidUserData, amount);
    this.firestore.collection("users").doc(this.rfidUserData.uid).update({
      balance: this.rfidUserData.balance + amount
    }).then(res => {
      this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get()
        .subscribe(snapshot => {
          snapshot.forEach(doc => {
            this.rfidUserData = doc.data();
            this.loop('credit');
            this.isDisabled = false;
            this.userName = '';
            this.userImage = '';
            this.rfid = '';
            this.isLoggedIn = false;
            this.paymentMsg = '';
            this.toastService.success("Your order has been placed successfully.", 'Success');
          });
        });
    }).catch(err => {
      this.toastService.error(err, 'Error');
    });
  }

  payInCash() {
    this.isDisabled = true;
    this.addCashTransaction(this.rfidUserData, this.totalPrice);
    this.firestore.collection("users").doc(this.rfidUserData.uid).update({
      balance: this.rfidUserData.balance + this.totalPrice
    }).then(res => {
      this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get()
        .subscribe(snapshot => {
          snapshot.forEach(doc => {
            this.rfidUserData = doc.data();
            this.loop('cash');
            this.isDisabled = false;
            this.userName = '';
            this.userImage = '';
            this.rfid = '';
            this.isLoggedIn = false;
            this.paymentMsg = '';
            this.toastService.success("Your order has been placed successfully.", 'Success');
          });
        });
    }).catch(err => {
      this.toastService.error(err, 'Error');
    });
  }

  payLater() {
    this.isDisabled = true;
    this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.rfidUserData = doc.data();
          this.loop('payLater');
          this.isDisabled = false;
          this.userName = '';
          this.userImage = '';
          this.rfid = '';
          this.isLoggedIn = false;
          this.paymentMsg = '';
          this.toastService.success("Your order has been placed successfully.", 'Success');
        });
      });
  }

  ngAfterViewInit() {
    setTimeout(() => this.rfid ? this.rfid.nativeElement.focus() : null);
  }

  openModel(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  goBack() {
    this.isDisabled = false;
    this.userName = '';
    this.userImage = '';
    this.rfid = '';
    this.isLoggedIn = false;
    this.paymentMsg = '';
    this.ngAfterViewInit();
  }

  async loop(transactionType) {
    for (let i = 0; i < this.selectedProducts.length; i++) {
      let data = await this.getuserData().toPromise();
      data.forEach(e => {
        this.rfidUserData = e.data();
      });
      await this.loop2(this.selectedProducts[i], transactionType);
      if (i == (this.selectedProducts.length - 1)) {
        this.selectedProducts = [];
        this.totalPrice = 0;
      }
    }
  }

  getuserData() {
    return this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get();
  }

  async loop2(selectedProducts, transactionType) {
    let orderId = await this.addNewOrder(this.rfidUserData, selectedProducts, 1, selectedProducts.price);
    await this.updateUserBalance(this.rfidUserData, selectedProducts.price);
    if (transactionType == 'payLater')
      await this.addPaylaterTransaction(this.rfidUserData, selectedProducts.price, orderId);
    else
      await this.addTransaction(this.rfidUserData, selectedProducts.price, orderId);
    await this.updateProductStock(selectedProducts, 1);
    await this.getOrdersData();
  }

  async addNewOrder(user, product, totalQty, totalAmount) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('orders').add({
        uid: user.uid,
        clubId: user.clubId,
        name: user.firstName + ' ' + user.lastName,
        RFID: user.RFID,
        email: user.email,
        orderDate: new Date(),
        totalQuantity: totalQty,
        totalAmount: totalAmount,
        product: product
      }).then(result => {
        this.emailService.postOrderSuccessMail(user.email, user.firstName + ' ' + user.lastName, product, totalQty, totalAmount, this.getDateTime(new Date()));
        resolve(result.id);
      }).catch(error => {
        resolve('');
      });
    });
  }

  async addNewGuestOrder(clubId, product, totalQty, totalAmount) {
    return new Promise((resolve, reject) => {
      this.firestore.collection('orders').add({
        uid: "",
        clubId: clubId,
        name: "Parent or carer",
        RFID: "",
        email: "",
        orderDate: new Date(),
        totalQuantity: totalQty,
        totalAmount: totalAmount,
        product: product
      }).then(result => {
        resolve(result.id);
      }).catch(error => {
        resolve('');
      });
    });
  }

  async updateUserBalance(user, totalAmount) {
    this.firestore.collection("users").doc(user.uid).update({
      balance: user.balance - totalAmount
    })
  }

  async addTransaction(user, totalAmount, orderId) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'wallet',
      paymentType: 'debit',
      status: 'success',
      amount: totalAmount,
      openingBalance: user.balance,
      closingBalance: user.balance - totalAmount,
      clubId: user.clubId,
      sessionId: '',
      orderId: orderId,
      details: "Order placed"
    })
    this.emailService.postSendDebitMail(user.email, user.balance - totalAmount, totalAmount, user.firstName + ' ' + user.lastName)
  }

  async addCashTransaction(user, totalAmount) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'cash',
      paymentType: 'credit',
      status: 'success',
      amount: totalAmount,
      openingBalance: user.balance,
      closingBalance: user.balance + totalAmount,
      clubId: user.clubId,
      sessionId: '',
      orderId: '',
      details: "Top up amount credited into your wallet balance"
    })
    this.emailService.postSendCreditMail(user.email, user.balance + totalAmount, totalAmount, user.firstName + ' ' + user.lastName)
  }

  addPaylaterTransaction(user, amount, orderId) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'payLater',
      paymentType: 'debit',
      status: 'success',
      amount: amount,
      openingBalance: user.balance,
      closingBalance: user.balance - amount,
      clubId: user.clubId,
      sessionId: '',
      orderId: orderId,
      details: "Order placed"
    });
    this.emailService.postSendDebitMail(user.email, user.balance - amount, amount, user.firstName + ' ' + user.lastName);
  }

  async updateProductStock(product, totalQty) {
    this.firestore.collection("products").doc(product.id).update({
      stock: product.stock - totalQty
    })
  }

  getDateTime(date: Date) {
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }


  async payInCashGuest() {
    if (this.selectedProducts.length > 0) {
      if (confirm("Are you sure want to proceed with cash payment?")) {
        for (let i = 0; i < this.selectedProducts.length; i++) {
          let orderId = await this.addNewGuestOrder(this.userData.clubId, this.selectedProducts[i], 1, this.selectedProducts[i].price);
          await this.addCashTransactionGuest(this.selectedProducts[i].price, this.userData.clubId, orderId);
          await this.updateProductStock(this.selectedProducts[i], 1);
          await this.getOrdersData();
          if (i == (this.selectedProducts.length - 1)) {
            this.selectedProducts = [];
            this.totalPrice = 0;
          }
        }
        this.toastService.success("Your order has been placed successfully.", 'Success');
      }
    } else {
      this.toastService.error('Please select product', 'Error');
    }
  }

  async addCashTransactionGuest(totalAmount, clubId, orderId) {
    this.firestore.collection("transactions").add({
      uid: "",
      name: "Parent or carer",
      email: "",
      RFID: "",
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'cash',
      paymentType: 'credit',
      status: 'success',
      amount: totalAmount,
      openingBalance: 0,
      closingBalance: 0,
      clubId: clubId,
      sessionId: '',
      orderId: orderId,
      details: "Order placed"
    })
  }

  async userOrderNow() {
    if (this.selectedProducts.length > 0) {
      if (confirm("Are you sure want to proceed with order payment?")) {
        let data = await this.getMemberUserData().toPromise();
        let userDetails;
        data.forEach(e => {
          userDetails = e.data();
        });
        if (userDetails.balance >= this.totalPrice) {
          for (let i = 0; i < this.selectedProducts.length; i++) {
            await this.memberloop2(userDetails, this.selectedProducts[i]);
            if (i == (this.selectedProducts.length - 1)) {
              this.selectedProducts = [];
              this.totalPrice = 0;
              this.getUsersUpdatedData();
              this.toastService.success("Your order has been placed successfully.", 'Success');
            }
          }
        } else {
          this.toastService.error("Insufficient wallet balance", 'Error');
        }
      }
    } else {
      this.toastService.error('Please select product', 'Error');
    }
  }

  async memberloop2(userData, selectedProducts) {
    let orderId = await this.addNewOrder(userData, selectedProducts, 1, selectedProducts.price);
    await this.updateUserBalance(userData, selectedProducts.price);
    await this.addTransaction(userData, selectedProducts.price, orderId);
    await this.updateProductStock(selectedProducts, 1);
    await this.getOrdersData();
  }

  getMemberUserData() {
    return this.firestore.collection("users", ref => ref.where('uid', '==', this.userData.uid).limit(1)).get();
  }

  getUsersUpdatedData() {
    this.firestore.collection('users', ref => ref.where('uid', '==', this.userData.uid).limit(1)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.userData = doc.data();
        });
      });
  }

  // public increment(product) {
  //   product.quanity += 1;
  // }

  // public decrement(product) {
  //   if (product.quanity > 0) {
  //     product.quanity -= 1;
  //   }
  // }
}

