<div class="collection-mobile-back">
  <span class="filter-back" (click)="toggleFilter()">
    <i class="fa fa-angle-left" aria-hidden="true"></i> back
  </span>
</div>
<div class="collection-collapse-block top_filter border-0 open">
  <h3 class="collapse-block-title">Category</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <div class="custom-control custom-checkbox collection-filter-checkbox" *ngFor="let filter of categoriesFilters">
        <input type="checkbox" class="custom-control-input" [value]="filter.category" (change)="checkedFilter($event)"
          id="{{filter.category}}">
        <label class="custom-control-label" for="{{filter.category}}">{{filter.category}}</label>
      </div>
    </div>

  </div>
</div>