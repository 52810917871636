<!-- Nav Start-->
<!-- <header class="ecommerce loding-header nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav class="nav-ctm">
                    <a href="javascript:void()" class="logo-erapper"><img style="height: 50px;" alt="" src="assets/images/logo/5.png"
                            class="img-fluid"></a> -->
<app-menu></app-menu>
<!-- <div class="top-header-right">
                        <ul>
                            <li *ngIf="isLoggedIn != 'null'" class="cart text-white">
                                <a>
                                    <i class="icon-shopping-cart-full" (click)="showItem = !showItem"></i>
                                </a>
                                <span class="cart_qty_cls">{{shoppingCartItems.length}}</span>
                                <div class="dropdown-menu dropdown-menu-right" [class.show]="showItem">
                                    <ul class="shopping-cart" *ngIf='shoppingCartItems.length'>
                                        <li *ngFor="let item of shoppingCartItems">
                                            <div class="media">
                                                <a
                                                    [routerLink]="['/e-commerce/left-sidebar/product/', item.product.id]"><img
                                                        class="mr-3" [src]="item.product.img"
                                                        alt="Generic placeholder image"></a>
                                                <div class="media-body">
                                                    <a [routerLink]="['/home/left-sidebar/product', item.product.id]">
                                                        <h4>{{ item.product.name }}</h4>
                                                    </a>
                                                    <h4><span>{{ item.quantity }} x
                                                            {{ item.product.price | currency:productService?.currency:'symbol' }}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class="close-circle">
                                                <a (click)="removeItem(item)"><i class="fa fa-times"
                                                        aria-hidden="true"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="total">
                                                <h5>subtotal :
                                                    <span>{{ getTotal() | async | currency:productService?.currency:'symbol'}}</span>
                                                </h5>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="buttons">
                                                <a [routerLink]="'/e-commerce/cart'" class="view-cart">view cart</a>
                                                <a [routerLink]="'/e-commerce/checkout'" class="checkout">checkout</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li *ngIf="isLoggedIn != 'null'" class="account ">
                                <a aria-expanded="false" data-toggle="dropdown" href="#" id="dropdownMenuButton2">
                                    <i class="icon-user"></i>
                                </a>
                                <div aria-labelledby="dropdownMenuButton2"
                                    class="dropdown-menu show dropdown-menu-right">
                                    <a class="dropdown-item" [routerLink]="['/page/user/account']"><i
                                            class="fa fa-2x fa-user-circle mr-2"></i>Account</a>
                                    <a class="dropdown-item" [routerLink]="['/page/membership']"><i
                                            class="fa fa-2x fa-id-card mr-2"></i>Membership</a>
                                    <a class="dropdown-item" [routerLink]="['/page/medical-info']"><i
                                            class="fa fa-2x fa-medkit mr-2"></i>Medical
                                        History</a>
                                    <a class="dropdown-item" href="#"><i
                                            class="fa fa-2x fa-credit-card mr-2"></i>Wallet</a>
                                    <a class="dropdown-item" [routerLink]="['/shop/checkout']"><i
                                            class="fa fa-2x fa-cart-plus mr-2"></i>Checkout</a>
                                    <a class="dropdown-item" (click)="loginService.SignOut()"><i
                                            class="fa fa-2x fa-sign-out mr-2"></i>Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header> -->
<!-- Nav end-->