import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MembershipService } from './membership.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
  providers: [MembershipService]
})
export class MembershipComponent implements OnInit {

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  gender = ['Male', 'Female'];
  age = ['Above 18', 'Under 18'];
  userData = [];
  docId: any;
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();

  constructor(private _formBuilder: FormBuilder, private service: MembershipService, public toastService: ToastrService, private firestore: AngularFirestore) {
    this.fourthFormGroup = this._formBuilder.group({
      ethnicity: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      consent: [''],
      // memberName: ['', Validators.required],
      age: ['', Validators.required]
    });
    this.sixthFormGroup = this._formBuilder.group({
      consent: [''],
      //accept: ['', Validators.requiredTrue],
    });
  }

  ngOnInit() {
    this.getMembership();
  }

  getMembership() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.firestore.collection('membership', ref => ref.where('userId', '==', user.uid)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.docId = doc.id;
          this.userData.push(doc.data());
          this.fourthFormGroup.setValue({
            ethnicity: this.userData.length > 0 ? this.userData[0].ethnicity.ethnicity : ''
          });
          this.fifthFormGroup.setValue({
            consent: this.userData.length > 0 ? this.userData[0].photography.consent : '',
            // memberName: this.userData.length > 0 ? this.userData[0].photography.memberName : '',
            age: this.userData.length > 0 ? this.userData[0].photography.age : ''
          });
          this.sixthFormGroup.setValue({
            consent: this.userData.length > 0 ? this.userData[0].travelling.consent : '',
            //accept: this.userData.length > 0 ? this.userData[0].travelling.accept : ''
          });
        });
      });
  }

  submit() {
    if (this.userData.length == 0) {
      this.service.AddUserMembership(this.fourthFormGroup.value, this.fifthFormGroup.value, this.sixthFormGroup.value)
        .then(
          res => {
            this.getMembership();
            this.toastService.success('Details successfully saved!', 'Success');
            this.parentFun.emit();
          }
        )
    } else {
      this.service.UpdateUserMembership(this.fourthFormGroup.value, this.fifthFormGroup.value, this.sixthFormGroup.value, this.docId)
        .then(
          res => {
            this.toastService.success('Details successfully updated!', 'Success');
            this.parentFun.emit();
          }
        )
    }
  }

}
