<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography" style="min-width: 350px;">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Reenter Password</mat-label>
        <input matInput type="password" placeholder="Reenter Password" [(ngModel)]="password" required>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="button" class="btn btn-danger" (click)="deleteAccount()">Submit</button>
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>