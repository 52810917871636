<div class="container">
    <div class="row m-5">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header text-center">
                    <h4 class="card-title mb-0  text-danger text-uppercase">Restrict Account</h4>
                </div>
                <div class="card-body">
                    <h5 class="card-text mb-1"><i class="fa fa-check text-success"></i> Your data is safe.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-check text-success"></i> You can contact administrator to
                        revive
                        your account.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-check text-success"></i> You get notification email for
                        reviving
                        your account.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-remove text-danger"></i> You can not access your account
                        after
                        that.</h5>
                </div>
                <div class="card-footer text-center">
                    <button class="btn btn-large btn-outline-danger" (click)="openDialog('restrict')"> <i
                            class="fas fa-ban mr-1"></i>
                        Restrict
                        Account</button>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header text-center">
                    <h4 class="card-title mb-0  text-danger text-uppercase">Delete Account</h4>
                </div>
                <div class="card-body">
                    <h5 class="card-text mb-1"><i class="fa fa-check text-success"></i> You get notification email for
                        deleting
                        your account.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-remove text-danger"></i> You can not access your account
                        after
                        that.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-remove text-danger"></i> Your data will be deleted.</h5>
                    <h5 class="card-text mb-1"><i class="fa fa-remove text-danger"></i> You will
                        permanently lose your profile, bookings and photos.</h5>

                </div>
                <div class="card-footer text-center">
                    <button class="btn btn-large btn-outline-danger" (click)="openDialog('delete')"> <i
                            class="fas fa-trash mr-1"></i>
                        Delete
                        Account</button>
                </div>
            </div>

        </div>
    </div>

</div>