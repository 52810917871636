import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../../service/nav.service';
import { LoginService } from "../../../../pages/login/login.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [LoginService]
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem: string = ''
  public overlay: boolean = false;
  public showItem: boolean = false;
  isLoggedIn = false;
  isClubAdmin = false;
  isUser = false;
  isSuperAdmin = false;
  private isMobileResolution: boolean;
  logoUrl = "assets/images/logo/5.png";

  constructor(public navServices: NavService, public afAuth: AngularFireAuth, public router: Router,
    public route: ActivatedRoute, public afs: AngularFirestore, public loginService: LoginService) {
    this.afAuth.authState.subscribe(user => {
      this.isLoggedIn = user != null ? user.emailVerified : false;
    })
    if (window.innerWidth < 780) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  ngOnInit() {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
    });
  }

  redirectUrl(url) {
    this.toggleSidebar();
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }

  redirectUrlAll(url) {
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }

  toggleSidebar() {
    this.openSide = !this.openSide
  }

  closeOverlay() {
    this.openSide = false
  }

  //For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = ''
    } else {
      this.activeItem = menuItem
    }
  }

  isActive(item) {
    return this.activeItem === item
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = ''
    } else {
      this.activeChildItem = subMenu
    }
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu
  }

  deleteAccount() {
    this.toggleSidebar();
    this.router.navigate([localStorage.getItem("clubName") + "/user/delete/account"]);
  }

  sponsorship() {
    this.toggleSidebar();
    this.router.navigate([localStorage.getItem("clubName") + "/sponsorship"]);
  }
}
