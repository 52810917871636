import { Component, OnInit, Inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
declare var $: any;

@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.scss']
})
export class SponsorshipComponent implements OnInit {
  userData: any;
  sponsorship = false;
  sponsorshipForm: FormGroup;
  shareUrl = "";
  uploadedFiles: any[] = [];
  displayImages: any[] = [];
  loading = false;

  constructor(public toastService: ToastrService, private firestore: AngularFirestore, private _formBuilder: FormBuilder,
    @Inject(AngularFireStorage) private storage: AngularFireStorage) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    this.sponsorshipForm = this._formBuilder.group({
      id: [''],
      publicUrl: ['', Validators.required],
      height: ['', Validators.required],
      weight: ['', Validators.required],
      description: ['', Validators.required],
      amount: ['', Validators.required],
      facebookLink: [''],
      instagramLink: [''],
      websiteLink: [''],
      otherLink: [''], news: [''],
      strengths: [''],
      images: [],
      wins: [''], loss: [''],
      draw: [''],
      userId: [''], clubId: [''], createdDate: [''], updatedDate: ['']
    });
  }

  ngOnInit(): void {
    this.getUsersData();
  }

  async getUsersData() {
    await this.firestore.collection('users').doc(this.userData.uid).get().subscribe(docs => {
      this.userData = docs.data();
      this.sponsorship = this.userData ? (this.userData.sponsorship ? this.userData.sponsorship : false) : false;
      if (this.sponsorship) {
        this.shareUrl = this.sponsorship ? "https://app.pamit.co.uk/" + localStorage.getItem("clubName") + "/profile/" + this.userData.uid + "/" + this.userData.firstName + "-" + this.userData.lastName : "";
        this.getUserSponsorshipDetails();
        $("#socialshare").jsSocials({
          url: this.shareUrl,
          text: "Help " + this.userData.firstName + "-" + this.userData.lastName + " to raise money for sponsorship",
          showLabel: false,
          showCount: false,
          shares: ["email", "twitter", "facebook", "whatsapp"]
        });
      } else {
        $("#socialshare").jsSocials({
          shares: []
        });
      }
    });
  }

  getUserSponsorshipDetails() {
    if (this.userData) {
      this.firestore.collection('sponsorship', ref => ref.where('userId', '==', this.userData.uid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.sponsorshipForm.setValue({
              id: '', publicUrl: this.shareUrl, height: '', weight: '', description: '', facebookLink: '', instagramLink: '', websiteLink: '', amount: '', draw: 0, wins: 0, loss: 0,
              otherLink: '', strengths: '', images: [], userId: this.userData.uid, clubId: this.userData.clubId, createdDate: null, updatedDate: null, news: ''
            });
          } else {
            snapshot.forEach(doc => {
              let data = doc.data();
              data.id = doc.id;
              this.sponsorshipForm.setValue({
                id: data.id, publicUrl: data.publicUrl, height: data.height, weight: data.weight, description: data.description, facebookLink: data.facebookLink,
                amount: data.amount, instagramLink: data.instagramLink, websiteLink: data.websiteLink, otherLink: data.otherLink, strengths: data.strengths, images: data.images,
                userId: data.userId, clubId: data.clubId, createdDate: data.createdDate, updatedDate: data.updatedDate, draw: data.draw ? data.draw : 0, news: data.news ? data.news : '',
                wins: data.wins ? data.wins : 0, loss: data.loss ? data.loss : 0
              });
              this.displayImages = data.images;
            });
          }
        });
    }
  }

  userUpdate(event) {
    let url = this.sponsorship ? "https://app.pamit.co.uk/" + localStorage.getItem("clubName") + "/profile/" + this.userData.uid + "/" + this.userData.firstName + "-" + this.userData.lastName : "";
    this.firestore.doc('users/' + this.userData.uid).update({
      sponsorship: event.checked,
    }).then(res => {
      if (event.checked) {
        this.shareUrl = url;
        this.toastService.success('Sponsorship activated', 'Success');
        this.getUsersData();
      } else {
        this.toastService.success('Sponsorship disabled', 'Success');
        this.shareUrl = "";
        $("#socialshare").jsSocials({
          shares: []
        });
      }
    }).catch(error => {
      this.toastService.error(error, 'Error');
    })
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  async saveSponsorship() {
    let data = this.sponsorshipForm.value; let count = 0;
    if (this.uploadedFiles.length > 0) {
      for (let file of this.uploadedFiles) {
        const fileRef = this.storage.ref('/sponsorship/' + this.userData.uid + '/' + file.name);
        await this.storage.upload('/sponsorship/' + this.userData.uid + '/' + file.name, file).snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              data.images.push(url);
              count++;
              if (this.uploadedFiles.length == count) {
                this.saveData(data);
              }
            })
          })).subscribe();
      }
    } else this.saveData(data);
  }

  async saveData(data) {
    this.loading = true;
    if (data.id != '') {
      data.updatedDate = new Date();
      this.firestore.collection('sponsorship').doc(data.id).update(data).then(res => {
        this.toastService.success('Sponsorship details updated', 'Success');
        this.clearData();
      }).catch(error => {
        this.toastService.error('Something went wrong try again after sometime', 'Error');
        this.loading = false;
      })
    } else {
      data.createdDate = new Date();
      data.updatedDate = null;
      this.firestore.collection('sponsorship').add(data).then(res => {
        this.toastService.success('Sponsorship details saved', 'Success');
        this.clearData();
      }).catch(error => {
        this.toastService.error('Something went wrong try again after sometime', 'Error');
        this.loading = false;
      })
    }
  }

  clearData() {
    this.getUserSponsorshipDetails();
    this.uploadedFiles = [];
    this.loading = false;
  }

  removeImage(image) {
    if (confirm("Are you sure you want to remove this image?")) {
      this.loading = true;
      const index = this.displayImages.indexOf(image);
      if (index > -1) {
        this.displayImages.splice(index, 1);
      }
      let data = this.sponsorshipForm.value;
      data.images = this.displayImages;
      this.firestore.collection('sponsorship').add(data).then(res => {
        this.toastService.success('Sponsorship details saved', 'Success');
        this.clearData();
      }).catch(error => {
        this.toastService.error('Something went wrong try again after sometime', 'Error');
        this.loading = false;
      })
    }
  }
}
