import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Products } from '../product.model';
import { ProductsService } from '../products.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public product: Products = {};
  public products: Products[] = [];
  public counter: number = 1;
  public selectedSize: any = '';
  public wishlist: boolean = false;
  public closeResult: string;
  public url: any;
  userData: any;

  constructor(private route: ActivatedRoute, public toastService: ToastrService,
    public router: Router, private firestore: AngularFirestore,
    public productsService: ProductsService,) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.productsService.getProduct(id.toString()).subscribe(product => this.product = product)
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
  }

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  // Change size variant
  public changeSizeVariant(variant) {
    this.selectedSize = variant;
  }

  productSliderOptions = {
    items: 1,
    nav: true,
    dots: true,
    autoplay: true,
    slideSpeed: 300,
    loop: true,
    autoWidth: false,
  }

  productSliderOptions1 = {
    items: 3,
    loop: true,
    margin: 10,
    dots: false
  }


  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar
  }

  buyNow(product, counter) {
    if (this.userData) {
      if (confirm("Are you sure you want to purchase this item?")) {
        this.firestore.collection('users', ref => ref.where('uid', '==', this.userData.uid).limit(1)).get()
          .subscribe(snapshot => {
            snapshot.forEach(doc => {
              let user = doc.data();
              let totalprice = product.price * counter;
              if (user.balance > 0 && user.balance >= totalprice) {
                this.addNewOrder(user, product, counter, totalprice);
                this.updateUserBalance(user, totalprice);
                this.addTransaction(user, totalprice);
                this.toastService.success("Your order has been placed successfully. Please go to Order History page to see your placed order.", 'Success');
              } else {
                this.toastService.error("Not enough credit in your wallet. Please recharge it.", 'Error');
              }
            });
          });
      }
    } else
      this.router.navigate(["/login"]);
  }

  addNewOrder(user, product, totalQty, totalAmount) {
    this.firestore.collection('orders').add({
      uid: user.uid,
      clubId: user.clubId,
      name: user.firstName + ' ' + user.lastName,
      RFID: user.RFID,
      email: user.email,
      orderDate: new Date(),
      totalQuantity: totalQty,
      totalAmount: totalAmount,
      product: product
    })
  }

  updateUserBalance(user, totalAmount) {
    this.firestore.collection("users").doc(user.uid).update({
      balance: user.balance - totalAmount
    })
  }

  addTransaction(user, totalAmount) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'wallet',
      paymentType: 'debit',
      status: 'success',
      amount: totalAmount,
      openingBalance: user.balance,
      closingBalance: user.balance - totalAmount,
      clubId: user.clubId
    })
  }

  updateProductStock(product, totalQty) {
    this.firestore.collection("products").doc(product.id).update({
      stock: product.stock - totalQty
    })
  }

}
