<section *ngIf="data.length > 0" class="event schedule set-relative p-b-150" id="schedule">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0"><span class=""><img
                                    class="img-b" [src]="boxingUrl"> latest news <img class="img-b"
                                    [src]="boxingUrl"></span>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div *ngIf="data.length == 0" class="alert alert-danger text-center">
                    News not found.
                </div>
                <div *ngIf="data.length > 0" class="event-container scrollbar pr-2">
                    <div>
                        <div *ngFor="let item of data" class=" cal-time events">
                            <div class="row">
                                <div class="col-md-11">
                                    <div class="schedule-event">
                                        <h5 class="names">{{item.title}}</h5>
                                        <p [innerHtml]="item.description" class="schedule-description"></p>
                                    </div>
                                </div>
                                <div class="col-md-1 p-0">
                                    <div class="timing">
                                        <p class="p-0">{{item.startDate | date:'MMM d, y, h:mm a'}}</p>
                                        <!-- <div class="border-container"><div class="border-dashed"></div></div>
                                        <p class="event-time p-0">{{item.endDate | date:'MMM d, y, h:mm a'}}</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>