import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from '../../../shared/service/email.service';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  providers: [EmailService]
})
export class PaymentSuccessComponent implements OnInit {
  paymentId: any;
  clubName = "";

  constructor(private route: ActivatedRoute, private firestore: AngularFirestore, private router: Router,
    private http: HttpClient, public toastService: ToastrService, private emailService: EmailService,) {
    this.route.params.subscribe(params => {
      this.clubName = params['clubName'];
    });
  }

  ngOnInit(): void {
    this.firestore.collection('payments').doc(this.route.snapshot.paramMap.get('id')).get()
      .subscribe(doc => {
        if (doc.exists) {
          let paymentData = doc.data();
          if (paymentData.status == "created") {
            this.firestore.collection("users").doc(paymentData.userId).get().subscribe(user => {
              if (user.exists) {
                let userData = user.data();
                let body = {
                  id: doc.data().payment_intent
                }
                this.http.post(environment.prodApiLink + "payment/details", body).subscribe(response => {
                  let res: any = response;
                  this.paymentId = paymentData.payment_intent;
                  if (res.status == "succeeded") {
                    let amount = res.amount / 100;
                    this.firestore.collection("transactions").add({
                      uid: userData.uid,
                      name: userData.firstName + ' ' + userData.lastName,
                      email: userData.email,
                      RFID: userData.RFID,
                      transactionDate: new Date(),
                      stripeToken: res.id,
                      paymentMode: 'card',
                      paymentType: 'credit',
                      status: 'success',
                      amount: amount,
                      openingBalance: userData.balance,
                      closingBalance: userData.balance + amount,
                      clubId: userData.clubId,
                      sessionId: '',
                      orderId: '',
                      details: "Amount credited into your wallet balance"
                    }).then(result1 => {
                      this.firestore.collection('payments').doc(this.route.snapshot.paramMap.get('id')).update({
                        status: res.status,
                        transactionId: result1.id,
                        userId: userData.uid,
                        updatedDate: new Date
                      });
                      this.firestore.collection("users").doc(userData.uid).update({
                        balance: userData.balance + amount
                      }).then(user => {
                        this.toastService.success('Amount successfully added', 'Sucess');
                        this.emailService.postSendCreditMail(userData.email, userData.balance + amount, amount, userData.firstName + ' ' + userData.lastName);
                        this.router.navigate(["/" + this.clubName + "/wallet"])
                      }).catch(err => {
                        this.toastService.error(err, 'Error');
                      });
                    }).catch(err => {
                      this.toastService.error(err, 'Error');
                    });
                  } else {
                    this.firestore.collection('payments').doc(this.route.snapshot.paramMap.get('id')).update({
                      status: res.status
                    })
                  }
                });
              } else {
                this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
                this.router.navigate(["/" + this.clubName + "/wallet"])
              }
            });
          } else
            this.router.navigate(["/" + this.clubName + "/wallet"])
        } else {
          this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
          this.router.navigate(["/" + this.clubName + "/wallet"])
        }
      });
  }
}
