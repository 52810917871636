import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  clubName = "";
  constructor(public route: ActivatedRoute,) { 
    this.route.params.subscribe(params => {
      this.clubName = params['clubName'];
    });
  }

  ngOnInit(): void {
  }

}
