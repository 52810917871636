import { Component, OnInit } from '@angular/core';
import { LoginService } from "./login.service";
import { FormGroup } from '@angular/forms';
import { WindowService } from '../register/window.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService, WindowService]
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  email: string;
  password: string;
  phone: string;

  option: string = "email";
  isEmail = true;
  windowRef: any;
  verificationCode: string;
  recaptchaVerifier: any;
  otpSent = false;
  isLogin = false;

  constructor(public service: LoginService, private firestore: AngularFirestore, public afAuth: AngularFireAuth,
    public toastService: ToastrService, private win: WindowService, public route: ActivatedRoute,
    public router: Router) {
    this.route.params.subscribe(params => {
      let clubName = params['clubName'];
      if (!clubName)
        localStorage.removeItem("logoUrl")
    });
    if (localStorage.getItem("clubName"))
      this.isLogin = true;
  }

  ngOnInit() {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: "invisible"
    })

    this.windowRef.recaptchaVerifier.render()
  }

  changeOption(event) {
    if (event == 'email') {
      this.isEmail = true;
      this.phone = '';
      this.otpSent = false;
      this.verificationCode = '';
    } else {
      this.isEmail = false;
      this.email = '';
      this.password = '';

    }
  }

  sendLoginCode() {
    this.verificationCode = '';
    if (!this.phone)
      this.toastService.error('Phone number is required', 'Error');
    else {
      this.firestore.collection('users', ref => ref.where("phoneNo", "==", '+44' + this.phone)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.toastService.error('User not found. Please register first.', 'Error');
          } else {
            const appVerifier = this.windowRef.recaptchaVerifier;
            this.afAuth.auth.signInWithPhoneNumber('+44' + this.phone, appVerifier)
              .then(result => {
                this.windowRef.confirmationResult = result;
                this.otpSent = true;
                this.toastService.success('OTP successfully send', 'Success');
              })
              .catch(error => { this.toastService.error(error.message, 'Error'); });
          }
        });

    }
  }

  action(type) {
    if (type == 'login')
      this.isLogin = true;
    else
      this.router.navigate(['/register']);
  }

  verifyLoginCode() {
    if (!this.verificationCode)
      this.toastService.error('OTP is required', 'Error');
    else if (this.verificationCode.length < 6)
      this.toastService.error('OTP not valid it should be 6 digits', 'Error');
    else {
      this.windowRef.confirmationResult
        .confirm(this.verificationCode)
        .then(result => {
          console.log(result.user);
          this.firestore.collection('users').doc(result.user.uid).get()
            .subscribe(snapshot => {
              let data = snapshot.data();
              sessionStorage.setItem('user', JSON.stringify(snapshot.data()));
              this.firestore.collection("clubs").doc(data.clubId).get()
                .subscribe(snapshot1 => {
                  let clubName = snapshot1.data().clubDetails.name.replace(/ /g, '-')
                  if (data.roles == "clubAdmin")
                    this.router.navigate([clubName + '/home']);
                  else if (data.roles != "clubAdmin" && data.roles != "superAdmin")
                    this.router.navigate([clubName + '/book-session']);
                  this.toastService.success('You have been successfully logged in!', 'Success');
                });
            });
        })
        .catch(error => { this.toastService.error(error.message, 'Error'); });
    }
  }

  redirectUrl(url) {
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }
}
