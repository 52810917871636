<div class="m-5">
    <div class="alert alert-success text-center">
        <h3>Thank you for submitting information</h3>
        <ul class="mb-2">
            <li><i class="fa fa-check" aria-hidden="true"></i> You will be provided with a wristband at your club which
                needs to be worn at all times.</li>
            <li><i class="fa fa-check" aria-hidden="true"></i> You can now add money to your <a
                    routerLink="/{{clubName}}/wallet">wallet</a> online for
                contactless entry using your credit card or by paying at the gym.
            </li>
        </ul>
        <img src="/assets/images/band.jpeg" class="img-band">
    </div>
</div>