import { Component, Input, ViewChild, OnInit, HostListener, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent implements OnInit {
  @Input() name: string;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  sigPadElement;
  context;
  isDrawing = false;
  img;
  user: any;
  docId = "";
  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 320,
    'canvasHeight': 150
  };
  constructor(private firestore: AngularFirestore, public toastService: ToastrService,) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getSignatureData();
  }

  getSignatureData() {
    this.firestore.collection('signature', ref => ref.where('userId', '==', this.user.uid)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.docId = doc.id;
          this.img = doc.data();
        });
      });
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
  }

  save() {
    this.img = this.signaturePad.toDataURL("image/png");
    let data = {
      signature: this.img,
      userId: this.user.uid,
    }
    if (this.docId != "") {
      this.firestore.collection("signature").doc(this.docId).update(data).then(result => {
        this.img = '';
        this.toastService.success('Data updated successfully!', 'Success');
        this.getSignatureData();
        this.clear();
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });
    } else {
      this.firestore.collection("signature").add(data).then(result => {
        this.img = '';
        this.toastService.success('Data added successfully!', 'Success');
        this.getSignatureData();
        this.clear();
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });
    }
  }

  drawComplete() {
  }

  drawStart() {
  }
}
