import { Component, PipeTransform, Pipe, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  clubData: any;
  clubName = "";
  club = "";
  isSignIn = false;

  constructor(public firestore: AngularFirestore, private formBuilder: FormBuilder, public route: ActivatedRoute,
    public toastService: ToastrService, public router: Router, private sanitizer: DomSanitizer,
    private _http: HttpClient) {
    this.route.params.subscribe(params => {
      if (params['clubName']) {
        let clubname = params['clubName'];
        this.clubName = clubname;
        let name = clubname.replace(/-/g, ' ');
        this.club = name;
        this.getClubData(name, clubname);
      }
    });
    this.contactForm = this.formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  getClubData(clubname, clubUrl) {
    this.firestore.collection("clubs").get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          data.id = e.id;
          if (data.clubDetails.name.toString().toLowerCase() == clubname.toString().toLowerCase()) {
            this.clubData = data;
            localStorage.setItem("clubName", clubUrl);
            localStorage.setItem("clubId", data.id);
            if (this.clubData.googleMapsLocation) {
              if (this.clubData.googleMapsLocation == '')
                this.clubData.googleMapsLocation = 'https://maps.google.com/maps?q=London,%20United%20Kingdom&Roadmap&z=10&ie=UTF8&iwloc=&output=embed';
            } else {
              this.clubData.googleMapsLocation = 'https://maps.google.com/maps?q=england&t=&z=13&ie=UTF8&iwloc=&output=embed';
            }
          }
        });
      });
  }

  async sendMail() {
    if (this.contactForm.status == "VALID") {
      let form = this.contactForm.value;
      if (this.clubData)
        this.postUserSendMail(this.clubData.clubDetails.email, form.subject, form.message);
      else
        this.postUserSendMail("info@pamit.co.uk", form.subject, form.message);
      form.clubId = this.clubData ? this.clubData.id : "";
      form.createdDate = new Date();
      form.emailSend = true;
      this.firestore.collection("inquiry").add(form).then(res => {
        this.contactForm.reset();
      });
      this.toastService.success("Thank you for contacting us. We will get back to you.", 'Success');
    } else {
      this.toastService.error("Please fill all details", 'Error');
    }
  }

  postUserSendMail(email, subject, description) {
    const body = {
      "email": email,
      "ccEmail": "info@pamit.co.uk",
      "subject": subject,
      "description": description
    };
    this._http.post('https://boxing-new-backend.herokuapp.com/api/send/user/mail', body).toPromise();
  }

}
