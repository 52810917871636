import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.afAuth.authState.pipe(
            map(authState => !!authState),
            map(auth => {
                if (!auth) {
                    this.router.navigate(['/login']);
                }
                return auth;
            }),
        );
    }
}