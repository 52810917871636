<section class="team-sec team-grid speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of coachData" class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img border border-dark">
                        <img class="img-sp" onerror="this.src='../../assets/images/nouser.png';" [src]="item?.imageUrl" >
                        <div class="overlay"></div>
                    </div>
                    <div class="employee w-100">
                        <h5 class="e-name text-center">{{item?.firstName}} {{item?.lastName}} <span *ngIf="item.coachLevel"
                                class="alert alert-info p-1">{{item?.coachLevel}} <span *ngIf="item.coachExperience">-
                                    {{item?.coachExperience}} years of experience</span></span></h5>
                        <div [innerHtml]="item?.coachContent"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>