<div class=" theme-card">
    <mat-vertical-stepper [linear]="isLinear" #stepper class="w-100">
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup" #f="ngForm">
                <ng-template matStepLabel>Personal Details</ng-template>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Member First Name</mat-label>
                            <input matInput placeholder="Member First Name" formControlName="firstName" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Member Last Name</mat-label>
                            <input matInput placeholder="Member Last Name" formControlName="lastName" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <mat-label class="mr-2 font-600">Date of Birth :</mat-label>
                        <p-calendar required="true" formControlName="dob" [showIcon]="true" [monthNavigator]="true"
                            [yearNavigator]="true" yearRange="1870:2030" [touchUI]="true"></p-calendar>
                        <div *ngIf="firstFormGroup.get('dob').hasError('required') && f.submitted"
                            class="error text-danger">
                            Date of birth is required
                        </div>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <mat-label class="mr-2 font-600">Sex :</mat-label>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="sex" name="sex">
                            <mat-radio-button required class="example-radio-button mr-3" *ngFor="let g of gender"
                                [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="firstFormGroup.get('sex').hasError('required') && f.submitted"
                            class="error text-danger">
                            Sex is required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Current Address</mat-label>
                            <input matInput ngx-google-places-autocomplete [options]='options' formControlName="address"
                                (onAddressChange)="addressChange($event,'current')" />
                            <!-- <textarea matInput placeholder="Current Address" formControlName="address"
                                required></textarea> -->
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Postcode</mat-label>
                            <input matInput placeholder="Post Code" type="text" formControlName="postcode" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" formControlName="email" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Home Tel No.</mat-label>
                            <input matInput placeholder="Home Tel No." type="text" pattern="[0-9]*"
                                formControlName="homeTelephoneNo" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Mobile No.</mat-label>
                            <input matInput placeholder="Mobile No." formControlName="mobileNo" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 mb-2">
                        <p *ngIf="!uploadedImage" class="font-weight-bold">Please upload an image of yourself for
                            our records *</p>
                        <p *ngIf="uploadedImage" class="font-weight-bold">Change profile picture</p>
                        <input class="mb-3" type="file" formControlName="imageUrl" (change)="showPreview($event)"
                            [required]="!uploadedImage ? true : false">
                        <!-- <input *ngIf="uploadedImage" class="mb-3 form-control" type="url" formControlName="imageUrl" readonly  required> -->
                        <div *ngIf="firstFormGroup.get('imageUrl').hasError('required') && f.submitted && !uploadedImage"
                            class="error text-danger">
                            Profile image is required
                        </div>
                        <img *ngIf="selectedImage != null" [src]="selectedImage"
                            style="border-radius: 50%;width: 100px;height: 100px;">
                        <img *ngIf="uploadedImage && selectedImage == null" [src]="uploadedImage"
                            style="border-radius: 50%;width: 100px;height: 100px;">
                    </div>
                    <div *ngIf="isFillingType == 'parent'" class="col-lg-12">
                        <mat-checkbox formControlName="volunteer" class="example-margin mr-1"><button mat-button
                                (click)="openDialog()">Read code
                                of conduct (Parents & Carers)</button></mat-checkbox>
                    </div>
                    <!-- <div class="col-lg-12 mb-2 border" style="height: 500px;overflow: overlay;">
                        <pdf-viewer [src]="'assets/rules.pdf'" [render-text]="true" style="display: block;">
                        </pdf-viewer>
                    </div> -->
                    <!-- <div class="col-lg-12 mb-3">
                            <mat-checkbox formControlName="volunteer" class="example-margin mr-1"></mat-checkbox>Willing
                            to take part-time as Volunteer or Parents/Carers (Optional)
                            <br>
                            <p class="font-italic mb-2 ml-3 text-lowercase text-primary" style="font-size: 10px;">PLEASE
                                READ THE FOLLOWING INSTRUCTIONS
                                CAREFULLY BEFORE CHECKING AS VOLUNTEER OR PARENTS/CARERS</p>
                            <div style="height: 250px;overflow:auto;" class="border p-2">
                                <h5 class="text-center" style="text-decoration: underline;">CODE OF PRACTICE FOR CLUB
                                    OFFICIALS AND VOLUNTEERS</h5>
                                <h6>The essence of good ethical conduct and practice is summarised below.</h6>
                                <p>All volunteers must:</p>
                                <ul>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Consider the well-being and
                                        safety or participants before the development of
                                        performance.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Develop an appropriate working
                                        relationship with participants, based on mutual
                                        trust
                                        and respect.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Make sure all activities are
                                        appropriate to the age, ability and experience of
                                        those
                                        taking part</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Promote the positive aspects of
                                        the sport (e.g. fair play)</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Display consistently high
                                        standards of behaviour and appearance.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Follow all guidelines laid down
                                        by the National Governing Body and the club.
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Hold appropriate valid
                                        qualifications and insurance cover.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Never exert undue influence over
                                        performers to obtain personal benefit or
                                        reward.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Never condone rule violations,
                                        rough play or the use of prohibited substances.
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Encourage participants to value
                                        their performances and not just results.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Encourage and guide participants
                                        to accept responsibility for their own
                                        performance and
                                        behaviour.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> All club matters remain
                                        confidential.</li>
                                </ul>
                                <h5 class="text-center" style="text-decoration: underline;">CODE OF PRACTICE FOR
                                    PARENTS/CARERS</h5>
                                <ul>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Encourage your child to learn the
                                        rules and play within them.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Discourage unfair play and
                                        arguing with officials.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Help your child to recognize good
                                        performance, not just results.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Never force your child to take
                                        part in sport.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Set a good example by recognizing
                                        fair play and applauding good performances of all.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Never punish or belittle a child
                                        for losing or making mistakes.
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Publicly accept officials’
                                        judgement.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Support your child’s involvement
                                        and help them to enjoy their sport.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Use correct and proper language
                                        at all times.
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> Encourage and guide performers to
                                        accept responsibility for their own performance and behaviour.</li>
                                    <li><i class="fa fa-check" aria-hidden="true"></i> All club matters remain
                                        confidential.</li>
                                </ul>
                            </div>
                        </div> -->
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Emergency Contact Details</ng-template>
                <div class="row  mt-2">
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Emergency Contact Name</mat-label>
                            <input matInput placeholder="Emergency Contact Name" formControlName="emergencyContactName"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Emergency Relationship</mat-label>
                            <input matInput placeholder="Emergency Relationship" formControlName="emergencyRelationship"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Emergency Contact Address</mat-label>
                            <input matInput ngx-google-places-autocomplete [options]='options'
                                formControlName="emergencyContactAddress"
                                (onAddressChange)="addressChange($event,'emergency')" />
                            <!-- <textarea matInput placeholder="Emergency Contact Address"
                                formControlName="emergencyContactAddress" required></textarea> -->
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Emergency Contact Tel No.</mat-label>
                            <input matInput placeholder="Emergency Contact Tel No." formControlName="emergencyContactNo"
                                type="text" pattern="[0-9]*" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="row">
                <div class="col-lg-12">
                    <p class="mb-3">With the new General Data Protection Regulating replacing the 1998 Data
                        protection act on
                        25.05.2018
                        {{clubName}} are legally required to inform you that personal details supplied
                        on your
                        membership form will be passed on to England Boxing.</p>
                </div>

                <div class="col-lg-12 mb-3">
                    <mat-checkbox [(ngModel)]="consent" class="example-margin mr-1"></mat-checkbox>I accept receiving
                    emails from PamIt.co.uk with regards to club/site updates, and additional information.
                </div>
                <!-- <div class="col-lg-12 mb-3">
                    <mat-checkbox class="example-margin mr-1" [(ngModel)]="newsLetter">
                    </mat-checkbox>Sign up for newsletters
                </div> -->
                <div class="col-lg-12">
                    <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                    <button class="btn btn-default btn-success radius-0" (click)="submit()"
                        [disabled]="!consent">Submit</button>
                </div>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</div>