import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from '../../../shared/service/email.service';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.scss'],
  providers: [EmailService]
})
export class BookingSuccessComponent implements OnInit {
  paymentId: any;
  clubName = "";
  currentUrl = "";
  status = "";
  eventData: any;
  bookingData: any;

  constructor(private route: ActivatedRoute, private firestore: AngularFirestore, private router: Router,
    private http: HttpClient, public toastService: ToastrService, private emailService: EmailService,) {
    this.currentUrl = `https://app.pamit.co.uk/` + this.route.snapshot.paramMap.get('clubName');
    //this.currentUrl = `http://localhost:4200/` + this.route.snapshot.paramMap.get('clubName');
  }

  ngOnInit(): void {
    let checkoutId = this.route.snapshot.paramMap.get('checkoutid');
    if (checkoutId)
      this.checkPayment(checkoutId);
  }

  checkPayment(checkoutId) {
    this.firestore.collection('payments').doc(checkoutId).get()
      .subscribe(doc => {
        if (doc.exists) {
          let paymentData = doc.data();
          if (paymentData.status == "created") {
            this.firestore.collection("bookings").doc(checkoutId).get().subscribe(user => {
              if (user.exists) {
                let booking = user.data();
                let body = {
                  id: doc.data().payment_intent
                }
                this.http.post(environment.prodApiLink + "payment/details", body).subscribe(response => {
                  let res: any = response;
                  this.status = res.status;

                  if (res.status == "succeeded") {
                    this.bookingData = booking;
                    this.firestore.collection('club_events').doc(booking.eventId).get().subscribe(event => {
                      let sData = event.data();
                      sData.startDate = new Date(sData.startDate.seconds * 1000);
                      sData.endDate = new Date(sData.endDate.seconds * 1000)
                      sData.id = event.id;
                      this.eventData = sData;
                      this.emailTicket();
                    });
                    let amount = res.amount / 100;
                    this.firestore.collection("transactions").add({
                      uid: booking.userId,
                      name: booking.name,
                      email: booking.email,
                      RFID: '',
                      transactionDate: new Date(),
                      stripeToken: res.id,
                      paymentMode: 'card',
                      paymentType: 'credit',
                      status: 'success',
                      amount: amount,
                      openingBalance: 0,
                      closingBalance: 0,
                      clubId: booking.clubId,
                      sessionId: '',
                      orderId: '',
                      bookingId: booking.bookingId,
                      eventId: booking.eventId,
                      details: "Book event"
                    }).then(result1 => {
                      this.firestore.collection('payments').doc(checkoutId).update({
                        status: res.status,
                        transactionId: result1.id,
                        updatedDate: new Date
                      }).then(d => {
                        this.firestore.collection('bookings').doc(checkoutId).update({
                          status: res.status,
                          transactionId: result1.id,
                        })
                      });
                    }).catch(err => {
                      this.toastService.error(err, 'Error');
                    });
                  } else {
                    this.firestore.collection('payments').doc(checkoutId).update({
                      status: res.status
                    })
                    this.firestore.collection('bookings').doc(checkoutId).update({
                      status: res.status
                    })
                    this.toastService.error("Your payment in " + res.status + " status. Please wait for sometime.", 'Error');
                  }
                });
              } else {
                this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
                window.location.href = this.currentUrl;
              }
            });
          } else window.location.href = this.currentUrl;
        } else {
          this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
          window.location.href = this.currentUrl;
        }
      });
  }

  emailTicket() {
    let totalTickets = 0;
    let types = '';
    this.bookingData.tickets.forEach(e => {
      totalTickets += Number(e.quantity);
      types += e.type + ' : ' + e.quantity + '<br>';
    });
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let eventDate = days[this.eventData.startDate.getDay()] + ' ' + new Date(this.eventData.startDate).getDate() + ', ' + new Date(this.eventData.startDate).getFullYear();
    let timing = 'FROM ' + this.formatAMPM(new Date(this.eventData.startDate)) + ' TO ' + this.formatAMPM(new Date(this.eventData.endDate));
    this.emailService.postEventSuccessMail(this.bookingData.email, this.bookingData.name,
      this.bookingData.bookingId, this.eventData.eventName, this.eventData.location, eventDate,
      timing, types, totalTickets, this.bookingData.amount);
    this.toastService.success("Ticket send to your email address", 'Success');
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}
