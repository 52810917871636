<!-- Login section -->
<section class="login-page section-b-space pt-3 pb-3">
    <div class="container" style="max-width: 500px;">
        <!-- <div class="logo text-center">
            <a class="navbar-brand" href="#"><img src="assets/images/logo/5.png" alt="logo"></a>
        </div> -->
        <div class="col-md-12" id="recaptcha-container"></div>
        <div *ngIf="!isLogin">
            <div class="col-md-12 theme-card">
                <button class="btn btn-lg btn-danger btn-block mb-4" (click)="action('register')">SIGN UP</button>
                <h4 class="text-center mb-4"> -- OR --</h4>
                <button class="btn btn-lg btn-primary btn-block" (click)="action('login')">SIGN IN</button>
            </div>
        </div>
        <div *ngIf="isLogin" class="row">
            <div class="col-lg-12">
                <h3 class="text-center">Login</h3>
                <div class="theme-card">
                    <!-- <div class="row mb-3">
                        <div class="col-md-12 mb-2">
                            <button (click)="service.facebookAuth()" type="button"
                                class="btn btn-fb btn-block text-white"><i class="fa fa-2x fa-facebook pr-2 v-mdl"></i>
                                Login With Facebook</button>
                        </div>
                        <div class="col-md-12 mb-2">
                            <button (click)="service.googleAuth()" type="button"
                                class="btn btn-google btn-block text-white"><i
                                    class="fa fa-2x v-mdl fa-google pr-2"></i>
                                Login
                                With Google</button>
                        </div>
                    </div>
                    <h2><span>OR</span></h2> -->
                    <form class="theme-form mt-3">
                        <!-- <div class="col-md-12 mb-3 p-0 text-center">
                            <mat-radio-group name="epoption" [(ngModel)]="option" (change)="changeOption($event.value)">
                                <mat-radio-button [checked]="true" class="mr-3" value="email">Email
                                </mat-radio-button>
                                <mat-radio-button value="phone">Phone</mat-radio-button>
                            </mat-radio-group>
                        </div> -->
                        <div class="col-md-12 mb-3 p-0" *ngIf="isEmail">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="text" class="form-control mb-2" [(ngModel)]="email" name="email"
                                    placeholder="Enter your email" required>
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input type="password" class="form-control mb-2" [(ngModel)]="password" name="password" autocomplete="on"
                                    placeholder="Enter your password" required>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3 p-0" *ngIf="!isEmail">
                            <div class="form-group">
                                <label for="phone" class="mb-1">Phone</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"
                                            style="height: 50px!important;">+44</span>
                                    </div>
                                    <input [disabled]="otpSent" type="number" class="form-control mb-1 border"
                                        style="height: 50px!important;" [(ngModel)]="phone" name="phone" id="phone"
                                        placeholder="Phone" >
                                </div>

                            </div>
                        </div>

                        <div class="col-md-12 mb-3 p-0" *ngIf="!isEmail && otpSent">
                            <div class="form-group">
                                <p for="code" class="mb-1 font-weight-bold">Enter OTP <a (click)="sendLoginCode()"
                                        class="float-right text-primary">Resend OTP</a></p>
                                <input type="text" class="form-control mb-1" [(ngModel)]="verificationCode" name="code"
                                    id="code" placeholder="code" >
                            </div>
                        </div>
                        <div>
                            <button *ngIf="isEmail" type="submit"
                                class="btn primary-btn btn-default radius-0 mb-1 btn-block"
                                (click)="service.signWithEmailAndPassword(email,password)">Login</button>
                            <button *ngIf="!isEmail && !otpSent"
                                class="btn primary-btn btn-block mb-1 btn-default radius-0"
                                (click)="sendLoginCode()">SMS Text Login Code</button>
                            <button *ngIf="!isEmail && otpSent" [disabled]="!verificationCode"
                                (click)="verifyLoginCode()"
                                class="btn primary-btn btn-block mb-1 btn-default radius-0">Verify</button>
                            <span class="float-left">New account?<a class="text-primary"
                                    (click)="redirectUrl('/register')">
                                    Sign Up</a></span>
                            <span class="float-right"><a class="text-primary" (click)="redirectUrl('/forgot-password')">
                                    Forgot Password?</a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->