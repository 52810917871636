<footer class="bg bg-img contact event event-gradient p-2 p-b-100 position-sticky" id="contact">
    <div class="container p-b-100">
        <div class="row">
            <div *ngIf="clubData" class="col-md-12">
                <div class="mb-2 title">
                    <div class="sub-title">
                        <P><span class="text-uppercase ">{{clubData?.clubDetails?.name}}</span><br>
                            <span><i class="fa fa-address-card-o mr-1"></i>{{clubData?.clubDetails?.address}},
                                {{clubData?.clubDetails?.city}}, {{clubData?.clubDetails?.state}},
                                {{clubData?.clubDetails?.country}}, {{clubData?.clubDetails?.postcode}}</span><br>
                            <span><i class="fa fa-envelope-o mr-1"></i>{{clubData?.clubDetails?.email}}</span><br>
                            <span><i class="fa fa-phone mr-1"></i>0{{clubData?.clubDetails?.mobileNo}} /
                                0{{clubData?.clubDetails?.landlineNo}}</span><br>
                        </P>
                    </div>
                </div>
            </div>
            <div *ngIf="!clubData" class="col-md-12">
                <div class="mb-2 title">
                    <div class="sub-title">
                        <P><span class="text-uppercase ">PamIT</span><br>
                            <span><i class="fa fa-address-card-o mr-1"></i>C/o Camburgh House, 27 New Dover Rd,
                                Canterbury CT1 3DN</span><br>
                            <span><i class="fa fa-envelope-o mr-1"></i>info@pamit.co.uk</span><br>
                            <span><i class="fa fa-phone mr-1"></i>07973 600042</span><br>
                        </P>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-r-0 map">
                <div class="iframe-container">
                    <iframe *ngIf="clubData" [src]="clubData?.googleMapsLocation | safe" width="600" height="450"
                        frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    <iframe *ngIf="!clubData"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.9787447430012!2d1.0865406151901804!3d51.274710335511486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47deca36a513b47b%3A0xa52dca558b76d43f!2sBurgess%20Hodgson%20LLP!5e0!3m2!1sen!2sin!4v1638371158880!5m2!1sen!2sin"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="p-l-0 col-md-6 set-z-index form-footer">
                <div class="bg-white border">
                    <form [formGroup]="contactForm" #f="ngForm">
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Name" formControlName="name" required>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Phone</mat-label>
                                    <input matInput placeholder="Phone" formControlName="phone" type="number" required>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Email" formControlName="email" required>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Subject</mat-label>
                                    <input matInput placeholder="Subject" formControlName="subject" required>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Message</mat-label>
                                    <textarea matInput placeholder="Message" formControlName="message"
                                        required></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <button class="btn btn-block btn-default m-0-auto primary-btn" (click)="sendMail()"
                            type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</footer>