import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { EmailService } from '../../shared/service/email.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  providers: [EmailService]
})
export class WalletComponent implements OnInit {
  transactionsData: any[] = [];
  userData: any;
  amount: number = 0;
  isLoggedIn = false;
  isDisabled = false;
  userName = '';
  userImage: any;
  rfidUserData: any;
  rfid: any;
  sessionData: any;
  orderData: any;
  selectedTransaction: any;

  constructor(public toastService: ToastrService, private http: HttpClient,
    private firestore: AngularFirestore, private emailService: EmailService,
    private modalService: NgbModal, private route: ActivatedRoute) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    if (this.userData.roles == 'clubAdmin')
      this.getTop10Transactions();
    else
      this.getTransactions(this.userData);
  }

  ngOnInit(): void {
  }

  getUsersUpdatedData() {
    this.firestore.collection('users', ref => ref.where('uid', '==', this.userData.uid).limit(1)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.userData = doc.data();
        });
      });
  }

  getTransactions(user) {
    this.transactionsData = [];
    this.firestore.collection('transactions', ref => ref.where('uid', '==', user.uid).orderBy('transactionDate', 'desc').limit(100)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let data = doc.data();
          let tdate = data.transactionDate != null ? new Date(data.transactionDate.seconds * 1000) : null;
          data.transactionDate = tdate;
          this.transactionsData.push(data);
        });
      });
    this.getUsersUpdatedData();
  }

  getTop10Transactions() {
    this.transactionsData = [];
    this.firestore.collection('transactions', ref => ref.where('clubId', '==', this.userData.clubId).orderBy('transactionDate', 'desc').limit(100)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let data = doc.data();
          let tdate = data.transactionDate != null ? new Date(data.transactionDate.seconds * 1000) : null;
          data.transactionDate = tdate;
          this.transactionsData.push(data);
        });
      });
    this.getUsersUpdatedData();
  }

  deposit(amount) {
    if (amount == 0 || amount < 0)
      this.toastService.error('Please enter valid amount', 'Error');
    else if (amount < 10)
      this.toastService.error('Minimum £10 require for deposit', 'Error');
    else {
      let checkOutId = this.randomString(20);
      let body = {
        "price": amount * 100,
        "clubUrl": this.route.snapshot.paramMap.get('clubName'),
        "checkoutId": checkOutId,
        "customerEmail": this.userData.email,
      }
      this.http.post(environment.prodApiLink + "create-checkout-session", body).subscribe(response => {
        var res: any = response;
        if (res.error) {
          this.toastService.error(res.error.message, 'Error');
        } else {
          this.firestore.collection("payments").doc(checkOutId.toString()).set({
            payment_intent: res.payment_intent,
            checkoutId: checkOutId,
            transactionId: null,
            userId: this.userData.uid,
            status: "created",
            createdDate: new Date(),
            clubId: this.userData.clubId,
            updatedDate: null
          }).then(resData => {
            window.location.href = res.url;
          })
        }
      });
      // if (typeof window !== 'undefined') {
      // let handler = (window as any).StripeCheckout.configure({
      //   key: environment.stripeID, // publishble key
      //   locale: 'auto',
      //   token: (token: any) => {
      //     let body = {
      //       "stripeToken": token.id,
      //       "price": amount * 100
      //     }
      //     this.http.post("https://boxing-new-backend.herokuapp.com/api/payment", body).toPromise().then(response => {
      //       let res: any = response;
      //       if (res.status == "succeeded") {

      //         this.firestore.collection("transactions").add({
      //           uid: this.userData.uid,
      //           name: this.userData.firstName + this.userData.lastName,
      //           email: this.userData.email,
      //           RFID: this.userData.RFID,
      //           transactionDate: new Date(),
      //           stripeToken: token.id,
      //           paymentMode: 'card',
      //           paymentType: 'credit',
      //           status: 'success',
      //           amount: amount,
      //           openingBalance: this.userData.balance,
      //           closingBalance: this.userData.balance + amount,
      //           clubId: this.userData.clubId,
      //           sessionId: '',
      //           orderId: '',
      //           details: "Amount credited into your wallet balance"
      //         }).then(result1 => {
      //           this.firestore.collection("users").doc(this.userData.uid).update({
      //             balance: this.userData.balance + amount
      //           }).then(user => {
      //             this.amount = 0;
      //             this.toastService.success('Amount successfully added', 'Sucess');
      //             this.emailService.postSendCreditMail(this.userData.email, this.userData.balance + amount, amount, this.userData.firstName + ' ' + this.userData.lastName);
      //             this.getTransactions(this.userData);
      //           }).catch(err => {
      //             this.toastService.error(err, 'Error');
      //           });
      //         }).catch(err => {
      //           this.toastService.error(err, 'Error');
      //         });
      //       } else if (res.type == "StripeCardError") {
      //         this.toastService.error(res.raw.message, 'Error');
      //       } else {
      //         this.toastService.error("Something wrong in payment process. Please try again after some time.", 'Error');
      //       }
      //     }).catch(error => {
      //       this.toastService.error(error, 'Error');
      //     });;
      //   }
      // });
      // handler.open({
      //   name: 'PamIT',
      //   description: 'Top up credit',
      //   currency: 'gbp',
      //   amount: amount * 100,
      //   email: this.userData.email
      // })
      //}
    }
  }

  submitDetails(rfid) {
    this.isDisabled = true;
    this.isLoggedIn = false;
    this.rfidUserData = {};
    if (!rfid) {
      this.toastService.error('Enter RFID', 'Error');
      this.isDisabled = false;
    } else {
      this.firestore.collection("users", ref => ref.where('club_rfid', '==', this.userData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.toastService.error("User not found. Please contact site administrator.", 'Error');
            this.rfid = '';
          }
          else {
            snapshot.forEach(doc => {
              let user = doc.data();
              this.rfidUserData = doc.data();
              this.isLoggedIn = true;
              this.isDisabled = false;
              this.rfid = '';
              this.userName = user.firstName + ' ' + user.lastName;
              this.userImage = user.imageUrl ? user.imageUrl : '';
            });
          }
        });
    }
  }

  topUpAmount(amount) {
    if (confirm("are you sure you want to deposit cash?")) {
      this.isDisabled = true;
      this.addCashTransaction(this.rfidUserData, amount);
      this.firestore.collection("users").doc(this.rfidUserData.uid).update({
        balance: this.rfidUserData.balance + amount
      }).then(res => {
        this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get()
          .subscribe(snapshot => {
            snapshot.forEach(doc => {
              this.rfidUserData = doc.data();
              this.isDisabled = false;
              this.userName = '';
              this.userImage = '';
              this.rfid = '';
              this.isLoggedIn = false;
              this.getTop10Transactions();
              this.toastService.success("Cash amount added successfully.", 'Success');
            });
          });
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });
    }
  }

  payInCash() {
    if (this.amount == 0 || this.amount < 0)
      this.toastService.error('Please enter valid amount', 'Error');
    else {
      if (confirm("are you sure you want to deposit cash?")) {
        this.isDisabled = true;
        this.addCashTransaction(this.rfidUserData, this.amount);
        this.firestore.collection("users").doc(this.rfidUserData.uid).update({
          balance: this.rfidUserData.balance + this.amount
        }).then(res => {
          this.firestore.collection("users", ref => ref.where('uid', '==', this.rfidUserData.uid).limit(1)).get()
            .subscribe(snapshot => {
              snapshot.forEach(doc => {
                this.rfidUserData = doc.data();
                this.isDisabled = false;
                this.userName = '';
                this.userImage = '';
                this.rfid = '';
                this.isLoggedIn = false;
                this.amount = 0;
                this.getTop10Transactions();
                this.toastService.success("Cash amount added successfully.", 'Success');
              });
            });
        }).catch(err => {
          this.toastService.error(err, 'Error');
        });
      }
    }
  }

  async addCashTransaction(user, totalAmount) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'cash',
      paymentType: 'credit',
      status: 'success',
      amount: totalAmount,
      openingBalance: user.balance,
      closingBalance: user.balance + totalAmount,
      clubId: user.clubId,
      sessionId: '',
      orderId: '',
      details: "Amount credited into your wallet balance"
    })
    this.emailService.postSendCreditMail(user.email, user.balance + totalAmount, totalAmount, user.firstName + ' ' + user.lastName);
  }

  openTransactionDetails(content, transaction) {
    this.sessionData = this.orderData = undefined;
    if (transaction.sessionId && transaction.sessionId != '')
      this.getSessionData(transaction.sessionId);
    else if (transaction.orderId && transaction.orderId != '')
      this.getOrderData(transaction.orderId);
    this.selectedTransaction = transaction;
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  goBack(){
    this.isLoggedIn = false;
  }

  getSessionData(id) {
    this.firestore.collection('sessions').doc(id).get()
      .subscribe(snapshot => {
        if (snapshot.exists) {
          this.sessionData = snapshot.data();
          this.sessionData.startDate = new Date(this.sessionData.startDate.seconds * 1000);
          this.sessionData.endDate = new Date(this.sessionData.endDate.seconds * 1000);
        }
      });
  }

  getOrderData(id) {
    this.firestore.collection('orders').doc(id).get()
      .subscribe(snapshot => {
        if (snapshot.exists) {
          this.orderData = snapshot.data();
          this.orderData.orderDate = new Date(this.orderData.orderDate.seconds * 1000);
        }
      });
  }

  randomString(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
