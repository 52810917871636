<div class="row p-b-150">
    <div class="col-md-4">
    </div>
    <div class="col-md-4">
        <div class="card mt-3 mb-3">
            <div class="card-body shadow">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Validate Ticket No. </mat-label>
                    <mat-icon matPrefix>book_online</mat-icon>
                    <input matInput [(ngModel)]="ticketNo" required>
                </mat-form-field><br>
                <button class="btn btn-success btn-block btn-sm" (click)="vallidate()">
                    Validate</button>
            </div>
        </div>
    </div>
    <div class="col-md-4"></div>

    <div class="col-md-12">
        <div class="alert alert-info font-weight-bold mb-1 mt-2 p-1 text-center">Showing Active Booking Details
        </div>
        <div class="table-responsive">
            <table class="table table-sm table-striped">
                <thead>
                    <th>Event Name</th>
                    <th>#Ticket</th>
                    <th>Booking Name</th>
                    <th>Booking Date</th>
                    <!-- <th>Email</th> -->
                    <th>Contact</th>
                    <th>Tickets</th>
                    <th>Total Tickets</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of bookingData">
                        <td>{{item?.eventName}}</td>
                        <td>{{item?.bookingId}}</td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.createdDate | date:'d/M/yyyy h:m a'}}</td>
                        <!-- <td>{{item?.email }}</td> -->
                        <td>{{item?.contact}}</td>
                        <td>
                            <ul>
                                <li *ngFor="let item1 of item?.tickets">
                                    <span>{{item1?.type}} : {{item1?.amount}} X {{item1?.quantity}} =
                                        £{{item1?.amount
                                        *
                                        item1?.quantity}}</span>
                                </li>
                            </ul>
                        </td>
                        <td class="text-center">{{item?.totalTickets}}</td>
                        <td class="text-center">{{item?.amount}}</td>
                        <td class="text-center">
                            <span *ngIf="item.isUsed" class="badge badge-pill badge-success">Validated</span>
                            <span *ngIf="!item.isUsed" class="badge badge-pill badge-danger">Not Validate</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>