import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductsService } from '../../products.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() categoriesFilters: any[] = [];
  @Output() categoryFilters: EventEmitter<any[]> = new EventEmitter<any[]>();
  public checkedCategoriesArray: any[] = [];
  constructor(public productsService: ProductsService) {
  }

  checkedFilter(event) {
    let index = this.checkedCategoriesArray.indexOf(event.target.value);
    if (event.target.checked)
      this.checkedCategoriesArray.push(event.target.value);
    else
      this.checkedCategoriesArray.splice(index, 1);
  }

  ngOnInit() {
    this.categoryFilters.emit(this.checkedCategoriesArray);
  }

  // For mobile view
  public mobileFilterBack() { }

  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar
  }

}
