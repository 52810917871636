import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PagesComponent } from './pages.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AccountComponent } from './account/account.component';
import { MembershipComponent } from './membership/membership.component';
import { MedicalInfoComponent } from './medical-info/medical-info.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EntryComponent } from './entry/entry.component';
import { ExitComponent } from './exit/exit.component';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FileUploadModule } from 'primeng/fileupload';
import { WalletComponent } from './wallet/wallet.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TabViewModule } from 'primeng/tabview';
import { SettingsComponent, DialogDetails } from './settings/settings.component';
import { AccordionModule } from 'primeng/accordion';
import { InOutComponent } from './in-out/in-out.component';
import { ScanComponent } from './scan/scan.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SuccessComponent } from './settings/success/success.component';
import { UsermgmtComponent } from './usermgmt/usermgmt.component';
import { BookingComponent } from './booking/booking.component';
import { ProductListComponent } from './shop/productList/productList.component';
import { ProductDetailsComponent } from './shop/product-detail/product-details.component';
import { ProductBoxComponent } from './shop/product-box/product-box.component';
import { NewProductComponent } from './shop/new-product/new-product.component';
import { BrandComponent } from './shop/filter/brand/brand.component';
import { CategoriesComponent } from './shop/filter/categories/categories.component';
import { ColorComponent } from './shop/filter/color/color.component';
import { PriceComponent } from './shop/filter/price/price.component';
import { Ng5SliderModule } from 'ng5-slider';
import { OrderByPipe } from './shop/order-by.pipe';
import { SanitizeHtmlPipe } from './shop/safe';
import { RatingModule } from 'primeng/rating';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { QuickPurchaseComponent } from './quick-purchase/quick-purchase.component';
import { ClubHomeComponent } from './club-home/club-home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogCodeOfConduct } from './account/account.component';
import { DeleteAccountComponent, DialogUserPassword } from './delete-account/delete-account.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NoticeBoardComponent } from './notice-board/notice-board.component';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { OfflineComponent } from './offline/offline.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { QRCodeModule } from 'angularx-qrcode';
import { PaymentSuccessComponent } from './wallet/payment-success/payment-success.component';
import { ContactUsComponent, SafePipe } from './contact-us/contact-us.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SponsorshipComponent } from './sponsorship/sponsorship.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsComponent } from './notifications/notifications.component';
import { ClubCoachesComponent } from './club-coaches/club-coaches.component';
import { ClubSponsorsComponent } from './club-sponsors/club-sponsors.component';
import { ClubEventsComponent } from './club-events/club-events.component';
import { BookingSuccessComponent } from './club-events/booking-success/booking-success.component';
import { BookingCheckComponent } from './club-events/booking-check/booking-check.component';

@NgModule({
  declarations: [PagesComponent, LoginComponent, AboutUsComponent, RegisterComponent, ProductListComponent,
    ProductDetailsComponent, ForgotPasswordComponent, VerifyEmailComponent, AccountComponent, MembershipComponent,
    MedicalInfoComponent, EntryComponent, ExitComponent, WalletComponent, HomeComponent, OrderByPipe,
    SettingsComponent, InOutComponent, ScanComponent, SuccessComponent, UsermgmtComponent, BookingComponent,
    ProductBoxComponent, NewProductComponent, BrandComponent, CategoriesComponent, ColorComponent, PriceComponent,
    SanitizeHtmlPipe, PageNotFoundComponent, DialogCodeOfConduct, OrderHistoryComponent, QuickPurchaseComponent,
    SafePipe, ClubHomeComponent, PrivacyPolicyComponent, TermsConditionsComponent, DialogUserPassword,
    DialogDetails, DeleteAccountComponent, QuestionnaireComponent, NoticeBoardComponent, DigitalSignatureComponent, OfflineComponent,
    PaymentSuccessComponent, ContactUsComponent, UserProfileComponent, SponsorshipComponent, NotificationsComponent, ClubCoachesComponent, ClubSponsorsComponent, ClubEventsComponent, BookingSuccessComponent, BookingCheckComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    CarouselModule,
    MatDialogModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCheckboxModule,
    CalendarModule,
    MatIconModule,
    TabViewModule,
    AccordionModule,
    PdfViewerModule,
    Ng5SliderModule,
    RatingModule,
    MatSlideToggleModule,
    GooglePlaceModule,
    SignaturePadModule,
    QRCodeModule,
    AutoCompleteModule,
    FileUploadModule,
    MatTooltipModule
  ],
  providers: [MatDatepickerModule]
})
export class PagesModule { }
