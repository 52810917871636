import { Component, OnInit } from '@angular/core';
import { LoginService } from "../login/login.service";
import { RegisterService } from "./register.service";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase';
import { WindowService } from './window.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../user';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [LoginService, RegisterService, WindowService]
})
export class RegisterComponent implements OnInit {
  title: string = "";
  email: string;
  phone: string;
  password: string;
  repeatPassword: string;
  firstName: string;
  lastName: string;
  over18: boolean = false;
  parentsName: string;
  option: string = "email";
  fillingType: string = "boxer";
  club: string = '';
  clubData: any[] = [];
  isEmail = true;
  windowRef: any;
  verificationCode: string;
  recaptchaVerifier: any;
  otpSent = false;
  isClubReg = false;
  isParent = true;
  clicked = false;
  titleData = [{ name: 'Mr.', value: 'Mr.' }, { name: 'Mrs.', value: 'Mrs.' }, { name: 'Miss', value: 'Miss' },
  { name: 'Ms.', value: 'Ms.' }, { name: 'Dr.', value: 'Dr.' }, { name: 'Prof.', value: 'Prof.' },
  { name: 'Rev.', value: 'Rev.' }]

  constructor(public lService: LoginService, public route: ActivatedRoute, public router: Router, public toastService: ToastrService, private win: WindowService,
    public afAuth: AngularFireAuth, public rService: RegisterService, private firestore: AngularFirestore) {
    this.route.params.subscribe(params => {
      let clubname = params['clubName'];
      let name = '';
      if (clubname)
        name = clubname.replace(/-/g, ' ');
      this.firestore.collection('clubs').get()
        .subscribe(snapshot => {
          snapshot.forEach(doc => {
            let data = doc.data();
            let option = {
              value: doc.id,
              name: data.clubDetails.name
            };
            if (name != '') {
              if (data.clubDetails.name.toString().toLowerCase() == name.toString().toLowerCase()) {
                this.club = doc.id;
                this.isClubReg = true;
              }
            }
            this.clubData.push(option)
          });
        });
    });

  }

  ngOnInit() {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: "invisible"
    })

    this.windowRef.recaptchaVerifier.render()
  }

  changeOption(event) {
    if (event == 'email') {
      this.isEmail = true;
      this.phone = '';
      this.otpSent = false;
      this.verificationCode = '';
    } else {
      this.isEmail = false;
      this.email = '';
      this.password = '';

    }
  }


  submit() {
    this.clicked = true;
    this.rService.SignUp(this.email, this.password, this.repeatPassword, this.firstName, this.lastName, this.club,
      this.fillingType, this.over18, this.parentsName, this.title).then(res => {
        this.clicked = false;
      }).catch(error => {
        this.toastService.error(error.message, 'Error');
        this.clicked = false;
      });
  }

  sendLoginCode() {
    if (!this.firstName)
      this.toastService.error('First name is required', 'Error');
    else if (!this.lastName)
      this.toastService.error('Last name is required', 'Error');
    else if (!this.phone)
      this.toastService.error('Phone number is required', 'Error');
    else if (!this.club)
      this.toastService.error('Club name is required', 'Error');
    else {
      const appVerifier = this.windowRef.recaptchaVerifier;
      this.afAuth.auth.signInWithPhoneNumber('+44' + this.phone, appVerifier)
        .then(result => {
          this.windowRef.confirmationResult = result;
          this.otpSent = true;
          this.toastService.success('OTP successfully send', 'Success');
        })
        .catch(error => { this.toastService.error(error.message, 'Error'); });
    }
  }

  verifyLoginCode() {
    if (!this.verificationCode)
      this.toastService.error('OTP is required', 'Error');
    else if (this.verificationCode.length < 6)
      this.toastService.error('OTP not valid it should be 6 digits', 'Error');
    else {
      this.windowRef.confirmationResult
        .confirm(this.verificationCode)
        .then(result => {
          this.firestore.collection('clubs').doc(this.club).get()
            .subscribe(clubResult => {
              this.firestore.collection('users', ref => ref.where('clubId', '==', this.club).limit(1).orderBy('norfid', 'desc')).get()
                .subscribe(snapshot => {
                  snapshot.forEach(doc1 => {
                    this.SetUserData(result.user, this.firstName, this.lastName, this.club, clubResult.data(), doc1.data(), this.over18, this.parentsName, this.title);
                  });
                });
            });
          this.toastService.success('OTP successfully confirmed', 'Success');
          this.toastService.success('Successfully registered', 'Success');
          this.firestore.collection("wallet").add({
            uid: result.user.uid,
            balance: 0,
            currency: "GBP"
          });
          this.router.navigate(['login']);
        })
        .catch(error => { this.toastService.error(error.message, 'Error'); });
    }
  }

  SetUserData(user, firstName, lastName, clubId, clubdata, userR, over18, parentsName, title) {
    let club = clubdata.planDetails.planDetails.filter(a => a.plan == "Incomplete");
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(`users/${user.uid}`);
    const userData: User = {
      title: title,
      uid: user.uid,
      email: '',
      phoneNo: user.phoneNumber,
      firstName: firstName,
      lastName: lastName,
      photoURL: user.photoURL,
      emailVerified: true,
      roles: 'clubUser',
      clubId: clubId,
      RFID: (parseInt(userR.RFID) + 1) + '',
      norfid: parseInt(userR.RFID) + 1,
      group: club.length > 0 ? club[0].plan : clubdata.planDetails.planDetails[0].plan,
      groupType: clubdata.groupType.groupType[0].name,
      club_rfid: clubId + '_' + (parseInt(userR.RFID) + 1),
      balance: 0,
      currency: 'GBP',
      isDelete: false,
      isActive: false,
      fillingType: "boxer",
      over18: over18,
      parentsName: over18 ? '' : parentsName,
      accountDetailsAddedDate: null,
      accountDetailsUpdatedDate: null,
      medicalDetailsAddedDate: null,
      medicalDetailsUpdatedDate: null,
      membershipDetailsAddedDate: null,
      membershipDetailsUpdatedDate: null,
      createdDate: new Date(),
      deletedDate: null
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  redirectUrl(url) {
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }

  ageChecked(event) {
    this.isParent = !event;
    this.parentsName = event ? "" : this.parentsName;
  }
}

export class PhoneNumber {
  country: string;
  area: string;
  prefix: string;
  line: string;

  // format phone numbers as E.164
  get e164() {
    const num = this.country + this.area + this.prefix + this.line
    return `+${num}`
  }

}