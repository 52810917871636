<!-- about section Start-->
<section class="gym format position-sticky" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img *ngIf="clubData" onerror="this.src='assets/images/gym/l5.png';"
                                [src]="clubData?.clubDetails?.logoUrl" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-head-text mb-2">
                            <h3 class="gradient-text">welcome to {{club}}</h3>
                        </div>
                        <div class="format-sub-text mb-2">
                            <p class="p-light about-para" style="text-transform: none;"
                                [innerHtml]="clubData?.clubDetails?.description"></p>
                        </div>
                        <div class="form-inline">
                            <a *ngIf="!isSignIn" routerLink="/{{clubName}}/register"
                                class="btn btn-default primary-btn mr-2">Register Now</a>
                            <qrcode [elementType]="elementType" [level]="level" [qrdata]="qrdata" [scale]="scale"
                                [width]="width"></qrcode>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->

<!-- <app-booking></app-booking> -->

<!-- <section *ngIf="coachData.length > 0" class="event speaker set-relative bg-g" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0"><span class=""><img
                                    class="img-b" [src]="boxingUrl"> our Coaches <img class="img-b"
                                    [src]="boxingUrl"></span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container" *ngFor="let trainer of coachData">
                <div class="text-center">
                    <div class="team-img">
                        <img onerror="this.src='../../assets/images/nouser.png';" [src]="trainer?.imageUrl" width="330"
                            height="250">
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">{{trainer.firstName}} {{trainer.lastName}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!--trainer section start-->
<!-- <section *ngIf="coachData.length > 1" class="bg-g gym trainers">
    <div class="container overflow-hide">
        <div class="row">
            <div class="col-12">
                <div class="title mb-0">
                    <div class="text-center">
                        <div class="main-title mb-0">
                            <h3><img class="img-b" [src]="boxingUrl"> our Coaches <img class="img-b" [src]="boxingUrl">
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="trainercarouselOptions" class="trainers-slider">
                    <ng-container *ngFor="let trainer of coachData">
                        <ng-template carouselSlide class="item">
                            <img onerror="this.src='../../assets/images/noimage.jpg';" [src]="trainer?.imageUrl"
                                height="200" width="200">
                            <div class="text-center trainers-info">
                                <h5>{{trainer.firstName}} {{trainer.lastName}}</h5>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->
<!--trainer section end-->
<app-club-events></app-club-events>
<!--sponsorship section start-->
<section *ngIf="sponsorshipUsersData.length > 0" class="event team-sec speaker set-relative bg-g" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-12 p-0">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0"><span class=""><img
                                    class="img-b" [src]="boxingUrl"> Sponsor our boxers & club <img class="img-b"
                                    [src]="boxingUrl"></span>
                        </h3>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of sponsorshipUsersData;let i=index" class="col-md-4 col-sm-6 speker-container">
                <div class="card">
                    <div class="team-img" style="border-radius: 0.25rem 0.25rem 0px 0px;">
                        <a [href]="item?.publicUrl">
                            <img *ngIf="item?.images.length > 0" class="img-b-main" src="{{item?.images[0]}}">
                        </a>
                        <a [href]="item?.publicUrl">
                            <img *ngIf="item?.images.length == 0" class="img-b-main" src="{{item?.imageUrl}}"
                                onerror="this.src='../../assets/images/noimage.jpg';">
                        </a>
                        <!-- <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li *ngIf="item?.facebookLink != ''">
                                    <a [href]="item?.facebookLink" target="_blank">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.otherLink != ''">
                                    <a [href]="item?.otherLink" target="_blank">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.instagramLink != ''">
                                    <a [href]="item?.instagramLink" target="_blank">
                                        <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.publicUrl != ''">
                                    <a [href]="item?.publicUrl" target="_blank">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                    <div class="card-body" style="min-height: 140px;">
                        <h5 class="e-name text-center">{{item?.firstName}} {{item?.lastName}}</h5>
                        <!-- <h6 *ngIf="item.description" class="ff text-center" style="text-transform: none;">{{(show == i)
                            ? item?.description : item?.description | slice:0:200}} <a
                                *ngIf="item.description.length > 200" class="text-info" (click)="saveShow(i)">{{ show ==
                                i ? 'Show
                                less': 'Show More' }}</a></h6> -->
                        <h6 *ngIf="item.description" class="ff text-center" style="text-transform: none;">
                            {{item?.description | slice:0:200}} <a *ngIf="item.description.length > 200"
                                class="text-info" [href]="item?.publicUrl">{{ 'Show More' }}</a></h6>
                    </div>
                    <div class="card-footer">
                        <h5 class="mb-2 text-left">
                            <span class="text-success"><b>{{(item?.raisedAmount || 0) | currency:'GBP'}}</b> <span
                                    class="text-muted ml-2">raised</span></span><span
                                class="float-right"><b>{{item?.donorsCount || 0}}</b> <span
                                    class="text-muted ml-2">sponsors</span></span>
                        </h5>
                        <p>
                            <ngb-progressbar type="info" [animated]="true" [max]="item?.amount" height="10px"
                                [value]="item?.raisedAmount">
                            </ngb-progressbar>
                        </p>
                        <a [href]="item?.publicUrl" class="btn btn-block btn-info btn-sm mt-4 rounded-pill">SPONSOR
                            NOW</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--sponsorship section end-->

<!--pricing section start-->
<section *ngIf="clubData?.displayPricing" class="gym pricing position-sticky bg-g" id="plan">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0"><span class=""><img
                                    class="img-b" [src]="boxingUrl"> pricing <img class="img-b"
                                    [src]="boxingUrl"></span>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="pricingcarouselOptions" class="price-margin pricing-slider">
                    <ng-container *ngFor="let price of clubData?.planDetails?.planDetails;let i =index">
                        <ng-template carouselSlide class="item">
                            <div class="price-container price-margin hover-overlay shadows bg-white text-center">
                                <div class="price-feature-container set-relative">
                                    <div class="feature-text">
                                        <span class="flaticon-{{icons[i]}} feature-icon"></span>
                                        <h4 class="feature-text-heading bold text-uppercase">{{price?.plan}}</h4>
                                        <hr class="set-border">
                                    </div>
                                    <div class="price-value">
                                        <h6 class="price text-center">£<span
                                                class="large">{{price?.price}}</span>/session
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--pricing section end-->

<!--notice board start-->
<app-notice-board></app-notice-board>
<!--notice board end-->

<!--brand slider start-->
<section *ngIf="sponsorsData.length > 0" class="gym brand-slider" style="padding-bottom: 125px;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0"><span class=""><img
                                    class="img-b" [src]="boxingUrl"> Featured Sponsors <img class="img-b"
                                    [src]="boxingUrl"></span>
                        </h3>
                    </div>
                </div>
            </div>
            <div *ngFor="let brand of sponsorsData" class="col-md-3 m-auto text-center">
                <a [href]="brand?.websiteLink" target="_blank">
                    <img [src]="brand?.logo" class="img-sp">
                </a>
            </div>
        </div>
    </div>
</section>
<!--brand slider end-->