import { Component, OnInit } from '@angular/core';
import { NavService } from '../../shared/service/nav.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  message = '';
  constructor(public navServices: NavService, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    this.getUsersDetails();
  }

  getUsersDetails() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.firestore.collection('users').doc(user.uid).get()
        .subscribe(doc => {
          let userData = doc.data();
          if (userData.isDelete == false) {
            if (userData.englandBoxingStatus) {
              if (userData.englandBoxingStatus == "MemberEmailVerificationPending") {
                this.message = "ACTION REQUIRED : England boxing account email verification pending. Please find email and click on green button to verify. No need to enter any details, just click the button.";
              }
            }
          }
        });
    }
  }
}
