<div class=" theme-card">
    <mat-vertical-stepper [linear]="isLinear" #stepper class="w-100">
        <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Doctor Details</ng-template>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Doctor Name</mat-label>
                            <input matInput placeholder="Doctor Name" formControlName="doctorName" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Doctor No.</mat-label>
                            <input matInput placeholder="Doctor No." formControlName="doctorNo" type="text"
                                pattern="[0-9]*" required>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Doctor Address</mat-label>
                            <input matInput ngx-google-places-autocomplete [options]='options'
                                formControlName="doctorAddress"
                                (onAddressChange)="addressChange($event)" />
                            <!-- <textarea matInput placeholder="Doctor Address" formControlName="doctorAddress"
                                required></textarea> -->
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
            <form [formGroup]="secondFormGroup" #f2="ngForm">
                <ng-template matStepLabel>Disability</ng-template>
                <div class="alert alert-info mb-2">Any impairment which may impact on your ability to participate in
                    this clubs activities</div>
                <div class="row">
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Visual *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="visual" required>
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="secondFormGroup.get('visual').hasError('required') && f2.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Hearing *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="hearing">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="secondFormGroup.get('hearing').hasError('required') && f2.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Physical *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="physical">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="secondFormGroup.get('physical').hasError('required') && f2.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Learning *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="learning">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="secondFormGroup.get('learning').hasError('required') && f2.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Other *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="other">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="secondFormGroup.get('other').hasError('required') && f2.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>If Yes to any of the above please supply details
                            </mat-label>
                            <textarea matInput placeholder="If Yes to any of the above please state details"
                                formControlName="details"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
            <form [formGroup]="thirdFormGroup" #f3="ngForm">
                <ng-template matStepLabel>Sporting Information</ng-template>
                <div class="alert alert-info mb-2">Have you any experience in the following?</div>
                <div class="row">
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Boxing *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="boxing">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('boxing').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Martial Arts *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="martialArts">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('martialArts').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Football *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="football">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('football').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Rugby *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="rugby">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('rugby').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Hockey *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="hockey">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('hockey').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Tennis *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="tennis">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('tennis').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Other *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="other">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="thirdFormGroup.get('other').hasError('required') && f3.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>If Yes to any of the above please supply details
                            </mat-label>
                            <textarea matInput placeholder="If Yes to any of the above please supply details"
                                formControlName="details"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Boxing REG Number</mat-label>
                            <input matInput placeholder="Boxing REG Number" formControlName="regNo">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Club Name</mat-label>
                            <input matInput placeholder="Club Name" formControlName="clubName">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Previous Surname</mat-label>
                            <input matInput placeholder="Previous Surname" formControlName="previousSurname">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Previous Sports</mat-label>
                            <input matInput placeholder="Previous Sports" formControlName="previousSports">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-12 ">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="type">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of type" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-12 ">
                        <h6 class="mb-2 font-600">Have you previously been affilated with England Boxing?</h6>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="isEnglandBoxing">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of option" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-12 ">
                        <h6 class="mb-2 font-600">Have you previously been affilated any Other Boxing Organisation?</h6>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="isOtherBoxing">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of option" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-4 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Organisation Name</mat-label>
                            <input matInput placeholder="Organisation Name" formControlName="organisation">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-1 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>WIN</mat-label>
                            <input matInput placeholder="WIN" formControlName="win" type="number">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-1 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>LOSS</mat-label>
                            <input matInput placeholder="LOSS" formControlName="loss" type="number">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 ">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Full or Semi Contact</mat-label>
                            <input matInput placeholder="Full or Semi Contact" formControlName="fullSemiContact">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>England Boxing Email</mat-label>
                            <input matInput placeholder="England Boxing Email" formControlName="englandBoxingEmail">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="fourthFormGroup">
            <form [formGroup]="fourthFormGroup" #f4="ngForm">
                <ng-template matStepLabel>Medical Details</ng-template>
                <div class="alert alert-info mb-2">Do you have any of the following medical conditions?</div>
                <div class="row">
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Diabetes *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="diabetes">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('diabetes').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">High Blood Pressure *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="highBloodPressure">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('highBloodPressure').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Heart Problems *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="heartProblems">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('heartProblems').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="mb-2 font-600">Chest / Lung Problems *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="chestLungProblems">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('chestLungProblems').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Asthma *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="asthma">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('asthma').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Epilepsy *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="epilepsy">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('epilepsy').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <p class="mb-2 font-600">Fainting / Dizziness *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="faintingDizziness">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fourthFormGroup.get('faintingDizziness').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>If Yes to any of the above please state details:
                            </mat-label>
                            <textarea matInput placeholder="If Yes to any of the above please state details"
                                formControlName="details"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>List any medications you wish us to be aware of
                            </mat-label>
                            <textarea matInput placeholder="List any medications you wish us to be away of"
                                formControlName="medications"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>

            </form>
        </mat-step>
        <mat-step [stepControl]="fifthFormGroup">
            <form [formGroup]="fifthFormGroup">
                <ng-template matStepLabel>Medical Information</ng-template>
                <div class="alert alert-info mb-2">Please detail below any medical information that our coaches
                    should be aware of</div>
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Medical Condition</mat-label>
                            <input matInput placeholder="Medical Condition" formControlName="medicalCondition">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Current Treatment</mat-label>
                            <input matInput placeholder="Current Treatment" formControlName="currentTreatment">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Current Medication</mat-label>
                            <input matInput placeholder="Current Medication" formControlName="currentMedication">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb-2 font-600">Allergies</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="allergies">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of meds" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>If Yes to the above please supply details
                            </mat-label>
                            <textarea matInput placeholder="If Yes to the above please supply details"
                                formControlName="details"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12 mb-3">
                        <mat-checkbox class="example-margin ml-1 mr-2" formControlName="consent">
                        </mat-checkbox>I am over the age of 18
                        or am a parent or guardian need above and give permission for the coach in charge to
                        give the immediate necessary authority on my behalf for any medical or surgical
                        treatment recommended by competent medical authorities, where it would be contrary for
                        my son / daughter interest, in the Dr's opinion, for any delay to be incurred by seeking
                        my personal consent.
                    </div>
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Parents / Guardians Name Where Applicable</mat-label>
                            <input matInput placeholder="Parents / Guardians Name Where Applicable"
                                formControlName="parentsName">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn btn-default btn-success radius-0" [disabled]="!fourthFormGroup.valid"
                            (click)="submit()">Submit</button>
                    </div>
                </div>

            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>