<section class="login-page section-b-space pb-3 pt-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <h5 for="email" class="text-center">Search by First Name</h5>
                            <p-autoComplete [(ngModel)]="name" [suggestions]="filteredUsers"
                                (completeMethod)="filterUsers($event)" field="fullName" [minLength]="1" name="username"
                                (onSelect)="selectName($event)"></p-autoComplete>
                            <div class="separator">OR</div>
                            <h5 for="email" class="text-center">Search by RFID</h5>
                            <input type="text" autocomplete="off" class="form-control text-uppercase" [(ngModel)]="rfid"
                                name="rfid"  required>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                                (click)="submitDetails(rfid)">Go</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <h5 for="email" class="text-center">Total Members</h5>
                            <h3 class="text-center">{{totalUsers}}</h3>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div *ngIf="usersData.length > 0" class="alert alert-info m-auto w-25 text-center">
    <h5 class="mb-0">{{usersData.length}} users found</h5>
</div> -->
<div *ngIf="data" class="row pr-2 pl-2">
    <div class="col-md-12 text-center">
        <img [src]="data.personalDetails.imageUrl" class="mb-2" onerror="this.src='../../assets/images/noimage.jpg';"
            style="border-radius: 50%;width:80px;height:80px;border: solid 1px grey;">
    </div>
    <div class="col-md-4 mb-1">
        <div class="card">
            <div class="card-header font-weight-bold">
                <i class="fas fa-user-cog"></i> Personal Details
            </div>
            <div class="card-body">
                <table class="table table-striped small">
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Name</td>
                            <td>{{data?.personalDetails?.firstName}}
                                {{data?.personalDetails?.lastName}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">DOB</td>
                            <td>{{data?.personalDetails?.dob | date:'dd/MM/yyyy'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Email</td>
                            <td>{{data?.personalDetails?.email}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Sex</td>
                            <td>{{data?.personalDetails?.sex}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Mobile No.</td>
                            <td>{{data?.personalDetails?.mobileNo}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Address</td>
                            <td>{{data?.personalDetails?.address}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Home Telephone No.</td>
                            <td>{{data?.personalDetails?.homeTelephoneNo}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">PostCode</td>
                            <td>{{data?.personalDetails?.postcode}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Photography</td>
                            <td><span *ngIf="photography">Yes Photos</span><span *ngIf="!photography">No Photos</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-1">
        <div class="card">
            <div class="card-header font-weight-bold">
                <i class="fas fa-ambulance"></i> Emergency Details
            </div>
            <div class="card-body">
                <table class="table table-striped small">
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Name</td>
                            <td>{{data?.emergencyDetails?.emergencyContactName}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Relationship</td>
                            <td>{{data?.emergencyDetails?.emergencyRelationship}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Address</td>
                            <td>{{data?.emergencyDetails?.emergencyContactAddress}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Contact No.</td>
                            <td>{{data?.emergencyDetails?.emergencyContactNo}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-1">
        <div class="card">
            <div class="card-header font-weight-bold">
                <i class="fas fa-briefcase-medical"></i> Medical Details
            </div>
            <div class="card-body">
                <table class="table table-striped small">
                    <tbody>
                        <tr>
                            <td class="font-weight-bold">Asthma</td>
                            <td>{{medicalData?.diseaseDetails?.asthma}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Chest / Lung Problems</td>
                            <td>{{medicalData?.diseaseDetails?.chestLungProblems}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Diabetes</td>
                            <td>{{medicalData?.diseaseDetails?.diabetes}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Epilepsy</td>
                            <td>{{medicalData?.diseaseDetails?.epilepsy}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Fainting Dizziness</td>
                            <td>{{medicalData?.diseaseDetails?.faintingDizziness}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Heart Problems</td>
                            <td>{{medicalData?.diseaseDetails?.heartProblems}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">High Blood Pressure</td>
                            <td>{{medicalData?.diseaseDetails?.highBloodPressure}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Details</td>
                            <td>{{medicalData?.diseaseDetails?.details}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Medications</td>
                            <td>{{medicalData?.diseaseDetails?.medications}}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Allergies</td>
                            <td>{{medicalData?.medicalDetails?.allergies}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngFor="let item of usersData" class="border border-dark p-2 row mt-2">
    <div class="col-md-2 text-center m-auto">
        <img [src]="item?.personalDetails?.imageUrl" class="mb-2" onerror="this.src='../../assets/images/noimage.jpg';"
            style="border-radius: 50%;width:80px;height:80px;border: solid 1px grey;">
    </div>
    <div class="col-md-10 mb-1">
        <div class="card mb-2">
            <div class="card-header font-weight-bold">
                <i class="fas fa-user-cog"></i> Personal Details
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-striped small table-sm mb-0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>DOB</th>
                            <th>Email</th>
                            <th>Sex</th>
                            <th>Mobile No.</th>
                            <th>Address</th>
                            <th>Home Telephone No.</th>
                            <th>PostCode</th>
                            <th>Photography</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{item?.account?.personalDetails?.firstName}}
                                {{item?.account?.personalDetails?.lastName}}</td>
                            <td>{{item?.account?.personalDetails?.dob | date:'dd/MM/yyyy'}}
                            </td>
                            <td>{{item?.account?.personalDetails?.email}}
                            </td>
                            <td>{{item?.account?.personalDetails?.sex}}
                            </td>
                            <td>{{item?.account?.personalDetails?.mobileNo}}
                            </td>
                            <td>{{item?.account?.personalDetails?.address}}
                            </td>
                            <td>{{item?.account?.personalDetails?.homeTelephoneNo}}
                            </td>
                            <td>{{item?.account?.personalDetails?.postcode}}
                            </td>
                            <td>{{item?.photography}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-2">
            <div class="card-header font-weight-bold">
                <i class="fas fa-ambulance"></i> Emergency Details
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-striped small table-sm mb-0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Relationship</th>
                            <th>Address</th>
                            <th>Contact No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{item?.account?.emergencyDetails?.emergencyContactName}}
                            </td>
                            <td>{{item?.account?.emergencyDetails?.emergencyRelationship}}
                            </td>
                            <td>{{item?.account?.emergencyDetails?.emergencyContactAddress}}
                            </td>
                            <td>{{item?.account?.emergencyDetails?.emergencyContactNo}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-2">
            <div class="card-header font-weight-bold">
                <i class="fas fa-briefcase-medical"></i> Medical Details
            </div>
            <div class="card-body p-0 table-responsive">
                <table class="table table-striped small table-sm mb-0">
                    <thead>
                        <tr>
                            <th>Asthma</th>
                            <th>Chest / Lung Problems</th>
                            <th>Diabetes</th>
                            <th>Epilepsy</th>
                            <th>Fainting Dizziness</th>
                            <th>Heart Problems</th>
                            <th>High Blood Pressure</th>
                            <th>Details</th>
                            <th>Medications</th>
                            <th>Allergies</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{item?.medical?.diseaseDetails?.asthma}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.chestLungProblems}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.diabetes}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.epilepsy}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.faintingDizziness}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.heartProblems}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.highBloodPressure}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.details}}
                            </td>
                            <td>{{item?.medical?.diseaseDetails?.medications}}
                            </td>
                            <td>{{item?.medical?.medicalDetails?.allergies}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->