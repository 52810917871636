import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  data: any;
  constructor(public router: Router, public firestore: AngularFirestore) {
    this.firestore.collection("aboutUs").doc("WzPQBWfBuqW3BOwbt9ag").get().subscribe(snapshot => {
      this.data = snapshot.data();
    });
  }

  ngOnInit() {
  }

  aboutTestimonial = [
    {
      img: "assets/images/gym/testimonial/L3-1.png",
      text: "An absolute life saver. Not only does it offer functionality to help take card payments but it also allows us to track users activity and monitor those who never show up!",
      name: "Martin Urch",
      rating: 5
    },
    {
      img: "assets/images/gym/testimonial/L3-1.png",
      text: "Saved us hours of endless admin work. We were spending at least 4 hours a week on admin just for a few boxers. With this software where have been able to grow our classes and keep things more organised than ever.",
      name: "David Botha",
      rating: 5
    }
  ]

  services = [
    {
      text: "Client Dashboard",
      icon: "fa fa-dashboard fa-4x",
      description: "Each member gets their own personal login dashboard to enable them to monitor their spend, visits, and update/edit information where applicable."
    },
    {
      text: "Unlimited Members",
      icon: "fa fa-users fa-4x",
      description: "Unlike other systems we offer an unlimited capability so there are no restrictions on your account, and we grow with you."
    },
    {
      text: "Member Analytics",
      icon: "fa fa-line-chart fa-4x",
      description: "Looking to monitor in-depth information about your members, who is active and who is inactive? You can do this from the Club dashboard."
    },
    {
      text: "Parental Control",
      icon: "fa fa-child fa-4x",
      description: "Save time with our mass export and importing functionality to upload froma basic Excel spreadsheet."
    },
    {
      text: "Members Portal",
      icon: "fa fa-rss fa-4x",
      description: "Looking to amend users details or add your own members? We have you covered with our easy to use Admin panel."
    },
    {
      text: "Online & Offline Payment Gateway",
      icon: "fa fa-cc-stripe fa-4x",
      description: "PamIT allows you the ability to take online payments via our card gateway, but also add in additional funds to members based on cash payments."
    },
    {
      text: "Email Broadcasting",
      icon: "fa fa-bullhorn fa-4x",
      description: "Need to get a message out to the club or particular members in a hurry? Our mass email broadcasting service can help do just that."
    },
    {
      text: "Attendance via Wristband technology",
      icon: "fa fa-hourglass fa-4x",
      description: "Monitor members attendance, and see who is maximising their visits, all based on wristband technology."
    },
    {
      text: "Portal for Regular News & Updates",
      icon: "fa fa-newspaper-o fa-4x",
      description: "Keep your members up to date with club/gym news with our noticeboard functionality that enables clubs to share news with their members."
    },
    {
      text: "Emergency Information to Hand",
      icon: "fa fa-ambulance fa-4x",
      description: "Been an injury or need to get hold of someone quickly? With a scan of the wristband or quick search all information comes up instantly."
    },
    {
      text: "Mass Import & Export",
      icon: "fa fa-download fa-4x",
      description: "Save time with our mass export and importing functionality to upload froma basic Excel spreadsheet."
    },
    {
      text: "Notifications & Reminders",
      icon: "fa fa-bell-o fa-4x",
      description: "Fed up of chasing payments or requesting people to update their records? Why not automate it with our automated reminder and renewal system."
    },
    {
      text: "Club reporting",
      icon: "fa fa-th fa-4x",
      description: "Report back on your clubs progress, including earnings, amount of members visiting and how many inactive members you have."
    },
    {
      text: "Safe and secure",
      icon: "fa fa-shield fa-4x",
      description: "Using the best in technology for security measures your data is highly protected and risk free 24/7."
    },
    {
      text: "Club Sign up forms",
      icon: "fa fa-file-text-o fa-4x",
      description: "Tired of filling out forms? How about an automated process that takes the admin side away? We have you covered."
    }
  ]
  aboutTestimonialCarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/gym/testimonial/left.png">', '<img src="assets/images/gym/testimonial/right.png">'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }

  addClub() {
    this.router.navigate(["/register"]);
    //window.open("https://boxing-admin-frontend.web.app/#/session/register-new-club", "_blank");
  }
}
