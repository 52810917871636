import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class MedicalInfoService {

    constructor(private firestore: AngularFirestore) { }

    AddUserMedicalInfo(dd, disability, sd, fd, md) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'add');
        return this.firestore.collection('medicalHistory').add({
            userId: user.uid,
            doctorDetails: {
                doctorName: dd.doctorName,
                doctorAddress: dd.doctorAddress,
                doctorNo: dd.doctorNo
            },
            disability: {
                visual: disability.visual,
                hearing: disability.hearing,
                physical: disability.physical,
                learning: disability.learning,
                other: disability.other,
                details: disability.details
            },
            sportingDetails: {
                boxing: sd.boxing,
                martialArts: sd.martialArts,
                football: sd.football,
                rugby: sd.rugby,
                hockey: sd.hockey,
                tennis: sd.tennis,
                other: sd.other,
                details: sd.details,
                regNo: sd.regNo,
                clubName: sd.clubName,
                previousSurname: sd.previousSurname,
                previousSports: sd.previousSports,
                type: sd.type,
                isEnglandBoxing: sd.isEnglandBoxing,
                isOtherBoxing: sd.isOtherBoxing,
                organisation: sd.organisation,
                win: sd.win,
                loss: sd.loss,
                fullSemiContact: sd.fullSemiContact,
                englandBoxingEmail: sd.englandBoxingEmail
            },
            diseaseDetails: {
                diabetes: fd.diabetes,
                highBloodPressure: fd.highBloodPressure,
                heartProblems: fd.heartProblems,
                chestLungProblems: fd.chestLungProblems,
                asthma: fd.asthma,
                epilepsy: fd.epilepsy,
                faintingDizziness: fd.faintingDizziness,
                details: fd.details,
                medications: fd.medications
            },
            medicalDetails: {
                medicalCondition: md.medicalCondition,
                currentTreatment: md.currentTreatment,
                currentMedication: md.currentMedication,
                allergies: md.allergies,
                details: md.details,
                consent: md.consent,
                parentsName: md.parentsName,
            },
            createdDate: new Date(),
            updatedDate: null
        })
    }

    UpdateUserMedicalInfo(dd, disability, sd, fd, md, docid) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'update');
        return this.firestore.doc('medicalHistory/' + docid).update({
            doctorDetails: {
                doctorName: dd.doctorName,
                doctorAddress: dd.doctorAddress,
                doctorNo: dd.doctorNo
            },
            disability: {
                visual: disability.visual,
                hearing: disability.hearing,
                physical: disability.physical,
                learning: disability.learning,
                other: disability.other,
                details: disability.details
            },
            sportingDetails: {
                boxing: sd.boxing,
                martialArts: sd.martialArts,
                football: sd.football,
                rugby: sd.rugby,
                hockey: sd.hockey,
                tennis: sd.tennis,
                other: sd.other,
                details: sd.details,
                regNo: sd.regNo,
                clubName: sd.clubName,
                previousSurname: sd.previousSurname,
                previousSports: sd.previousSports,
                type: sd.type,
                isEnglandBoxing: sd.isEnglandBoxing,
                isOtherBoxing: sd.isOtherBoxing,
                organisation: sd.organisation,
                win: sd.win,
                loss: sd.loss,
                fullSemiContact: sd.fullSemiContact,
                englandBoxingEmail: sd.englandBoxingEmail
            },
            diseaseDetails: {
                diabetes: fd.diabetes,
                highBloodPressure: fd.highBloodPressure,
                heartProblems: fd.heartProblems,
                chestLungProblems: fd.chestLungProblems,
                asthma: fd.asthma,
                epilepsy: fd.epilepsy,
                faintingDizziness: fd.faintingDizziness,
                details: fd.details,
                medications: fd.medications
            },
            medicalDetails: {
                medicalCondition: md.medicalCondition,
                currentTreatment: md.currentTreatment,
                currentMedication: md.currentMedication,
                allergies: md.allergies,
                details: md.details,
                consent: md.consent,
                parentsName: md.parentsName,
            },
            updatedDate: new Date()
        })
    }

    userUpdate(id, action) {
        if (action == 'add') {
            this.firestore.doc('users/' + id).update({
                medicalDetailsAddedDate: new Date(),
            })
        } else {
            this.firestore.doc('users/' + id).update({
                medicalDetailsUpdatedDate: new Date(),
                detailsVerified: false
            })
        }
    }
}