<section>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <h2 class="mt-2 text-center">Payment is {{status}}!</h2>
            <h4 *ngIf="status == 'succeeded'" class="text-center">Your Ticket </h4>
            <article *ngIf="status == 'succeeded'" class="border border-dashed card fl-left mt-4 mb-5">
                <section class="date">
                    <time>
                        <span>{{eventData?.startDate | date:'d'}}</span><span>{{eventData?.startDate |
                            date:'MMMM'}}</span>
                    </time>
                </section>
                <section class="card-cont">
                    <h2>{{bookingData?.bookingId}}</h2>
                    <h4>{{eventData?.eventName}}</h4>
                    <div class="even-date">
                        <i class="fa fa-calendar"></i>
                        <time class="pl-1">
                            <p>{{eventData?.startDate | date:'EEEE, MMMM d, y'}}</p>
                            <p>From {{eventData?.startDate |
                                date:'hh:mm a'}} To {{eventData?.endDate |
                                date:'hh:mm a'}}</p>
                        </time>
                    </div>
                    <div class="even-date">
                        <i class="fa fa-ticket-alt"></i>
                        <time class="pl-1">
                            <ul class="border-bottom">
                                <li  *ngFor="let item of bookingData?.tickets">
                                    <span>{{item?.type}} : {{item?.amount}} X {{item?.quantity}} =
                                        £{{item?.amount
                                        *
                                        item?.quantity}}</span>
                                </li>
                            </ul>
                        </time>
                    </div>
                    <div class="even-info">
                        <i class="fa fa-map-marker"></i>
                        <p class="pl-1">
                            {{eventData?.location}}
                        </p>
                    </div>
                    <p class="font-weight-bold mt-2"><i class="fa fa-money"></i> Total Amount : £{{bookingData?.amount}}
                    </p>
                </section>
            </article>
            <div  class="text-center mb-5">
                <!-- <a routerLink="/{{clubName}}" title="Download Ticket" class="border mr-3 p-1">Download Ticket</a> -->
                <a *ngIf="status == 'succeeded'" (click)="emailTicket()" title="Email Ticket" class="border mr-3 p-1">Email Ticket</a>
                <a routerLink="/{{clubName}}" title="Back To Home" class="border mr-3 p-1">Back To Home</a>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</section>