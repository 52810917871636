import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
    providedIn: 'root'
})

export class AccountService {

    constructor(private firestore: AngularFirestore) { }

    AddUserAccount(pd, ed) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'add', pd);
        return this.firestore.collection('account').add({
            userId: user.uid,
            personalDetails: {
                firstName: pd.firstName,
                lastName: pd.lastName,
                dob: pd.dob,
                sex: pd.sex,
                address: pd.address,
                postcode: pd.postcode,
                email: pd.email,
                homeTelephoneNo: pd.homeTelephoneNo,
                mobileNo: pd.mobileNo,
                imageUrl: '',
                volunteer: pd.volunteer
            },
            emergencyDetails: {
                emergencyContactName: ed.emergencyContactName,
                emergencyRelationship: ed.emergencyRelationship,
                emergencyContactAddress: ed.emergencyContactAddress,
                emergencyContactNo: ed.emergencyContactNo
            },
            createdDate: new Date(),
            updatedDate: null
        })
    }

    UpdateUserAccount(pd, ed, docid) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'update', pd);
        return this.firestore.doc('account/' + docid).update({
            personalDetails: {
                firstName: pd.firstName,
                lastName: pd.lastName,
                dob: pd.dob,
                sex: pd.sex,
                address: pd.address,
                postcode: pd.postcode,
                email: pd.email,
                homeTelephoneNo: pd.homeTelephoneNo,
                mobileNo: pd.mobileNo,
                imageUrl: pd.imageUrl,
                volunteer: pd.volunteer
            },
            emergencyDetails: {
                emergencyContactName: ed.emergencyContactName,
                emergencyRelationship: ed.emergencyRelationship,
                emergencyContactAddress: ed.emergencyContactAddress,
                emergencyContactNo: ed.emergencyContactNo
            },
            updatedDate: new Date()
        })
    }

    userUpdate(id, action, pd) {
        if (action == 'add') {
            this.firestore.doc('users/' + id).update({
                firstName: pd.firstName,
                lastName: pd.lastName,
                accountDetailsAddedDate: new Date(),
            })
        } else {
            this.firestore.doc('users/' + id).update({
                firstName: pd.firstName,
                lastName: pd.lastName,
                accountDetailsUpdatedDate: new Date(),
                detailsVerified: false
            })
        }
    }
}