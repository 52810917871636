<header class="bg-light custom-scroll ecommerce loding-header nav-abs">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav class="nav-ctm">
                    <a (click)="redirectUrlAll('')" onerror="this.src='../../assets/images/logo/5.png';"
                        class="logo-erapper"><img style="height: 50px;" alt="" [src]="navServices.getLogo()"
                            class="img-fluid rounded-circle"></a>
                    <a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide"
                        (click)="closeOverlay()"></a>
                    <div class="responsive-btn">
                        <a (click)="toggleSidebar()">
                            <i class="fa fa-bars text-white" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
                        <div class="responsive-btn">
                            <a (click)="toggleSidebar()">
                                <h5>back</h5>
                            </a>
                        </div>
                        <ul class="main-menu">
                            <!-- 1st Level Menu -->
                            <li
                                *ngIf="navServices.getUserName() && navServices.getUserEmail() && navServices.getUserRole()">
                                <a class="mega-menu mega-menu-container" title="Home" (click)="redirectUrlAll('')">
                                    <i class="fas fa-home mr-1"></i> Home
                                </a>
                            </li>
                            <li *ngFor="let menuItem of navServices.getAll()"
                                [class]="menuItem.megaMenu ? 'mega-menu' : ''">
                                <!-- Link -->
                                <a (click)="redirectUrlAll(menuItem.path)" routerLinkActive="active"
                                    *ngIf="menuItem.type === 'link' && menuItem.role === 'all'">
                                    <i class="{{menuItem.icon}}"></i> {{menuItem.title}}
                                </a>
                                <a (click)="redirectUrl(menuItem.path)" routerLinkActive="active"
                                    *ngIf="menuItem.type === 'link' && menuItem.role === 'user'">
                                    <i class="{{menuItem.icon}}"></i> {{menuItem.title}}
                                </a>
                            </li>
                            <li
                                *ngIf="navServices.getUserName() && navServices.getUserEmail() && navServices.getUserRole()">
                                <a class="mega-menu mega-menu-container" title="Sponsorship" (click)="sponsorship()">
                                    <i class="fas fa-donate mr-1"></i> Sponsorship
                                </a>
                            </li>
                            <li
                                *ngIf="navServices.getUserName() && navServices.getUserEmail() && navServices.getUserRole()">
                                <a class="mega-menu mega-menu-container" title="Delete Account"
                                    (click)="deleteAccount()">
                                    <i class="fas fa-trash mr-1"></i> Delete Account
                                </a>
                            </li>
                            <li *ngIf="navServices.getUserName() && navServices.getUserEmail()">
                                <a class="mega-menu mega-menu-container" title="Reset Password"
                                    (click)="loginService.resetPassword()">
                                    <i class="fas fa-unlock-alt mr-1"></i> Reset Password
                                </a>
                            </li>
                            <li *ngIf="navServices.getUserName()">
                                <a class="mega-menu mega-menu-container" title="Logout"
                                    (click)="loginService.SignOut()"><i class="fas fa-sign-out-alt mr-1"></i>Logout</a>
                            </li>
                            <li [class.dropdown]="!getIsMobileResolution()"
                                class="border border-radius ng-star-inserted pr-2 rounded-pill text-left"
                                *ngIf="navServices.getUserName() && !getIsMobileResolution()">
                                <img [src]="navServices.getUserImage()"
                                    onerror="this.src='../../assets/images/noimage.jpg';" height="40px"
                                    class="mr-1 rounded-circle"><span class="mr-2">{{navServices.getUserName()}}</span>
                                <ul class="dropdown-menu dropdown-menu-right">
                                    <li *ngIf="navServices.getUserEmail() && navServices.getUserRole()">
                                        <a (click)="sponsorship()" routerLinkActive="active">
                                            <i class="fas fa-donate mr-1"></i> Sponsorship
                                        </a>
                                    </li>
                                    <li *ngIf="navServices.getUserEmail() && navServices.getUserRole()">
                                        <a (click)="deleteAccount()" routerLinkActive="active">
                                            <i class="fas fa-trash mr-1"></i> Delete Account
                                        </a>
                                    </li>
                                    <li *ngIf="navServices.getUserEmail()">
                                        <a href="javascript:void(0)" (click)="loginService.resetPassword()">
                                            <i class="fas fa-unlock-alt mr-1"></i> Reset Password
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="loginService.SignOut()">
                                            <i class="fas fa-sign-out-alt mr-1"></i> logout
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                </nav>
            </div>
        </div>
    </div>
</header>