<div class="container p-b-150">
    <div *ngIf="userData?.roles != 'clubAdmin'" class="card mt-3 mb-3">
        <div class="card-body shadow">
            <div class="row">
                <div class="col-md-4">
                    <mat-icon class="fa-2x">account_balance_wallet</mat-icon>
                    <span class="fa-3x text-success">
                        £{{userData?.balance}}
                    </span>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="fill" class="mr-5">
                        <mat-label>Amount</mat-label>
                        <input matInput [(ngModel)]="amount" placeholder="Minimum £10 require for deposit" type="number"
                            class="example-right-align">
                        <span matPrefix class="mr-2 fa-2x">£</span>
                        <span matSuffix>.00</span>
                    </mat-form-field>
                    <button class="btn btn-success  btn-sm" (click)="deposit(amount)"><i class="fa fa-plus"></i> Add
                        Amount</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="userData?.roles == 'clubAdmin'" class="row mt-3">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <div class="theme-card card card-body">
                <div *ngIf="isLoggedIn" class="mb-3">
                    <a (click)="goBack()"><i class="fa mr-2 fa-arrow-left"></i>Back</a>
                </div>
                <div *ngIf="userName != '' && isLoggedIn" class="alert alert-success text-center">
                    <img [src]="userImage" class="mb-2" onerror="this.src='../../assets/images/noimage.jpg';"
                        style="border-radius: 50%;width:80px;height:80px;">
                    <h3 class="mb-0 text-center"> {{userName}}</h3>
                </div>
                <div *ngIf="isLoggedIn" class="row mt-2 mb-4">
                    <div class="col-md-12">
                        <h4>
                            <span class="border border-dark float-left p-2 "><i class="fa fa-credit-card mr-2"></i>
                                <span class="text-success">£{{rfidUserData?.balance}}</span> </span>
                        </h4>
                    </div>
                </div>
                <form *ngIf="!isLoggedIn" class="theme-form">
                    <div class="form-group">
                        <h5 for="rfid" class="text-center">Enter RFID for deposit cash</h5>
                        <input type="text" autocomplete="off" #RFID class="form-control text-uppercase"
                            [(ngModel)]="rfid" name="rfid" placeholder="RFID" required>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                            (click)="submitDetails(rfid)" [disabled]="isDisabled">Go</button>
                    </div>
                </form>
                <div *ngIf="isLoggedIn">
                    <form class="theme-form">
                        <div class="mb-3">
                            <form class="theme-form text-center">
                                <div class="form-group">
                                    <label for="email">Top up Amount in CASH:</label>
                                </div>
                                <div class="mb-3">
                                    <a (click)="topUpAmount(10)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £10</span></a>
                                    <a (click)="topUpAmount(15)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £15</span></a>
                                    <a (click)="topUpAmount(20)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £20</span></a>
                                </div>
                            </form>
                        </div>
                        <hr class="hr-text" data-content="OR">
                    </form>
                    <div class="text-center">
                        <mat-form-field appearance="fill" class="mr-5 w-100">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="amount" placeholder="Amount" type="number"
                                class="example-right-align">
                            <span matPrefix class="mr-2 fa-2x">£</span>
                            <span matSuffix>.00</span>
                        </mat-form-field>
                        <button class="btn btn-success  btn-sm" (click)="payInCash()"><i class="fa fa-plus"></i>
                            Add
                            Cash Amount</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
        </div>
    </div>
    <div class="alert alert-info font-weight-bold mb-1 mt-2 p-1 text-center">Showing last 100 Transactions
    </div>
    <div class="card display">
        <div class="card-body table-responsive">
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped">
                        <thead>
                            <th>Name</th>
                            <!-- <th>Email</th> -->
                            <th>RFID</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Mode</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Opening Balance</th>
                            <th>Closing Balance</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of transactionsData">
                                <td>{{item.name}}</td>
                                <!-- <td>{{item.email}}</td> -->
                                <td>{{item.RFID }}</td>
                                <td>{{item.transactionDate | date:'d/M/yyyy h:m a'}}</td>
                                <td><span *ngIf="item.status == 'success'" class="badge badge-pill badge-success"
                                        style="font-size: 12px;">Success</span>
                                    <span *ngIf="item.status == 'failed'" class="badge badge-pill badge-danger"
                                        style="font-size: 12px;">Failed</span>
                                </td>
                                <td>
                                    <mat-icon *ngIf="item.paymentMode == 'card'" style="vertical-align: bottom;">
                                        credit_card</mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'guest'" style="vertical-align: bottom;">
                                        person</mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'wristband'" style="vertical-align: bottom;">
                                        watch</mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'wallet'" style="vertical-align: bottom;">
                                        account_balance_wallet</mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'cash'" style="vertical-align: bottom;">money
                                    </mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'payLater'" style="vertical-align: bottom;">
                                        watch_later
                                    </mat-icon>
                                    <mat-icon *ngIf="item.paymentMode == 'sponsorship'" style="vertical-align: bottom;">
                                        volunteer_activism
                                    </mat-icon> {{item.paymentMode}}
                                </td>
                                <td>
                                    <span *ngIf="item.paymentType == 'credit'"
                                        class="badge badge-pill badge-success">Credit</span>
                                    <span *ngIf="item.paymentType == 'debit'"
                                        class="badge badge-pill badge-danger">Debit</span>
                                </td>
                                <td class="text-center"><span [class.text-danger]="item.amount < 0"
                                        *ngIf="item.amount">£{{item.amount}}</span><span *ngIf="!item.amount">£0</span>
                                </td>
                                <td class="text-center">£{{item.openingBalance}}</td>
                                <td class="text-center"><span [class.text-danger]="item.closingBalance < 0"
                                        *ngIf="item.closingBalance">£{{item.closingBalance}}</span><span
                                        *ngIf="!item.closingBalance">£0</span></td>
                                <td>
                                    <a [routerLink]="" (click)="openTransactionDetails(transactionDetails,item)"
                                        [attr.data-target]="'#transactionDetails-view'" data-toggle="modal"
                                        title="Details" class="btn  btn-outline-info  btn-sm">Details</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let item of transactionsData" class="card mb-2 display-m">
        <div class="card-body p-2">
            <h4 class="mb-2">{{item.name}}
                <a [routerLink]="" (click)="openTransactionDetails(transactionDetails,item)"
                    [attr.data-target]="'#transactionDetails-view'" data-toggle="modal" title="Details">
                    <i class="fa fa-link"></i></a>
                <span class="float-right">{{item.RFID}}</span>
            </h4>
            <p class="mb-2">via
                <span>
                    <mat-icon *ngIf="item.paymentMode == 'card'" style="vertical-align: bottom;">
                        credit_card</mat-icon>
                    <mat-icon *ngIf="item.paymentMode == 'guest'" style="vertical-align: bottom;">
                        person</mat-icon>
                    <mat-icon *ngIf="item.paymentMode == 'wristband'" style="vertical-align: bottom;">
                        watch</mat-icon>
                    <mat-icon *ngIf="item.paymentMode == 'wallet'" style="vertical-align: bottom;">
                        account_balance_wallet</mat-icon>
                    <mat-icon *ngIf="item.paymentMode == 'cash'" style="vertical-align: bottom;">money
                    </mat-icon>
                    <mat-icon *ngIf="item.paymentMode == 'payLater'" style="vertical-align: bottom;">
                        watch_later
                    </mat-icon> {{item.paymentMode}}
                </span>
                <span>
                    <span *ngIf="item.paymentType == 'credit'" class="badge badge-pill badge-success">Credit</span>
                    <span *ngIf="item.paymentType == 'debit'" class="badge badge-pill badge-danger">Debit</span>
                </span>
                <span class="float-right" [class.text-danger]="item.amount < 0"
                    *ngIf="item.amount">£{{item.amount}}</span><span *ngIf="!item.amount">£0</span>
            </p>
            <p class="text-muted">{{item.transactionDate | date:'d/M/yyyy h:m a'}}
                <span *ngIf="item.status == 'success'" class="badge badge-pill badge-success float-right"
                    style="font-size: 12px;">Success</span>
                <span *ngIf="item.status == 'failed'" class="badge badge-pill badge-danger float-right"
                    style="font-size: 12px;">Failed</span>
            </p>
        </div>
    </div>
</div>

<!-- Quick view Popup -->
<ng-template #transactionDetails let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Transaction Details</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div *ngIf="selectedTransaction.details" class="alert alert-info w-100 text-center">
                <span>{{selectedTransaction?.details}}</span>
            </div>
            <div *ngIf="!selectedTransaction.details" class="alert alert-danger w-100 text-center">
                <span>No details found</span>
            </div>
            <div *ngIf="orderData" class="w-100 table-responsive">
                <table class="table table-sm table-striped ">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Brand</th>
                            <th>Ordered Date</th>
                            <th>Price</th>
                            <th class="text-center">Quantity</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> <img alt="" class="img-fluid" [src]="orderData?.product?.images[0]?.src"
                                    style="height:50px!important;"
                                    onerror="this.src='../../assets/images/no-image.png';">
                            </td>
                            <td>{{orderData?.product?.title}}</td>
                            <td>{{orderData?.product?.brand}}</td>
                            <td>{{orderData?.orderDate | date:'d/M/yyyy h:m a'}}</td>
                            <td>{{orderData?.product?.price | currency:'GBP' }}</td>
                            <td class="text-center">{{orderData?.totalQuantity }}</td>
                            <td>{{orderData?.totalAmount | currency:'GBP' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="sessionData" class="w-100 table-responsive">
                <table class="table table-sm table-striped">
                    <thead>
                        <tr>
                            <th>Session Name</th>
                            <th>Location</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{sessionData?.name}}</td>
                            <td>{{sessionData?.location}}</td>
                            <td>{{sessionData?.startDate | date:'d/M/yyyy h:m a'}}</td>
                            <td>{{sessionData?.endDate | date:'d/M/yyyy h:m a'}}</td>
                            <td>{{sessionData?.amount | currency:'GBP'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>