import { Component, Input, OnInit } from '@angular/core';
import { Products } from '../product.model';
import { ProductsService } from '../products.service';
import { Router } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {
  @Input() products: Products;
  closeResult: string;
  public selectedSize: any = '';
  public counter: number = 1;
  userData: any;

  constructor(
    public productsService: ProductsService, public toastService: ToastrService,
    public router: Router, private firestore: AngularFirestore) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit() { }

  redirectUrl(url) {
    this.router.navigate([localStorage.getItem("clubName") + url]);
  }

  public changeVariantSize(variant) {
    this.selectedSize = variant;
  }

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  buyNow(product, counter) {
    if (this.userData) {
      if (confirm("Are you sure you want to purchase this item?")) {
        this.firestore.collection('users', ref => ref.where('uid', '==', this.userData.uid).limit(1)).get()
          .subscribe(snapshot => {
            snapshot.forEach(doc => {
              let user = doc.data();
              let totalprice = product.price * counter;
              if (user.balance > 0 && user.balance >= totalprice) {
                this.addNewOrder(user, product, counter, totalprice);
                this.updateUserBalance(user, totalprice);
                this.addTransaction(user, totalprice);
                this.updateProductStock(product, counter);
                this.toastService.success("Your order has been placed successfully. Please go to Order History page to see your placed order.", 'Success');
              } else {
                this.toastService.error("Not enough credit in your wallet. Please recharge it.", 'Error');
              }
            });
          });
      }
    } else
      this.router.navigate(["/login"]);
  }

  addNewOrder(user, product, totalQty, totalAmount) {
    this.firestore.collection('orders').add({
      uid: user.uid,
      clubId: user.clubId,
      name: user.firstName + ' ' + user.lastName,
      RFID: user.RFID,
      email: user.email,
      orderDate: new Date(),
      totalQuantity: totalQty,
      totalAmount: totalAmount,
      product: product
    })
  }

  updateUserBalance(user, totalAmount) {
    this.firestore.collection("users").doc(user.uid).update({
      balance: user.balance - totalAmount
    })
  }

  addTransaction(user, totalAmount) {
    this.firestore.collection("transactions").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'wallet',
      paymentType: 'debit',
      status: 'success',
      amount: totalAmount,
      openingBalance: user.balance,
      closingBalance: user.balance - totalAmount,
      clubId: user.clubId
    })
  }

  updateProductStock(product, totalQty) {
    this.firestore.collection("products").doc(product.id).update({
      stock: product.stock - totalQty
    })
    
  }
}
