import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginService } from "../login/login.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
declare var require: any;
@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
  providers: [LoginService]
})
export class EntryComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  rfid: string;
  isLoggedIn = false;
  userName = '';
  userImage: any;
  amount = 0;
  pricingData: any;
  userData: any;
  paymentMsg = '';
  isDisabled = false;
  clubData: any;
  @ViewChild('RFID', { static: false }) RFID: ElementRef;
  adminData: any;

  constructor(public service: LoginService, public afs: AngularFirestore, public toastService: ToastrService,) {
    this.adminData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    //this.RFID.nativeElement.focus();
    var cron = require('node-schedule');
    let test = cron.scheduleJob({ hour: 23, minute: 55 }, async () => this.logoutUsers());
    if (this.adminData) {
      this.afs.collection("clubs").doc(this.adminData.clubId).get()
        .subscribe(snapshot => {
          this.clubData = snapshot.data();
        });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.RFID.nativeElement.focus());
  }

  async logoutUsers() {
    this.afs.collection("users", ref => ref.where('isActive', '==', true)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.afs.collection("userLogs", ref => ref.where('uid', '==', doc.id).limit(1).orderBy('entryTime', 'desc')).get()
            .subscribe(ul => {
              ul.forEach(doc1 => {
                this.afs.collection("userLogs").doc(doc1.id).update({
                  exitTime: new Date(),
                  type: 'exit'
                }).then(result => {
                  this.afs.collection("users").doc(doc.id).update({
                    isActive: false
                  });
                })
              });
            });
        });
      });
  }

  validateRFID(rfid) {
    rfid = rfid.toString().toUpperCase();
    this.isDisabled = true;
    this.isLoggedIn = false;
    this.pricingData = {};
    this.userData = {};
    if (!rfid) {
      this.toastService.error('Enter RFID', 'Error');
      this.soundError();
      this.isDisabled = false;
    } else {
      this.afs.collection("users", ref => ref.where('club_rfid', '==', this.adminData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.soundError();
            this.toastService.error("User not found. Please contact site administrator.", 'Error');
            this.rfid = '';
            this.userName = '';
            this.userImage = '';
            this.isDisabled = false;
          }
          else {
            snapshot.forEach(doc => {
              let userData = doc.data();
              if (userData.roles != "clubUser") {
                let log_done = this.adminData.clubId + '_' + rfid
                this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                  .subscribe(snapshot1 => {
                    if (snapshot1.empty) {
                      this.addCoachLogs(userData);
                    } else {
                      snapshot1.forEach(doc => {
                        let data2 = doc.data();
                        data2.id = doc.id;
                        let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                        let tDay = new Date();
                        if (entry != null) {
                          if (entry.getDay() == tDay.getDay() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                            if (data2.exitTime == null) {
                              this.soundError();
                              this.toastService.error("User already logged in.", 'Error');
                              this.userName = data2.name;
                              this.userImage = userData.imageUrl;
                              sessionStorage.setItem('entry', JSON.stringify(data2));
                              this.rfid = '';
                              this.isDisabled = false;
                            } else {
                              let msg = '';
                              if (userData.imageUrl != null)
                                msg = '<img src="' + userData.imageUrl + '" width="80px" height="60px" >';
                              else
                                msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px" >';
                              this.toastService.success(msg, 'Welcome ' + userData.firstName + ' ' + userData.lastName, {
                                enableHtml: true,
                                closeButton: true,
                                timeOut: 5000
                              });
                              this.soundSuccess();
                              this.rfid = '';
                              this.isDisabled = false;
                              this.afs.collection("userLogs").doc(data2.id).update({
                                lastUpdatedIn: new Date(),
                                type: 'entry'
                              });
                            }
                          } else {
                            this.addCoachLogs(userData);
                          }
                        } else {
                          this.addCoachLogs(userData);
                        }
                      });
                    }
                  });
              } else {
                if (userData.RFID && userData.RFID != "" && userData.RFID == rfid) {
                  this.pricingData = this.clubData.planDetails.planDetails.filter(a => a.plan == userData.group)[0];
                  let log_done = this.adminData.clubId + '_' + rfid
                  this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                    .subscribe(snapshot1 => {
                      if (snapshot1.empty) {
                        this.addLogs(userData);
                      } else {
                        snapshot1.forEach(doc => {
                          let data2 = doc.data();
                          data2.id = doc.id;
                          let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                          let tDay = new Date();
                          if (entry != null) {
                            if (entry.getDay() == tDay.getDay() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                              if (data2.exitTime == null) {
                                this.soundError();
                                this.toastService.error("User already logged in.", 'Error');
                                this.userName = data2.name;
                                this.userImage = userData.imageUrl;
                                sessionStorage.setItem('entry', JSON.stringify(data2));
                                this.rfid = '';
                                this.isDisabled = false;
                              } else {
                                let msg = '';
                                if (userData.imageUrl != null)
                                  msg = '<img src="' + userData.imageUrl + '" width="80px" height="60px" >';
                                else
                                  msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px" >';
                                this.toastService.success(msg, 'Welcome ' + userData.firstName + ' ' + userData.lastName, {
                                  enableHtml: true,
                                  closeButton: true,
                                  timeOut: 5000
                                });
                                this.soundSuccess();
                                this.rfid = '';
                                this.isDisabled = false;
                                this.afs.collection("userLogs").doc(data2.id).update({
                                  lastUpdatedIn: new Date(),
                                  type: 'entry'
                                });
                              }
                            } else {
                              this.addLogs(userData);
                            }
                          } else {
                            this.addLogs(userData);
                          }
                        });
                      }
                    });
                }
              }
            });
          }
        });
    }
  }

  addCoachLogs(data1) {
    this.afs.collection("userLogs").add({
      uid: data1.uid,
      name: data1.firstName + ' ' + data1.lastName,
      email: data1.email,
      RFID: data1.RFID,
      entryTime: new Date(),
      exitTime: null,
      clubId: data1.clubId,
      log_done: data1.clubId + '_' + data1.RFID,
      type: 'entry',
      role: data1.roles
    });
    let msg = '';
    if (data1.imageUrl != null)
      msg = '<img src="' + data1.imageUrl + '" width="80px" height="60px" >';
    else
      msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px" >';
    this.toastService.success(msg, 'Welcome ' + data1.firstName + ' ' + data1.lastName, {
      enableHtml: true,
      closeButton: true,
      timeOut: 5000
    });
    this.soundSuccess();
    this.rfid = '';
    this.isDisabled = false;
  }

  addLogs(data) {
    this.userData = data;
    if (this.userData.balance < this.pricingData.price) {
      this.isLoggedIn = true;
      this.toastService.success(data.firstName + ' ' + data.lastName, 'Welcome');
      this.paymentMsg = "Not enough credit for entry. Please use below options.";
      this.soundError();
    }
    else {
      this.afs.collection("transactions").add({
        uid: this.userData.uid,
        name: this.userData.firstName + ' ' + this.userData.lastName,
        email: this.userData.email,
        RFID: this.userData.RFID,
        transactionDate: new Date(),
        stripeToken: '',
        paymentMode: 'wallet',
        paymentType: 'debit',
        status: 'success',
        amount: this.pricingData.price,
        openingBalance: this.userData.balance,
        closingBalance: this.userData.balance - this.pricingData.price,
        clubId: this.userData.clubId,
      }).then(result => {
        this.afs.collection("users").doc(this.userData.uid).update({
          balance: this.userData.balance - this.pricingData.price
        }).then(user1 => {
          this.userData.balance = this.userData.balance - this.pricingData.price;
          this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
          this.soundSuccess();
          this.paymentMsg = "Your Payment Was Successfully Received."
          this.isLoggedIn = false;
          this.afs.collection("userLogs").add({
            uid: data.uid,
            name: data.firstName + ' ' + data.lastName,
            email: data.email,
            RFID: data.RFID,
            entryTime: new Date(),
            exitTime: null,
            clubId: data.clubId,
            log_done: data.clubId + '_' + data.RFID,
            type: 'entry',
            role: data.roles
          });
          this.activateUser();
          this.timeout();
        }).catch(err => {
          this.toastService.error(err, 'Error');
          this.soundError();
        });
      }).catch(err => {
        this.toastService.error(err, 'Error');
        this.soundError();
      });
    }
    this.isDisabled = false;
    this.rfid = '';
    this.userName = data.firstName + ' ' + data.lastName;
    this.userImage = data.imageUrl;
  }

  payInCash() {
    this.isLoggedIn = false;
    this.isDisabled = true;
    this.afs.collection("transactions").add({
      uid: this.userData.uid,
      name: this.userData.firstName + ' ' + this.userData.lastName,
      email: this.userData.email,
      RFID: this.userData.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'cash',
      paymentType: 'debit',
      status: 'success',
      amount: this.pricingData.price,
      openingBalance: this.userData.balance,
      closingBalance: this.userData.balance,
      clubId: this.userData.clubId
    });
    this.activateUser();
    this.afs.collection("userLogs").add({
      uid: this.userData.uid,
      name: this.userData.firstName + ' ' + this.userData.lastName,
      email: this.userData.email,
      RFID: this.userData.RFID,
      entryTime: new Date(),
      exitTime: null,
      clubId: this.userData.clubId,
      log_done: this.userData.clubId + '_' + this.userData.RFID,
      type: 'entry'
    });
    this.paymentMsg = "Your Payment Was Successfully Received."
    this.toastService.success('Thank you', 'Welcome!');
    this.soundSuccess();
    this.ngAfterViewInit();
    this.timeout();
  }

  goBack() {
    this.isLoggedIn = false;
    this.isDisabled = false;
    this.userName = '';
    this.userImage = '';
    this.paymentMsg = '';
    this.ngAfterViewInit();
  }

  topUpAmount(amount) {
    if ((this.userData.balance + amount) < this.pricingData.price) {
      this.toastService.error("Not enough credit for entry", 'Error');
      this.soundError();
    } else {
      this.isDisabled = true;
      this.afs.collection("transactions").add({
        uid: this.userData.uid,
        name: this.userData.firstName + ' ' + this.userData.lastName,
        email: this.userData.email,
        RFID: this.userData.RFID,
        transactionDate: new Date(),
        stripeToken: '',
        paymentMode: 'cash',
        paymentType: 'credit',
        status: 'success',
        amount: amount,
        openingBalance: this.userData.balance,
        closingBalance: this.userData.balance + amount,
        clubId: this.userData.clubId
      }).then(result1 => {
        this.afs.collection("users").doc(this.userData.uid).update({
          balance: this.userData.balance + amount
        }).then(user => {
          this.userData.balance = this.userData.balance + amount;
          this.afs.collection("transactions").add({
            uid: this.userData.uid,
            name: this.userData.firstName + ' ' + this.userData.lastName,
            email: this.userData.email,
            RFID: this.userData.RFID,
            transactionDate: new Date(),
            stripeToken: '',
            paymentMode: 'wallet',
            paymentType: 'debit',
            status: 'success',
            amount: this.pricingData.price,
            openingBalance: this.userData.balance,
            closingBalance: this.userData.balance - this.pricingData.price,
            clubId: this.userData.clubId
          }).then(result => {
            this.afs.collection("users").doc(this.userData.uid).update({
              balance: this.userData.balance - this.pricingData.price
            }).then(user1 => {
              this.userData.balance = this.userData.balance - this.pricingData.price;
              this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
              this.soundSuccess();
              this.userName = '';
              this.userImage = '';
              this.isLoggedIn = false;
              this.paymentMsg = "Your Payment Was Successfully Received."
              this.afs.collection("userLogs").add({
                uid: this.userData.uid,
                name: this.userData.firstName + ' ' + this.userData.lastName,
                email: this.userData.email,
                RFID: this.userData.RFID,
                entryTime: new Date(),
                exitTime: null,
                clubId: this.userData.clubId,
                log_done: this.userData.clubId + '_' + this.userData.RFID,
                type: 'entry',
                role: this.userData.roles
              });
              this.activateUser();
              this.ngAfterViewInit();
              this.timeout();
              this.isDisabled = false;
            }).catch(err => {
              this.toastService.error(err, 'Error');
              this.soundError();
            });
          }).catch(err => {
            this.toastService.error(err, 'Error');
            this.soundError();
          });
        }).catch(err => {
          this.toastService.error(err, 'Error');
          this.soundError();
        });
      }).catch(err => {
        this.toastService.error(err, 'Error');
        this.soundError();
      });
    }
  }

  activateUser() {
    this.afs.collection("users").doc(this.userData.uid).update({
      isActive: true
    });
  }

  timeout() {
    this.isDisabled = false;
    setTimeout(() => {
      this.userName = '';
      this.userImage = '';
      this.isLoggedIn = false;
      this.paymentMsg = ""
    }, 3000);
  }

  soundSuccess() {
    let audio = new Audio();
    audio.src = "../../assets/audio/success.wav";
    audio.load();
    audio.play();
  }

  soundError() {
    let audio = new Audio();
    audio.src = "../../assets/audio/error.wav";
    audio.load();
    audio.play();
  }

}
