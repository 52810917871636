import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
   path?: string;
   title?: string;
   type?: string;
   icon?: string;
   badgeType?: string;
   badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
   bookmark?: boolean;
   role?: string;
   children?: Menu[];
}

@Injectable({
   providedIn: 'root'
})
export class NavService {

   constructor() { }

   generalMenu: Menu[] = [
      { path: '/book-session', title: 'BOOK A SESSION', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      //{ path: '/book-event', title: 'Events', type: 'link', icon: 'fas fa-ticket-alt', role: 'user' },
      { path: '/login', title: 'LOGIN', type: 'link', icon: 'fas fa-sign-in-alt', role: 'all' },
      { path: '/register', title: 'SIGNUP', type: 'link', icon: 'fas fa-user-plus', role: 'all' },
      { path: '/coaches', title: 'COACHES', type: 'link', icon: 'fas fa-users', role: 'all' },
      { path: '/sponsors', title: 'SPONSORS', type: 'link', icon: 'fas fa-donate', role: 'all' },
   ]

   userMenu: Menu[] = [
      // { path: '', title: 'HOME', type: 'link', icon: 'fas fa-home', role: 'user' },
      { path: '/settings', title: 'MEMBERSHIP', type: 'link', icon: 'fas fa-cog', role: 'user' },
      { path: '/wallet', title: 'WALLET', type: 'link', icon: 'fas fa-wallet', role: 'user' },
      { path: '/book-session', title: 'BOOK A SESSION', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      //{ path: '/book-event', title: 'Events', type: 'link', icon: 'fas fa-ticket-alt', role: 'user' },
      { path: '/quick-purchase', title: 'QUICK PURCHASE', type: 'link', icon: 'fas fa-shopping-bag', role: 'user' },
      //{ path: '/contact-us', title: 'CONTACT US', type: 'link', icon: 'fas fa-map-marker-alt', role: 'user' },
   ];

   adminMenu: Menu[] = [
      // { path: '', title: 'HOME', type: 'link', icon: 'fas fa-home', role: 'user' },
      { path: '/book-session', title: 'BOOK A SESSION', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      { path: '/wallet', title: 'WALLET', type: 'link', icon: 'fas fa-wallet', role: 'user' },
      { path: '/in-out', title: 'IN & OUT', type: 'link', icon: 'fas fa-exchange-alt', role: 'user' },
      { path: '/booking', title: 'Bookings', type: 'link', icon: 'fas fa-ticket-alt', role: 'user' },
      { path: '/scan', title: 'CHECK DETAILS', type: 'link', icon: 'fas fa-qrcode', role: 'user' },
      { path: '/quick-purchase', title: 'QUICK PURCHASE', type: 'link', icon: 'fas fa-shopping-bag', role: 'user' },
      //{ path: '/contact-us', title: 'CONTACT US', type: 'link', icon: 'fas fa-map-marker-alt', role: 'user' },
   ];

   otherUserMenu: Menu[] = [
      { path: '', title: 'HOME', type: 'link', icon: 'fas fa-home', role: 'user' },
      { path: '/settings', title: 'MEMBERSHIP', type: 'link', icon: 'fas fa-cog', role: 'user' },
      { path: '/book-session', title: 'BOOK A SESSION', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      //{ path: '/book-event', title: 'Events', type: 'link', icon: 'fas fa-ticket-alt', role: 'user' },
      //{ path: '/contact-us', title: 'CONTACT US', type: 'link', icon: 'fas fa-map-marker-alt', role: 'user' },
   ];

   // Array
   items = new BehaviorSubject<Menu[]>(this.userMenu);

   //Mobile menu start
   mobileGeneralMenu: Menu[] = [
      { path: '/book-session', title: 'Bookings', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      { path: '/login', title: 'Login', type: 'link', icon: 'fas fa-sign-in-alt', role: 'all' },
      { path: '/register', title: 'Sign Up', type: 'link', icon: 'fas fa-user-plus', role: 'all' },
      { path: '/contact-us', title: 'Contact', type: 'link', icon: 'fas fa-map-marker-alt', role: 'user' },
   ]

   mobileUserMenu: Menu[] = [
      { path: '/settings', title: 'Membership', type: 'link', icon: 'fas fa-cog', role: 'user' },
      { path: '/wallet', title: 'Wallet', type: 'link', icon: 'fas fa-wallet', role: 'user' },
      { path: '/book-session', title: 'Bookings', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      { path: '/quick-purchase', title: 'Purchase', type: 'link', icon: 'fas fa-shopping-bag', role: 'user' },
   ];

   mobileAdminMenu: Menu[] = [
      { path: '/wallet', title: 'Wallet', type: 'link', icon: 'fas fa-wallet', role: 'user' },
      { path: '/in-out', title: 'In/Out', type: 'link', icon: 'fas fa-exchange-alt', role: 'user' },
      { path: '/scan', title: 'Details', type: 'link', icon: 'fas fa-qrcode', role: 'user' },
      { path: '/quick-purchase', title: 'Purchase', type: 'link', icon: 'fas fa-shopping-bag', role: 'user' },
   ];

   mobileOtherUsersMenu: Menu[] = [
      { path: '', title: 'HOME', type: 'link', icon: 'fas fa-home', role: 'user' },
      { path: '/settings', title: 'Membership', type: 'link', icon: 'fas fa-cog', role: 'user' },
      { path: '/book-session', title: 'Bookings', type: 'link', icon: 'fas fa-calendar', role: 'user' },
      { path: '/contact-us', title: 'Contact', type: 'link', icon: 'fas fa-map-marker-alt', role: 'user' },
   ];
   //Mobile menu end

   getAll(): Menu[] {
      let user = JSON.parse(sessionStorage.getItem('user'));

      if (user) {
         if (user.roles == "clubAdmin")
            return this.adminMenu;
         else if (user.roles == "superAdmin")
            return this.generalMenu;
         else if (user.roles == "clubUser" || user.roles == "one2one" || user.roles == "coach") {
            let isEmail = user ? (user.email != "" ? true : false) : false;
            if (isEmail)
               return this.userMenu;
            else
               return this.userMenu.filter(a => a.path != '/wallet' && a.path != '/book-session');
         } else if (user.roles != "clubAdmin" && user.roles != "superAdmin" && user.roles != "clubUser")
            return this.otherUserMenu;
      } else
         return this.generalMenu;
   }

   getAllMobileMenu(): Menu[] {
      let user = JSON.parse(sessionStorage.getItem('user'));

      if (user) {
         if (user.roles == "clubAdmin")
            return this.mobileAdminMenu;
         else if (user.roles == "superAdmin")
            return this.mobileGeneralMenu;
         else if (user.roles == "clubUser" || user.roles == "one2one" || user.roles == "coach") {
            let isEmail = user ? (user.email != "" ? true : false) : false;
            if (isEmail)
               return this.mobileUserMenu;
            else
               return this.mobileUserMenu.filter(a => a.path != '/wallet' && a.path != '/book-session');
         } else if (user.roles != "clubAdmin" && user.roles != "superAdmin" && user.roles != "clubUser")
            return this.mobileOtherUsersMenu;
      } else
         return this.mobileGeneralMenu;
   }

   getUserName() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? (user.name ? user.name : user.firstName + ' ' + user.lastName) : '';
   }

   getUserEmail() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? (user.email != "" ? true : false) : false;
   }

   getUserRole() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? ((user.roles == "clubAdmin" || user.roles == "superAdmin") ? false : true) : false;
   }

   getUserImage() {
      let user = JSON.parse(sessionStorage.getItem('user'));
      return user ? user.imageUrl : '';
   }

   getLogo() {
      let user = localStorage.getItem("logoUrl");
      return user ? user : 'assets/images/logo/5.png';
   }

}
