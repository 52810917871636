<!--footer start-->
<footer class="copyright copyright-bg display footer2 gym inner-pages-footer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal  text-center mb-3">
                    <img class="rounded-circle" [src]="navServices.getLogo()" height="100" width="100">
                </div>
            </div>
            <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <!-- <li><a class="copyright-text" (click)="redirectUrl('')">ABOUT US</a></li> -->
                        <li *ngIf="!navServices.getUserName()"><a (click)="redirectUrl('/login')"
                                class="copyright-text">LOGIN</a></li>
                        <li *ngIf="!navServices.getUserName()"><a (click)="redirectUrl('/register')"
                                class="copyright-text">SIGNUP</a></li>
                        <li><a (click)="redirectUrl('/contact-us')" class="copyright-text">CONTACT US</a></li>
                        <li><a (click)="redirectUrl('/coaches')" class="copyright-text">COACHES</a>
                        </li>
                        <li><a (click)="redirectUrl('/sponsors')" class="copyright-text">SPONSORS</a>
                        </li>
                        <li><a href="https://admin.pamit.co.uk/#/session/register-new-club" target="_blank"
                                class="copyright-text">REGISTER CLUB</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a class="copyright-text" [href]="facebook" target="_blank"><i aria-hidden="true"
                                        class="fa fa-facebook"></i></a>
                            </li>
                            <li><a class="copyright-text" [href]="twitter" target="_blank"><i aria-hidden="true"
                                        class="fa fa-twitter"></i></a>
                            </li>
                            <li><a class="copyright-text" [href]="website" target="_blank"><i aria-hidden="true"
                                        class="fa fa-google"></i></a>
                            </li>
                            <li><a class="copyright-text " [href]="instagram" target="_blank"><i aria-hidden="true"
                                        class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<footer class="footer sticky bg-white w-100 display-m p-0">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of navServices.getAllMobileMenu()" class="col-3 p-1 text-center">
                <p class="mb-0 mt-2" class="mb-0 mt-2"><a (click)="redirectUrl(item.path)" title="{{item.title}}"
                        class="text-black"><i class="fa-2x {{item.icon}}">
                        </i> <br> {{item.title}}</a></p>
            </div>
        </div>
    </div>
</footer>
<!--copyright start-->
<div class="agency  copyright inner-page display">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a class="copyright-text" routerLink="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a class="copyright-text" routerLink="/terms-conditions">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right">Copyright ©2022
                        <!-- <i
                                aria-hidden="true"
                                class="fa fa-heart"></i>
                            pixelstrap -->
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright start-->
<app-tap-to-top></app-tap-to-top>