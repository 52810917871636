import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  data: any;
  constructor(public router: Router, public firestore: AngularFirestore) {
    this.firestore.collection("aboutUs").doc("WzPQBWfBuqW3BOwbt9ag").get().subscribe(snapshot => {
      this.data = snapshot.data();
    });
  }

  ngOnInit(): void {
  }
}
