<section style="padding:25px 10px!important;">
    <div class="row">
        <div class="col-lg-6 text-center">
            <owl-carousel-o [options]="productSliderOptions" #owlCar class="product-slick">
                <ng-container *ngFor="let data of product?.images; index as i">
                    <ng-template carouselSlide [id]="i">
                        <img alt="" class="img-fluid" [src]="data.src" style="height:500px;"
                            onerror="this.src='../../assets/images/no-image.png';">
                    </ng-template>
                </ng-container>
            </owl-carousel-o>

            <!-- <div class="col-12 p-0">
                <div class="slider-nav">
                    <owl-carousel-o [options]="productSliderOptions1" class="product-slick">
                        <ng-container *ngFor="let data of product?.images; index as i">
                            <ng-template carouselSlide [id]="i">
                                <img alt="" style="height:150px" class="img-fluid" [src]="data.src"
                                    (click)="owlCar.to(i.toString())">
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div> -->
        </div>
        <div class="col-lg-6 rtl-text">
            <div class="product-right">
                <h2>{{product?.title}}</h2>
                <h3 class="form-inline mb-0">{{product?.price | currency:'GBP'}} <span class="ml-2">
                        <p-rating [ngModel]="product.rating" readonly="true" [cancel]="false"></p-rating>
                    </span>
                </h3>

                <ul class="color-variant" *ngIf="product?.variants && !productsService?.catalogMode">
                    <li [class]="variant.color" [ngStyle]="{'background-color': variant.color}" [id]="i"
                        (click)="owlCar.to(i.toString())" *ngFor="let variant of product.variants let i = index"></li>
                </ul>
                <div class="product-description border-product">
                    <h5 class="avalibility" *ngIf="counter <= (product?.stock | number)"><span class="text-success">In Stock</span></h5>
                    <h5 class="avalibility" *ngIf="counter > (product?.stock | number)"><span class="text-danger">Out of Stock</span></h5>
                    <h6 class="product-title">quantity</h6>
                    <div class="qty-box">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <button type="button" (click)="decrement()" class="btn quantity-left-minus"
                                    data-type="minus" data-field="">
                                    <i class="fa fa-chevron-left"></i>
                                </button>
                            </span>
                            <input type="text" name="quantity" disabled class="form-control input-number"
                                value="{{counter}}">
                            <span class="input-group-prepend">
                                <button type="button" (click)="increment()" class="btn quantity-right-plus"
                                    data-type="plus" data-field="">
                                    <i class="fa fa-chevron-right"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="product-buttons" *ngIf="!productsService?.catalogMode">
                    <a [routerLink]="" (click)="buyNow(product, counter)"
                        class="btn btn-default primary-btn radius-0 ml-0"
                        [class.disabled]="counter > product?.stock">buy now</a>
                </div>
                <div class="border-product">
                    <h6 class="product-title">Brand : <span
                            class="text-info font-weight-normal">{{product?.brand}}</span> </h6>
                    <h6 class="product-title">Category : <span
                            class="text-info font-weight-normal">{{product?.category}}</span> </h6>
                    <h6 class="product-title">Tags : <span *ngFor="let item of product.tags"
                            class="btn btn-outline-info btn-sm mr-2  rounded-pill text-lowercase">{{item}}</span> </h6>
                    <h6 class="product-title">Seller : <span
                            class="text-info font-weight-normal">{{product?.seller}}</span> </h6>

                </div>
            </div>
        </div>
    </div>
    <div class="row tab-product m-0 p-b-0 pt-0">
        <div class="col-sm-12 col-lg-12">
            <ngb-tabset>
                <ngb-tab title="Description">
                    <ng-template ngbTabContent>
                        <div class="pt-2" [innerHTML]="product.description | sanitizeHtml">
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</section>