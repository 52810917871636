<h2 mat-dialog-title>User Details</h2>
<mat-dialog-content class="mat-typography">
    <div class="row pr-2 pl-2">
        <div class="col-md-12 mb-1">
            <div class="card">
                <div>
                    <table class="table table-striped" #contentToConvert id="contentToConvert">
                        <tbody>
                            <tr *ngIf="data.personalDetails">
                                <td colspan="2" style="font-weight: bold;">
                                    Personal Details
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Name</td>
                                <td>{{data?.personalDetails?.firstName}}
                                    {{data?.personalDetails?.lastName}}</td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >DOB</td>
                                <td>{{data?.personalDetails?.dob | date:'dd/MM/yyyy'}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Email</td>
                                <td>{{data?.personalDetails?.email}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Sex</td>
                                <td>{{data?.personalDetails?.sex}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Mobile No.</td>
                                <td>{{data?.personalDetails?.mobileNo}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Address</td>
                                <td>{{data?.personalDetails?.address}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >Home Telephone No.</td>
                                <td>{{data?.personalDetails?.homeTelephoneNo}}
                                </td>
                            </tr>
                            <tr *ngIf="data.personalDetails">
                                <td >PostCode</td>
                                <td>{{data?.personalDetails?.postcode}}
                                </td>
                            </tr>
                            <tr *ngIf="data.emergencyDetails">
                                <td colspan="2" style="font-weight: bold;">
                                    Emergency Details
                                </td>
                            </tr>
                            <tr *ngIf="data.emergencyDetails">
                                <td >Name</td>
                                <td>{{data?.emergencyDetails?.emergencyContactName}}
                                </td>
                            </tr>
                            <tr *ngIf="data.emergencyDetails">
                                <td >Relationship</td>
                                <td>{{data?.emergencyDetails?.emergencyRelationship}}
                                </td>
                            </tr>
                            <tr *ngIf="data.emergencyDetails">
                                <td >Address</td>
                                <td>{{data?.emergencyDetails?.emergencyContactAddress}}
                                </td>
                            </tr>
                            <tr *ngIf="data.emergencyDetails">
                                <td >Contact No.</td>
                                <td>{{data?.emergencyDetails?.emergencyContactNo}}
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td colspan="2" style="font-weight: bold;">
                                    Your Ethnicity
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td >Ethnicity</td>
                                <td>{{data?.membership?.ethnicity?.ethnicity}}
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td colspan="2" style="font-weight: bold;">
                                    Photography And Video Recordings
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td >Photography Consent</td>
                                <td>{{data?.membership?.photography?.consent}}
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td >Age</td>
                                <td>{{data?.membership?.photography?.age}}
                                </td>
                            </tr>
                            <tr *ngIf="data.membership">
                                <td >Travelling Consent</td>
                                <td>{{data?.membership?.travelling?.consent}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td colspan="2" style="font-weight: bold;">
                                    Doctor Details
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Name</td>
                                <td>{{data?.medical?.doctorDetails?.doctorName}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Phone No.</td>
                                <td>{{data?.medical?.doctorDetails?.doctorNo}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Address</td>
                                <td>{{data?.medical?.doctorDetails?.doctorAddress}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td colspan="2" style="font-weight: bold;">
                                    Disease Details
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Asthma</td>
                                <td>{{data?.medical?.diseaseDetails?.asthma}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Chest / Lung Problems</td>
                                <td>{{data?.medical?.diseaseDetails?.chestLungProblems}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Diabetes</td>
                                <td>{{data?.medical?.diseaseDetails?.diabetes}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Epilepsy</td>
                                <td>{{data?.medical?.diseaseDetails?.epilepsy}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Fainting Dizziness</td>
                                <td>{{data?.medical?.diseaseDetails?.faintingDizziness}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Heart Problems</td>
                                <td>{{data?.medical?.diseaseDetails?.heartProblems}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >High Blood Pressure</td>
                                <td>{{data?.medical?.diseaseDetails?.highBloodPressure}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Details</td>
                                <td>{{data?.medical?.diseaseDetails?.details}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Medications</td>
                                <td>{{data?.medical?.diseaseDetails?.medications}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td colspan="2" style="font-weight: bold;">
                                    Medical Details
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Allergies</td>
                                <td>{{data?.medical?.medicalDetails?.allergies}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Current Medication</td>
                                <td>{{data?.medical?.medicalDetails?.currentMedication}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Current Treatment</td>
                                <td>{{data?.medical?.medicalDetails?.currentTreatment}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Details</td>
                                <td>{{data?.medical?.medicalDetails?.details}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Medical Condition</td>
                                <td>{{data?.medical?.medicalDetails?.medicalCondition}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Parents Name</td>
                                <td>{{data?.medical?.medicalDetails?.parentsName}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td colspan="2">
                                    Disability
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Hearing</td>
                                <td>{{data?.medical?.disability?.hearing}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Learning</td>
                                <td>{{data?.medical?.disability?.learning}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Physical</td>
                                <td>{{data?.medical?.disability?.physical}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Visual</td>
                                <td>{{data?.medical?.disability?.visual}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Other</td>
                                <td>{{data?.medical?.disability?.other}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Details</td>
                                <td>{{data?.medical?.disability?.details}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td colspan="2" style="font-weight: bold;">
                                    Sporting Details
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Boxing</td>
                                <td>{{data?.medical?.sportingDetails?.boxing}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Football</td>
                                <td>{{data?.medical?.sportingDetails?.football}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Hockey</td>
                                <td>{{data?.medical?.sportingDetails?.hockey}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >MartialArts</td>
                                <td>{{data?.medical?.sportingDetails?.martialArts}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Rugby</td>
                                <td>{{data?.medical?.sportingDetails?.rugby}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Tennis</td>
                                <td>{{data?.medical?.sportingDetails?.tennis}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Other</td>
                                <td>{{data?.medical?.sportingDetails?.other}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Details</td>
                                <td>{{data?.medical?.sportingDetails?.details}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Registration No.</td>
                                <td>{{data?.medical?.sportingDetails?.regNo}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Club Name</td>
                                <td>{{data?.medical?.sportingDetails?.clubName}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Previous Surname</td>
                                <td>{{data?.medical?.sportingDetails?.previousSurname}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Previous Sports</td>
                                <td>{{data?.medical?.sportingDetails?.previousSports}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Boxer Type</td>
                                <td>{{data?.medical?.sportingDetails?.type}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Have you previously been affilated with England
                                    Boxing?</td>
                                <td>{{data?.medical?.sportingDetails?.isEnglandBoxing}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Have you previously been affilated any Other Boxing
                                    Organisation?</td>
                                <td>{{data?.medical?.sportingDetails?.isOtherBoxing}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Organisation Name</td>
                                <td>{{data?.medical?.sportingDetails?.organisation}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >WIN</td>
                                <td>{{data?.medical?.sportingDetails?.win}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >LOSS</td>
                                <td>{{data?.medical?.sportingDetails?.loss}}
                                </td>
                            </tr>
                            <tr *ngIf="data.medical">
                                <td >Full or Semi Contact</td>
                                <td>{{data?.medical?.sportingDetails?.fullSemiContact}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button mat-button-primary (click)="captureScreen()"><i class="fas fa-download"></i>Download</button>
</mat-dialog-actions>