import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class EmailService {
    userData: any;
    ccEmail = '';

    constructor(private _http: HttpClient, private firestore: AngularFirestore,
    ) {
        this.getCCEmail();
    }

    postSendMail(email) {
        const body = JSON.stringify({
            "email": email,
            "ccEmail": this.ccEmail,
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(environment.prodApiLink + 'send/mail', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err)
                    });
        });
    }


    postUserSendMail(email, subject, description) {
        const body = JSON.stringify({
            "email": email,
            "ccEmail": this.ccEmail,
            "subject": subject,
            "description": description
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(environment.prodApiLink + 'send/user/mail', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        observer.next(err)
                    });
        });
    }

    postEnglandUsers(email, password, clubId) {
        const body = JSON.stringify({
            "email": email,
            "password": password,
            "clubId": clubId
        })
        let headers = new HttpHeaders();
        headers = headers.set('content-Type', 'application/json;charset=utf-8');
        headers.set("Access-Control-Allow-Origin", "*");
        return Observable.create((observer) => {
            return this._http.post(environment.prodApiLink + 'all/englandusers', body, { headers: headers })
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err)
                    });
        });
    }

    postUserMail(email, subject, description) {
        let body = {
            "email": email,
            "subject": subject,
            "description": description,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "send/user/mail", body).toPromise();
    }

    postBookingSuccessMail(email, name, title, time, location, bookingDate) {
        let body = {
            "email": email,
            "name": name,
            "title": title,
            "time": time,
            "location": location,
            "bookingDate": bookingDate,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "booking/success", body).toPromise();
    }

    postBookingCancelMail(email, name, title, time, location, bookingDate) {
        let body = {
            "email": email,
            "name": name,
            "title": title,
            "time": time,
            "location": location,
            "bookingDate": bookingDate,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "booking/cancel", body).toPromise();
    }

    postSendDebitMail(email, totalAmount, amount, name) {
        let body = {
            "email": email,
            "totalAmount": totalAmount,
            "amount": amount,
            "name": name,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "wallet/debit", body).toPromise();
    }

    postSendCreditMail(email, totalAmount, amount, name) {
        let body = {
            "email": email,
            "totalAmount": totalAmount,
            "amount": amount,
            "name": name,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "wallet/credit", body).toPromise();
    }

    postOrderSuccessMail(email, name, product, quanity, totalAmount, orderDate) {
        let body = {
            "email": email,
            "name": name,
            "image": product.images.length > 0 ? product.images[0].src : '',
            "productName": product.title,
            "price": '£' + product.price,
            "quanity": quanity,
            "totalAmount": '£' + totalAmount,
            "orderDate": orderDate,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "order/success", body).toPromise();
    }

    postEventSuccessMail(email, name, ticketNo, eventName, location, date, timing, ticketTypes, totalTickets, totalAmount) {
        let body = {
            "email": email,
            "name": name,
            "ticketNo": ticketNo,
            "eventName": eventName,
            "location": location,
            "date": date,
            "timing": timing,
            "ticketTypes": ticketTypes,
            "totalTickets": totalTickets,
            "totalAmount": totalAmount,
            "ccEmail": this.ccEmail
        }
        this._http.post(environment.prodApiLink + "event/booking/success", body).toPromise();
    }

    getCCEmail() {
        this.firestore.collection('users', ref => ref.where('roles', '==', 'clubAdmin')).get()
            .subscribe(snapshot => {
                snapshot.forEach(e => {
                    let data = e.data();
                    if (data.clubId == localStorage.getItem('clubId'))
                        this.ccEmail = data.ccEmail ? data.ccEmail : '';
                })
            })
    }
}
