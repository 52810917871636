export const environment = {
  production: true,
  stripeID: "pk_live_bwSAbZ29xB1DgXfNwKMQRpCS005SUSZ4Sy",
  stripeID_test: "pk_test_HYLhFkNnnDuta8OiGzfgel4M009JFQU8bR",
  prodApiLink: "https://boxing-new-backend.herokuapp.com/api/",
  localApiLink: "http://localhost:3000/api/",
  firebaseConfig: {
    apiKey: "AIzaSyAehxPHDbNScSrsbLoh98gWmiYlDXebgmQ",
    authDomain: "boxing-frontend.firebaseapp.com",
    databaseURL: "https://boxing-frontend.firebaseio.com",
    projectId: "boxing-frontend",
    storageBucket: "boxing-frontend.appspot.com",
    messagingSenderId: "439486935948",
    appId: "1:439486935948:web:e7f82e8c29c0b370d1dca9",
    measurementId: "G-HG2Z0G7CTT"
  }
};
