<section *ngIf="data.length > 0" class="event about bg bg-img bg-about"
    style="background-image: url(&quot;https://angular.pixelstrap.com/unice/assets/images/event/bg1.jpg&quot;);">
    <div class="container">
        <div class="row">
            <div class="col-12 p-0">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h3 class="font-primary borders text-center text-uppercase m-b-0"><span class="fwhite"><img
                                    class="img-b" [src]="boxingUrl"> Events <img class="img-b" [src]="boxingUrl"></span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let item of data;let i=index">
            <div class="col-lg-4 offset-lg-1 col-md-6">
                <div class="text-right">
                    <div class="announcer-img"><img src="https://angular.pixelstrap.com/unice/assets/images/event/1.jpg"
                            alt="" class="img-fluid"><img
                            src="" alt=""
                            class="img-fluid"><img src="{{item.coverImage}}" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-lg-7 col-md-6">
                <div class="abouts center-text">
                    <div class="format">
                        <div class="format-head-text mb-0">
                            <h3 class="about-font-header text-white">{{item.eventName}}</h3>
                            <h5 class="mt-2 mb-2 fwhite"><i class="fa fa-map-marker-alt"></i> {{item.location}}</h5>
                            <h5 class="mt-2 mb-2 fwhite"><i class="fa fa-calendar"></i> {{item?.startDate |
                                date:'EEEE, MMMM d, y'}}
                            </h5>
                            <h5 class="mt-2 mb-2 fwhite"><i class="fa fa-clock-o"></i> From {{item?.startDate |
                                date:'hh:mm a'}} To {{item?.endDate |
                                date:'hh:mm a'}}
                            </h5>
                            <h5 class="mt-2 mb-2 fwhite"><i class="fa fa-ticket-alt"></i> Choose Tickets</h5>
                        </div>
                        <div class="format-sub-text">
                            <div class="row">
                                <div class="col-md-12" *ngFor="let tck of item.tickets">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <label class="font-weight-bold input-group-text"
                                                for="inputGroupSelect01">{{tck.type}}</label>
                                        </div>
                                        <div class="input-group-prepend">
                                            <label class="font-weight-bold input-group-text text-success"
                                                for="inputGroupSelect01">{{tck.amount |
                                                currency:'GBP'}}</label>
                                        </div>
                                        <select class="custom-select" id="inputGroupSelect01"
                                            [(ngModel)]="tck.quantity">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <p class="about-para p-light" [innerHtml]="item.description"></p>
                            <div class="social-link link-horizontal">
                                <ul>
                                    <li *ngIf="item?.facebookLink != ''"><a target="_blank" class="copyright-text"
                                            [href]="item?.facebookLink"><i aria-hidden="true"
                                                class="fa fa-facebook"></i></a></li>
                                    <li *ngIf="item?.twitterLink != ''"><a target="_blank" class="copyright-text"
                                            [href]="item?.twitterLink"><i aria-hidden="true"
                                                class="fa fa-twitter"></i></a></li>
                                    <li *ngIf="item?.websiteLink != ''"><a target="_blank" class="copyright-text"
                                            [href]="item?.websiteLink"><i aria-hidden="true"
                                                class="fa fa-google"></i></a></li>
                                    <li *ngIf="item?.instagramLink != ''"><a target="_blank" class="copyright-text"
                                            [href]="item?.instagramLink"><i aria-hidden="true"
                                                class="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div><a [routerLink]="" (click)="openModel(bookingSummary,item)"
                            [attr.data-target]="'#transactionDetails-view'" data-toggle="modal" title="Details"
                            class="btn btn-default">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quick view Popup -->
<ng-template #bookingSummary let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Payment Summary</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <ul>
                <li class="border-bottom" *ngFor="let item of selectedEvent.tickets">
                    <span *ngIf="item.quantity">{{item?.type}} : {{item?.amount}} X {{item?.quantity}} = £{{item?.amount
                        *
                        item?.quantity}}</span>
                </li>
                <h4 class="mt-2 mb-2">Total Amount : £{{totalAmount}}</h4>
            </ul>
        </div>
        <div>
            <form [formGroup]="bookingForm" #f="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email</mat-label>
                            <mat-icon matPrefix>email</mat-icon>
                            <input matInput placeholder="Email" formControlName="email" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name</mat-label>
                            <mat-icon matPrefix>person</mat-icon>
                            <input matInput placeholder="Name" formControlName="name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Contact</mat-label>
                            <mat-icon matPrefix>phone</mat-icon>
                            <input matInput type="number" placeholder="Contact" formControlName="contact" required>
                        </mat-form-field>
                    </div>
                    <div class="col-12 mb-90 text-center ">
                        <button [disabled]="!f.valid" (click)="bookTicket()" class="btn btn-info">Continue to pay
                            £{{totalAmount}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>