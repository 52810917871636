<!-- Login section -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="logo text-center">
                    <a class="navbar-brand" href="#"><img src="assets/images/logo/5.png" alt="logo" style="height: 100px;"></a>
                </div>
                <h3 class="mt-3 text-center">Thank You for Registering</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="formGroup" *ngIf="rService.userData as user">
                            <p class="text-center">We have sent a confirmation email to <strong>{{user.email}}</strong>.
                            </p>
                            <p class="text-center">Please check your email and click on the link to verfiy your email
                                address.</p>
                        </div>

                        <!-- Calling SendVerificationMail() method using authService Api -->
                        <div class="formGroup m-3 text-center">
                            <button type="button" class="btn primary-btn btn-default radius-0"
                                (click)="rService.SendVerificationMail()">
                                <i class="fas fa-redo-alt"></i>
                                Resend Verification Email
                            </button>
                        </div>
                        <div class="text-center">
                            <p>Go back to?<a class="redirect" routerLink="/login"> Login</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->