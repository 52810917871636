import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  personalDetails: any;
  emergencyDetails: any;
  membership: any;
  medical: any;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  isAccount = false;
  isMembership = false;
  isMedical = false;
  isQuestion = false;
  isSignature = false;
  index: number = 0;

  accountData: any;
  membershipData: any;
  medicalData: any;
  progress = '100';
  progressStatus = "Incomplete";
  type = "danger";
  progressNo = 0;

  constructor(private firestore: AngularFirestore, public afs: AngularFirestore,
    private route: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getUserAccount();
  }

  getUserAccount() {
    this.progressNo = 0;
    let user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      this.firestore.collection('account', ref => ref.where('userId', '==', user.uid)).get()
        .subscribe(snapshot => {
          if (!snapshot.empty) {
            this.isAccount = true;
            this.index = 0;
            this.progressStat();
            this.openNext();
            snapshot.forEach(doc1 => {
              let d = doc1.data();
              d.personalDetails.dob = new Date(d.personalDetails.dob.seconds * 1000);
              this.accountData = d;
            });
          }
        });
      this.firestore.collection('membership', ref => ref.where('userId', '==', user.uid)).get()
        .subscribe(snapshot => {
          if (!snapshot.empty) {
            this.isMembership = true;
            this.index = 1;
            this.progressStat();
            this.openNext();
            snapshot.forEach(doc1 => {
              this.membershipData = doc1.data();
            });
          }
        });
      this.firestore.collection('medicalHistory', ref => ref.where('userId', '==', user.uid)).get()
        .subscribe(snapshot => {
          if (!snapshot.empty) {
            this.isMedical = true;
            this.index = 2;
            this.progressStat();
            this.openNext();
            snapshot.forEach(doc1 => {
              this.medicalData = doc1.data();
            });
          }
        });
      if (user.quastionnaire) {
        this.isQuestion = true;
        this.index = 3;
        this.progressStat();
        this.openNext();
      } else {
        this.firestore.collection('questionnaire', ref => ref.where('clubId', '==', user.clubId).where('role', '==', user.roles)).get()
          .subscribe(snapshot => {
            if (snapshot.empty) {
              this.isQuestion = true;
              this.index = 3;
              this.progressStat();
              this.openNext();
            } 
          });
      }
      this.firestore.collection('signature', ref => ref.where('userId', '==', user.uid)).get()
        .subscribe(snapshot => {
          if (!snapshot.empty) {
            this.isSignature = true;
            this.index = 4;
            this.progressStat();
            this.openNext();
          }
        });
    }
  }

  openNext() {
    this.index = (this.index === 4) ? 0 : this.index + 1;

  }

  openPrev() {
    this.index = (this.index <= 0) ? 4 : this.index - 1;
  }

  progressStat() {
    this.progressNo = this.progressNo + 20;
    this.progress = this.progressNo + '';
    this.progressStatus = this.progressNo == 100 ? 'Completed!' : this.progress + '%';
    this.type = "success";
  }

  parentFun() {
    if (this.isMedical && this.isAccount && this.isMembership && this.isQuestion && this.isSignature)
      this.route.navigate([localStorage.getItem("clubName") + "/settings/success"]);
    else {
      this.getUserAccount();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogDetails, {
      data: {
        personalDetails: this.accountData ? this.accountData.personalDetails : '',
        emergencyDetails: this.accountData ? this.accountData.emergencyDetails : '',
        membership: this.membershipData ? this.membershipData : '',
        medical: this.medicalData ? this.medicalData : ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'dialog-details',
  templateUrl: 'dialogDetails.html',
})
export class DialogDetails {
  @ViewChild('htmlData') htmlData: ElementRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public captureScreen() {
    let filename = 'User Details' + '_' + new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();
    const doc = new jsPDF()
    autoTable(doc, {
      html: '#contentToConvert',
      willDrawCell: function (data) {
        var rows = data.table.body;
        if (data.cell.text[0] == 'Personal Details' || data.cell.text[0] == 'Emergency Details' ||
          data.cell.text[0] == 'Your Ethnicity' || data.cell.text[0] == 'Photography And Video Recordings' ||
          data.cell.text[0] == 'Doctor Details' || data.cell.text[0] == 'Disease Details' ||
          data.cell.text[0] == 'Disability' || data.cell.text[0] == 'Medical Details' || data.cell.text[0] == 'Sporting Details') {
          doc.setFillColor(211, 211, 211);
        }
      },
    })
    doc.save(filename + '.pdf')
  }
}