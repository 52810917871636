<section class="team-sec team-grid speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of sponsorData" class="col-lg-10 offset-lg-1 speker-container">
                <div class="text-center d-flex">
                    <div class="team-img border border-dark">
                        <img class="img-sp" onerror="this.src='../../assets/images/nouser.png';" [src]="item?.logo">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li *ngIf="item?.facebookLink != ''">
                                    <a [href]="item?.facebookLink" target="_blank">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.twitterLink != ''">
                                    <a [href]="item?.twitterLink" target="_blank">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.instagramLink != ''">
                                    <a [href]="item?.instagramLink" target="_blank">
                                        <i class="fa fa-instagram center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li *ngIf="item?.websiteLink != ''">
                                    <a [href]="item?.websiteLink" target="_blank">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee w-100">
                        <h5 class="e-name text-center">{{item?.name}} </h5>
                        <div [innerHtml]="item?.description"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>