<!-- Login section -->
<section class="login-page section-b-space pb-3 pt-3">
    <div class="container" style="max-width: 500px;">
        <div class="row">
            <div class="col-lg-12">
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <h5 for="email" class="text-center">Enter RFID for EXIT</h5>
                            <input type="text" autocomplete="off" class="form-control text-uppercase" [(ngModel)]="rfid" name="rfid" placeholder="RFID"
                                required>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                                (click)="exit(rfid)">Go</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->