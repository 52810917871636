import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavService } from '../../../service/nav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public url: any;
  facebook = "";
  twitter = "";
  website = "";
  instagram = "";
  isCoach = false;

  constructor(private router: Router, public route: ActivatedRoute, public firestore: AngularFirestore, public navServices: NavService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    console.log(localStorage.getItem("clubName") != null && localStorage.getItem("clubName") != "")
    if (localStorage.getItem("clubName") != null && localStorage.getItem("clubName") != "") {
      this.firestore.collection('clubs').get()
        .subscribe(snapshot => {
          snapshot.forEach(doc => {
            let data = doc.data();
            if (data.clubDetails.name.toString().toLowerCase() === localStorage.getItem("clubName").replace(/-/g, ' ').toString().toLowerCase()) {
              this.facebook = data.socialMedia.facebook;
              this.twitter = data.socialMedia.twitter;
              this.website = data.socialMedia.website;
              this.instagram = data.socialMedia.instagram;
              this.isCoach = true;
            }
          });
        });
    } else {
      this.firestore.collection("aboutUs").doc("WzPQBWfBuqW3BOwbt9ag").get().subscribe(snapshot => {
        let data = snapshot.data();
        this.facebook = data.socialMedia.facebook;
        this.twitter = data.socialMedia.twitter;
        this.website = data.socialMedia.website;
        this.instagram = data.socialMedia.instagram;
      });
    }
  }

  redirectUrl(url) {
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }
}
