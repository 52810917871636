<!-- Login section -->
<section class="login-page section-b-space pt-3 pb-3">
    <div *ngIf="isReset" class="container" style="max-width: 500px;">
        <div class="row">
            <div class="col-lg-12">
                <h3 class="text-center">Reset Password</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <label for="new">New Password</label>
                            <input type="password" class="form-control mb-2" [(ngModel)]="newPassword" name="newPassword"
                                placeholder="New Password" required>
                        </div>
                        <div class="form-group">
                            <label for="confirm">Confirm Password</label>
                            <input type="password" class="form-control mb-2" [(ngModel)]="confirmPassword" name="confirmPassword"
                                placeholder="Confirm Password" required>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-block btn-default mb-2 primary-btn radius-0"
                                (click)="resetPassword()">Submit</button>
                            <span class="float-right">Already have account?<a routerLink="/login">
                                    Login</a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->