<section class="bg-gradient-color format gym pt-3" id="schedule">
    <div class="container">
        <div class="row">
            <!-- <div class="col-12">
                <div class="title title2 mb-0">
                    <div class="main-title">
                        <h2 class="borders m-b-0 main-text text-center text-uppercase text-white"><span>classes
                                schedule</span></h2>
                    </div>
                </div>
            </div> -->
            <div class="col-12 mb-5">
                <div class="theme-card">
                    <section class="yoga event bg bg-gradient-color" style="padding: 0px;background: no-repeat;">
                        <div class="container">
                            <div *ngIf="data.length == 0"
                                class="alert alert-danger font-weight-bold form-control-file text-capitalize text-center">
                                <span><i class="fa fa-meh-o mr-1"></i>There are currently no bookings available. To
                                    enquire about booking a session please use our contact form.</span>
                            </div>

                            <div class="row">
                                <marquee *ngIf="data.length > 0"
                                    class="alert alert-danger font-weight-bold form-control-file text-capitalize"
                                    behavior="scroll" direction="left">please note that If its less than 24 hours
                                    before your session you will not get any
                                    refund for your
                                    cancellation.</marquee>
                                <div *ngIf="warningMsg" class="alert alert-warning text-center col-md-12">
                                    Please complete ALL <a routerLink="/{{this.clubURL}}/settings">membership</a>
                                    details or your
                                    account will be suspended.
                                </div>
                                <div *ngIf="data.length > 0" class="col-md-12 text-center mb-3 p-0">
                                    <a [class.factive]="item.value == selectedFilterType"
                                        (click)="filterData(item.value)" title="{{item.lable}}"
                                        *ngFor="let item of filter" class="btn btn-default btn-sm p-1 mr-1 mb-1">
                                        {{item.lable}}
                                    </a>
                                </div>
                                <div class="col-md-12" *ngIf="data.length > 0">
                                    <div *ngIf="selectedFilterType == 'day'" class="col-md-12 text-center mb-3 p-0 sc">
                                        <a [class.factive]="selectedFilterSubType == ''" (click)="filterTypeData('')"
                                            title="all" class="btn btn-default btn-sm mb-1 mr-1 p-1 pl-3 pr-3">
                                            All
                                        </a>
                                        <a [class.factive]="item == selectedFilterSubType"
                                            (click)="filterTypeData(item)" title="{{item}}"
                                            *ngFor="let item of tabDayData"
                                            class="btn btn-default btn-sm p-1 mr-1 mb-1">
                                            {{item}}
                                        </a>
                                    </div>
                                    <div *ngIf="selectedFilterType == 'week'" class="col-md-12 text-center mb-3 p-0 sc">
                                        <a [class.factive]="selectedFilterSubType == ''" (click)="filterTypeData('')"
                                            title="all" class="btn btn-default btn-sm mb-1 mr-1 p-1 pl-3 pr-3">
                                            All
                                        </a>
                                        <a [class.factive]="item == selectedFilterSubType"
                                            (click)="filterTypeData(item)" title="{{item}}"
                                            *ngFor="let item of tabWeekData"
                                            class="btn btn-default btn-sm p-1 mr-1 mb-1">
                                            {{item}}
                                        </a>
                                    </div>
                                    <div *ngIf="selectedFilterType == 'month'" class="col-md-12 text-center mb-3 p-0 sc">
                                        <a [class.factive]="selectedFilterSubType == ''" (click)="filterTypeData('')"
                                            title="all" class="btn btn-default btn-sm mb-1 mr-1 p-1 pl-3 pr-3">
                                            All
                                        </a>
                                        <a [class.factive]="item == selectedFilterSubType"
                                            (click)="filterTypeData(item)" title="{{item}}"
                                            *ngFor="let item of tabMonthData"
                                            class="btn btn-default btn-sm p-1 mr-1 mb-1">
                                            {{item}}
                                        </a>
                                    </div>
                                </div>
                                <div *ngFor="let item of data" class="col-md-6">
                                    <div class="event-container d-flex">
                                        <div class="yoga-circle p-0 pt-3 yoga-circle" style="margin: auto 0;">
                                            <h3 class="gradient-text">{{item.startDate | date:'dd'}}</h3>
                                            <h6 class="month">{{item.startDate | date:'MMMM'}}</h6>
                                            <h6 class="month">{{item.startDate | date:'EEEE'}}</h6>
                                        </div>
                                        <div class="event-info">
                                            <h4 class="text-info address mb-0">{{item.name}} <span *ngIf="item.amount"
                                                    class="text-success">- {{item?.amount | currency:'GBP'}}</span></h4>
                                            <h6 class="text-white time mb-0">
                                                {{item.startDate | date:'dd/MM/yyyy h:mm a'}} TO
                                                {{item.endDate | date:'dd/MM/yyyy h:mm a'}}</h6>
                                            <h6 class="text-white city"><i aria-hidden="true"
                                                    class="fa fa-map-marker m-r-5"></i>{{item?.location}}
                                            </h6>
                                            <button style="width:250px;"
                                                *ngIf="!item.booked && (item.bookedSlots < item.slots)"
                                                [disabled]="isDisabled" (click)="bookNow(item)"
                                                class="btn btn-app1-theme btn-default btn-sm mt-2 p-1"><i
                                                    class="fa fa-plus"></i> Book Now
                                                ({{item.slots - item?.bookedSlots}} slots left)</button>
                                            <span *ngIf="item.booked" class="text-success mt-1">Booked on
                                                {{item.bookingDate | date:'dd/MM/yyyy'}}</span>
                                            <span *ngIf="item.bookedSlots == item.slots" class="text-success mt-1">House
                                                Full</span>
                                            <div class="mt-2">
                                                <a [routerLink]="" (click)="addToCalendar(calendarView,item)"
                                                    style="width:250px;" [attr.data-target]="'#calendarView'"
                                                    data-toggle="modal" title="Add To Calendar
                                                " class="btn btn-outline-info p-1 rounded-pill"><i
                                                        class="fa fa-calendar" aria-hidden="true"></i> Add To
                                                    Calendar</a>
                                            </div>
                                            <div class="mt-2" *ngIf="item.cancel">
                                                <button style="width:250px;" (click)="cancelBooking(item)"
                                                    class="btn btn-danger p-1 rounded-pill"><i class="fa fa-remove"></i>
                                                    Cancel Your Booking</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quick view Popup -->
<ng-template #calendarView let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Add Session In Calendar</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 text-center">
                <a [href]="googleUrl" target="_blank" class="btn btn-outline-dark rounded-pill mr-2 mb-2"><i
                        class="fa fa-google"></i> Google</a>
                <a [href]="icalUrl" target="_blank" class="btn btn-outline-dark rounded-pill mr-2 mb-2"><i
                        class="fa fa-apple"></i> Apple</a>
                <a [href]="outlookUrl" target="_blank" class="btn btn-outline-dark rounded-pill mr-2 mb-2"><i
                        class="fa fa-microsoft"></i> Outlook</a>
                <a [href]="yahooUrl" target="_blank" class="btn btn-outline-dark rounded-pill mr-2 mb-2"><i
                        class="fa fa-yahoo"></i> Yahoo</a>
            </div>
        </div>
    </div>
</ng-template>