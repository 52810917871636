import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment.prod';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  clubName: ""; userId: ""; currentUrl = "";
  accountData: any; medicalData: any; sponsorshipData: any; userData: any; donorData = []; clubData: any;
  sponsorsData = []; sponsorshipFaq: any;
  selectedAmount = 15;
  totalAmount = 0;
  donerForm: FormGroup;
  isAnonymous = false;
  pricingcarouselOptions = {
    items: 4,
    margin: 10,
    nav: false,
    autoplay: true,
    slideSpeed: 600,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      480: {
        items: 1,
        margin: 10
      },
      992: {
        items: 1,
        margin: 10
      }
    }
  }

  constructor(private route: ActivatedRoute, private firestore: AngularFirestore, private router: Router, private _formBuilder: FormBuilder,
    private http: HttpClient, public toastService: ToastrService, private modalService: NgbModal,) {
    this.route.params.subscribe(params => {
      this.clubName = params['clubName'];
      this.userId = params['id'];
      if (this.userId) {
        this.firestore.collection('users', ref => ref.where('uid', '==', this.userId).limit(1)).get()
          .subscribe(snapshot => {
            if (snapshot.empty)
              this.router.navigate(["/404"])
            else {
              snapshot.forEach(doc => {
                this.userData = doc.data();
                if (this.userData.sponsorship) {
                  this.currentUrl = "https://app.pamit.co.uk/" + this.clubName + "/profile/" + this.userData.uid + "/" + this.userData.firstName.toString().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''); + "-" + this.userData.lastName.toString().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                  this.getUserDetails();
                } else
                  this.router.navigate(["/404"])
              });
            }
          });
      }
    });
  }

  ngOnInit(): void {
    this.donerForm = this._formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      contact: [''],
      message: [''],
      facebookLink: [''],
      instagramLink: [''],
      websiteLink: [''],
      twitterLink: [''],
    });
    let checkoutId = this.route.snapshot.paramMap.get('checkoutid');
    if (checkoutId)
      this.checkPayment(checkoutId);
  }

  async getUserDetails() {
    await this.firestore.collection('account', ref => ref.where('userId', '==', this.userId).limit(1)).get().subscribe(docs => {
      docs.forEach(e => {
        this.accountData = e.data();
        this.accountData.age = this.getAge(new Date(this.accountData.personalDetails.dob.seconds * 1000));
        $("#socialshare").jsSocials({
          url: this.currentUrl,
          text: "Help " + this.accountData.personalDetails.firstName + "-" + this.accountData.personalDetails.lastName + " to raise money for sponsorship",
          showLabel: false,
          showCount: false,
          shares: ["email", "twitter", "facebook", "whatsapp"]
        });
      });
    });
    await this.firestore.collection('medicalHistory', ref => ref.where('userId', '==', this.userId).limit(1)).get().subscribe(docs => {
      docs.forEach(e => {
        this.medicalData = e.data();
      });
    });
    await this.firestore.collection('sponsorship', ref => ref.where('userId', '==', this.userId).limit(1)).get().subscribe(docs => {
      docs.forEach(e => {
        this.sponsorshipData = e.data();
      });
    });
    await this.firestore.collection('donors', ref => ref.where('userId', '==', this.userId)).get().subscribe(docs => {
      docs.forEach(e => {
        let data = e.data();
        if (data.status == "succeeded" || data.status == "cash") {
          data.createdDate = new Date(data.createdDate.seconds * 1000);
          data.active = data.active != undefined ? data.active : true;
          if (data.active == true) {
            this.donorData.push(data);
            this.totalAmount += data.amount;
          }
        }
      });
    });
    await this.firestore.collection('clubs').doc(this.userData.clubId).get().subscribe(doc => {
      this.clubData = doc.data();
      var div = document.createElement("div");
      div.innerHTML = this.clubData.clubDetails.description;
      var text = div.textContent || div.innerText || "";
      this.clubData.clubDetails.description = text;
    });
    await this.firestore.collection('sponsors', ref => ref.where('clubId', '==', this.userData.clubId)).get().subscribe(docs => {
      docs.forEach(e => {
        let data = e.data();
        this.sponsorshipData.images.push(data.logo);
        var div = document.createElement("div");
        div.innerHTML = data.description;
        var text = div.textContent || div.innerText || "";
        data.description = text;
        this.sponsorsData.push(data);
      });
    });
    await this.firestore.collection('sponsorship_faq', ref => ref.where('clubId', '==', this.userData.clubId).limit(1)).get().subscribe(docs => {
      docs.forEach(e => {
        let data = e.data();
        this.sponsorshipFaq = data;
      });
    });
  }

  getAge(birthDate: Date) {
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  selectAmount(amount) {
    this.selectedAmount = amount;
  }

  focusFunction() {
    this.selectedAmount = 0;
  }

  donate() {
    let formData = this.donerForm.value;
    if (this.selectedAmount == 0 || this.selectedAmount < 0)
      this.toastService.error('Please enter valid amount', 'Error');
    else if (!this.isAnonymous && formData.email == '')
      this.toastService.error('Your email is required', 'Error');
    else if (!this.isAnonymous && formData.name == '')
      this.toastService.error('Your name is required', 'Error');
    else {
      let checkOutId = this.randomString(20);
      let body = {
        "price": this.selectedAmount * 100,
        "clubUrl": this.clubName,
        "checkoutId": checkOutId,
        "customerEmail": '',
        "url": this.currentUrl
      }
      let donarInfo = {
        email: this.isAnonymous ? '' : formData.email,
        name: this.isAnonymous ? 'Someone' : formData.name,
        contact: this.isAnonymous ? '' : formData.contact,
        message: this.isAnonymous ? '' : formData.message,
        transactionId: null,
        amount: this.selectedAmount,
        status: "created",
        userId: this.userData.uid,
        clubId: this.userData.clubId,
        createdDate: new Date(),
        facebookLink: this.isAnonymous ? '' : formData.facebookLink,
        instagramLink: this.isAnonymous ? '' : formData.instagramLink,
        websiteLink: this.isAnonymous ? '' : formData.websiteLink,
        twitterLink: this.isAnonymous ? '' : formData.twitterLink,
      }
      this.http.post(environment.prodApiLink + "sponsorship/create-checkout-session", body).subscribe(response => {
        var res: any = response;
        if (res.error) {
          this.toastService.error(res.error.message, 'Error');
        } else {
          this.firestore.collection("payments").doc(checkOutId.toString()).set({
            payment_intent: res.payment_intent,
            checkoutId: checkOutId,
            transactionId: null,
            userId: this.userData.uid,
            status: "created",
            createdDate: new Date(),
            clubId: this.userData.clubId,
            updatedDate: null
          }).then(resData => {
            this.firestore.collection("donors").doc(checkOutId.toString()).set(donarInfo).then(resData => {
              window.location.href = res.url;
            })
          })
        }
      });
    }
  }

  checkPayment(checkoutId) {
    this.firestore.collection('payments').doc(checkoutId).get()
      .subscribe(doc => {
        if (doc.exists) {
          let paymentData = doc.data();
          if (paymentData.status == "created") {
            this.firestore.collection("users").doc(paymentData.userId).get().subscribe(user => {
              if (user.exists) {
                let userData = user.data();
                let body = {
                  id: doc.data().payment_intent
                }
                this.http.post(environment.prodApiLink + "payment/details", body).subscribe(response => {
                  let res: any = response;
                  if (res.status == "succeeded") {
                    let amount = res.amount / 100;
                    this.firestore.collection("transactions").add({
                      uid: userData.uid,
                      name: userData.firstName + ' ' + userData.lastName,
                      email: userData.email,
                      RFID: userData.RFID,
                      transactionDate: new Date(),
                      stripeToken: res.id,
                      paymentMode: 'sponsorship',
                      paymentType: 'credit',
                      status: 'success',
                      amount: amount,
                      openingBalance: 0,
                      closingBalance: 0,
                      clubId: userData.clubId,
                      sessionId: '',
                      orderId: '',
                      details: "Amount credited into club wallet for sponsorship"
                    }).then(result1 => {
                      this.firestore.collection('payments').doc(checkoutId).update({
                        status: res.status,
                        transactionId: result1.id,
                        userId: userData.uid,
                        updatedDate: new Date
                      }).then(d => {
                        this.firestore.collection('donors').doc(checkoutId).update({
                          status: res.status,
                          transactionId: result1.id,
                        }).then(d => {
                          window.location.href = this.currentUrl;
                        })
                      });
                    }).catch(err => {
                      this.toastService.error(err, 'Error');
                    });
                  } else {
                    this.firestore.collection('payments').doc(checkoutId).update({
                      status: res.status
                    })
                    this.firestore.collection('donors').doc(checkoutId).update({
                      status: res.status
                    })
                  }
                });
              } else {
                this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
                window.location.href = this.currentUrl;
              }
            });
          } else window.location.href = this.currentUrl;
        } else {
          this.toastService.error("Something went wrong. Please try again after sometime.", 'Error');
          window.location.href = this.currentUrl;
        }
      });
  }

  openModel(content) {
    if (this.selectedAmount <= 0)
      this.toastService.error('Please choose valid amount', 'Error');
    else {
      this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(`Dismissed ${this.getDismissReason(reason)}`);
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changePaymentMethod(event) {
    if (event.checked) {
      this.isAnonymous = true;
      this.donerForm.reset();
    } else {
      this.isAnonymous = false;
    }
  }

  randomString(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
