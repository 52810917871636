import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Gym Layout
import { GymComponent } from './gym/gym.component';
import { GymNavComponent } from './gym/gym-nav/gym-nav.component';
import { GymHeaderComponent } from './gym/gym-header/gym-header.component';

@NgModule({
  declarations: [
    GymComponent, GymNavComponent, GymHeaderComponent
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    CarouselModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: []
})

export class LayoutsModule { }
