import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit {
  rfid: any;
  clubData: any;
  adminData: any;
  data: any;
  medicalData: any;
  photography: any;
  totalUsers = 0;
  usersData = [];
  users = [];
  name = '';
  filteredUsers = [];

  constructor(public afs: AngularFirestore, public toastService: ToastrService,) {
    this.adminData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    if (this.adminData) {
      this.afs.collection("clubs").doc(this.adminData.clubId).get()
        .subscribe(snapshot => {
          this.clubData = snapshot.data();
        });
      this.afs.collection("users", ref => ref.where('clubId', '==', this.adminData.clubId)).valueChanges()
        .subscribe(snapshot => {
          this.users = snapshot;
          this.users = this.users.filter(a => a.roles != 'clubAdmin');
          this.totalUsers = snapshot.length;
          this.users.forEach(e => {
            e.fullName = e.firstName + ' ' + e.lastName;
          });
        });
    }
  }

  filterUsers(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.users.length; i++) {
      let user = this.users[i];
      if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }
    this.filteredUsers = filtered;
  }

  selectName(event) {
    this.rfid = event.RFID;
    this.submitDetails(event.RFID)
  }

  submitDetails(rfid) {
    this.data = undefined;
    this.usersData = [];
    if (!rfid) {
      this.toastService.error('Enter RFID or Name', 'Error')
    } else {
      this.afs.collection("users", ref => ref.where('club_rfid', '==', this.adminData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            //this.searchPostCode(this.rfid);
            this.toastService.error("User details not found", 'Error');
            this.clearVar();
          }
          else {
            snapshot.forEach(doc => {
              let data1 = doc.data();
              if (data1.RFID && data1.RFID != "" && data1.RFID == rfid) {
                this.afs.collection("account", ref => ref.where('userId', '==', data1.uid)).get()
                  .subscribe(snapshot1 => {
                    if (snapshot1.empty) {
                      this.toastService.error("User details not found", 'Error');
                      this.clearVar();
                    } else {
                      snapshot1.forEach(doc => {
                        let d = doc.data();
                        d.personalDetails.dob = new Date(d.personalDetails.dob.seconds * 1000);
                        this.data = d;
                        let warningMsg = "Details " + (data1.accountDetailsAddedDate == null && data1.medicalDetailsAddedDate == null && data1.membershipDetailsAddedDate == null ? "Incompleted" : "Completed") + " and " + (data1.detailsVerified ? "Verified" : "Not Verified");
                        this.toastService.warning(warningMsg, '', {
                          closeButton: true,
                          timeOut: 5000
                        });
                      });
                      this.toastService.success("User details found", 'Success');
                    }
                  });
                this.afs.collection("medicalHistory", ref => ref.where('userId', '==', data1.uid)).get()
                  .subscribe(snapshot2 => {
                    snapshot2.forEach(doc => {
                      this.medicalData = doc.data();
                    });
                  });
                this.afs.collection("membership", ref => ref.where('userId', '==', data1.uid)).get()
                  .subscribe(snapshot3 => {
                    snapshot3.forEach(doc => {
                      this.photography = doc.data().photography.consent;
                    });
                  });
              }
            });
          }
        });
    }
  }

  clearVar() {
    this.rfid = '';
    this.name = '';
  }

  async searchPostCode(postCode) {
    this.usersData = [];
    this.afs.collection("account", ref => ref.where('personalDetails.postcode', '==', postCode)).get()
      .subscribe(snapshot1 => {
        if (snapshot1.empty) {
          this.toastService.error("User details not found", 'Error');
          this.rfid = '';
        } else {
          snapshot1.forEach(doc => {
            let accountData = doc.data();
            accountData.personalDetails.dob = new Date(accountData.personalDetails.dob.seconds * 1000);
            this.getDetails(accountData);
          });
          this.toastService.success("User details found", 'Success');
        }
      });
  }

  async getDetails(accountData) {
    let medical = await this.getMedicalHistory(accountData.userId);
    let photography = await this.getPhotographyConsent(accountData.userId);
    let uData = {
      account: accountData,
      medical: medical,
      photography: photography
    }
    this.usersData.push(uData);
  }

  async getMedicalHistory(userId) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("medicalHistory", ref => ref.where('userId', '==', userId)).get()
        .subscribe(snapshot2 => {
          if (snapshot2.empty)
            resolve([])
          snapshot2.forEach(doc => {
            resolve(doc.data());
          });
        });
    });
  }

  async getPhotographyConsent(userId) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("membership", ref => ref.where('userId', '==', userId)).get()
        .subscribe(snapshot3 => {
          if (snapshot3.empty)
            resolve(false);
          snapshot3.forEach(doc => {
            resolve(doc.data().photography.consent);
          });
        });
    });
  }
}
