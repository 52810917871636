import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from "../login/login.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { EmailService } from '../../shared/service/email.service';

@Component({
  selector: 'app-in-out',
  templateUrl: './in-out.component.html',
  styleUrls: ['./in-out.component.scss'],
  providers: [EmailService]
})
export class InOutComponent implements OnInit {
  form: FormGroup;
  rfid: string;
  isLoggedIn = false;
  userName = '';
  userImage: any;
  amount = 0;
  pricingData: any;
  userData: any;
  paymentMsg = '';
  isDisabled = false;
  clubData: any;
  @ViewChild('RFID', { static: false }) RFID: ElementRef;
  adminData: any;
  activeUsers = 0;
  sessionsData = [];
  isChooseSession = false;
  selectedSession: any;
  sessionMessage = '';
  sessionPrice = 0;
  activeUsersData = [];
  closeResult: string;
  bookingData = [];
  activeOtherRolesData = [];
  private updateSubscription: Subscription;
  guestFormGroup: FormGroup;
  entryWindow = 30;
  start = new Date(Date.now() - 864e5);
  end = new Date(Date.now() + 216e5);

  constructor(public service: LoginService, private _formBuilder: FormBuilder, public afs: AngularFirestore,
    private modalService: NgbModal, public toastService: ToastrService, private emailService: EmailService) {
    this.adminData = JSON.parse(sessionStorage.getItem('user'));
    this.guestFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      emergancyContactNo: [''],
      gender: [''],
      contactNo: [''],
      clubId: [''],
      address: [''],
      createdDate: [''],
      email: [''],
      fee: [''],
      organization: [''],
      role: [''],
      entryTime: [''],
      exitTime: [''],
      type: ['']
    });
  }

  ngOnInit(): void {
    if (this.adminData) {
      this.afs.collection("clubs").doc(this.adminData.clubId).get()
        .subscribe(snapshot => {
          this.clubData = snapshot.data();
        });
      this.getSessionsData();
      this.getActiveUsers('');
    }
    this.updateSubscription = interval(60000).subscribe(
      (val) => {
        this.getSessionsData();
        this.getActiveUsers('');
      });
  }

  getSessionsData() {
    this.sessionsData = [];
    this.bookingData = [];
    this.afs.collection('sessions', ref => ref.where('clubId', '==', this.adminData.clubId)
      .where('startDate', '>', this.start).where('startDate', '<', this.end)
      .orderBy("startDate", "desc")).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.startDate = new Date(sData.startDate.seconds * 1000);
          sData.endDate = new Date(sData.endDate.seconds * 1000);
          sData.id = doc.id;
          sData.remainingSlots = 0;
          this.afs.collection('userLogs', ref => ref.where('clubId', '==', this.adminData.clubId).where('type', '==', 'entry'
          ).where('sessionId', '==', doc.id)).get()
            .subscribe(snapshot => {
              sData.activeUsers = snapshot.size;
              let booked = 0;
              snapshot.forEach(e2 => {
                if (e2.data().role == 'clubUser') {
                  this.afs.collection('appointments', ref => ref.where('uid', '==', e2.data().uid).where('sessionId', '==', doc.id
                  ).limit(1)).get()
                    .subscribe(snapshot => {
                      if (snapshot.empty) {
                        let mSlots = sData.slots - sData.bookedSlots;
                        if (mSlots == 0)
                          sData.remainingSlots = 0;
                        else {
                          booked += 1;
                          sData.remainingSlots = (sData.slots - sData.bookedSlots) - booked;
                        }
                      }
                    });
                }
              });
            });
          let minutes = this.getMinutesBetweenDates(new Date(), new Date(sData.startDate));
          if (minutes > 0 && minutes <= (this.adminData.entryWindow ? this.adminData.entryWindow : this.entryWindow)) {
            this.getAllAppointmentData(doc.id);
            this.sessionsData.push(sData);
          }
          else {
            if (new Date(sData.endDate) > new Date() &&
              new Date(sData.startDate) < new Date()) {
              this.getAllAppointmentData(doc.id);
              this.sessionsData.push(sData);
            }
          }
        });
      });
  }

  getAllAppointmentData(id) {
    this.afs.collection('appointments', ref => ref.where('sessionId', '==', id).orderBy('eventName', 'asc')).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.bookingDate = new Date(sData.bookingDate.seconds * 1000);
          sData.startDate = new Date(sData.startDate.seconds * 1000);
          sData.endDate = new Date(sData.endDate.seconds * 1000);
          sData.id = doc.id;
          sData.status = sData.status ? sData.status : false;
          this.afs.collection('users', ref => ref.where('uid', '==', sData.uid).limit(1)).get()
            .subscribe(snapshot => {
              snapshot.forEach(doc => {
                sData.userData = doc.data();
                this.bookingData.push(sData);
              });
            });
        });
      });
  }

  getMinutesBetweenDates(d1, d2) {
    var diff = d2.getTime() - d1.getTime();
    return (diff / 60000);
  }

  getActiveUsers(type) {
    this.activeUsers = 0;
    this.activeUsersData = [];
    this.activeOtherRolesData = [];
    this.afs.collection("userLogs", ref => ref.where('clubId', '==', this.adminData.clubId)
      .where('entryTime', '>', this.start)
      .orderBy('entryTime', 'desc')).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          if (data.role == 'clubUser') {
            data.entryTime = data.entryTime != null ? new Date(data.entryTime.seconds * 1000) : null;
            data.exitTime = data.exitTime != null ? new Date(data.exitTime.seconds * 1000) : null;
            data.lastUpdatedOut = data.lastUpdatedOut != null ? new Date(data.lastUpdatedOut.seconds * 1000) : null;
            if (data.type == "entry") {
              if (data.entryTime.getDate() == new Date().getDate() && data.entryTime.getMonth() == new Date().getMonth()
                && data.entryTime.getFullYear() == new Date().getFullYear()) {
                this.afs.collection('users', ref => ref.where('uid', '==', data.uid).limit(1)).get()
                  .subscribe(snapshot => {
                    snapshot.forEach(doc => {
                      data.userData = doc.data();
                      if (type == 'view') {
                        this.checkUserDetails(data);
                      } else
                        this.activeUsersData.push(data);
                    });
                  });
                this.activeUsers += 1;
              }
            }
          } else {
            data.entryTime = data.entryTime != null ? new Date(data.entryTime.seconds * 1000) : null;
            data.exitTime = data.exitTime != null ? new Date(data.exitTime.seconds * 1000) : null;
            data.lastUpdatedOut = data.lastUpdatedOut != null ? new Date(data.lastUpdatedOut.seconds * 1000) : null;
            if (data.type == "entry") {
              if (data.entryTime.getDate() == new Date().getDate() && data.entryTime.getMonth() == new Date().getMonth()
                && data.entryTime.getFullYear() == new Date().getFullYear()) {
                this.activeOtherRolesData.push(data);
              }
            }
          }
        });
      });
    this.afs.collection("guest", ref => ref.where('clubId', '==', this.adminData.clubId)
      .where('entryTime', '>', this.start)
      .orderBy('entryTime', 'desc')).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          data.id = e.id;
          data.entryTime = data.entryTime != null ? new Date(data.entryTime.seconds * 1000) : null;
          data.exitTime = data.exitTime != null ? new Date(data.exitTime.seconds * 1000) : null;
          if (data.type == "entry") {
            if (data.entryTime.getDate() == new Date().getDate() && data.entryTime.getMonth() == new Date().getMonth()
              && data.entryTime.getFullYear() == new Date().getFullYear()) {
              this.activeOtherRolesData.push(data);
            }
          }
        });
      });
  }

  open(content, type) {
    if (type == 'view')
      this.getActiveUsers(type);
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.RFID.nativeElement.focus());
  }

  async validateRFID(rfid) {
    rfid = rfid.toString().toUpperCase();
    this.isDisabled = true;
    this.isLoggedIn = false;
    this.isChooseSession = false;
    this.pricingData = {};
    this.userData = {};
    this.selectedSession = undefined;
    if (!rfid) {
      this.toastService.error('Enter RFID', 'Error');
      this.soundError();
      this.isDisabled = false;
    } else {
      this.afs.collection("users", ref => ref.where('club_rfid', '==', this.adminData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.toastService.error("User not found. Please contact site administrator.", 'Error');
            this.soundError();
            this.rfid = '';
            this.userName = '';
            this.userImage = '';
            this.isDisabled = false;
          }
          else {
            snapshot.forEach(doc => {
              this.userData = doc.data();
              if (this.userData.roles != "clubUser") {
                let log_done = this.adminData.clubId + '_' + rfid;
                this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                  .subscribe(snapshot1 => {
                    if (snapshot1.empty) {
                      this.addCoachLogs(this.userData);
                    } else {
                      snapshot1.forEach(doc => {
                        let data2 = doc.data();
                        data2.id = doc.id;
                        let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                        let tDay = new Date();
                        if (entry != null) {
                          if (entry.getDate() == tDay.getDate() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                            if (data2.exitTime == null) {
                              this.afs.collection("userLogs").doc(doc.id).update({
                                exitTime: new Date(),
                                type: 'exit'
                              })
                              this.showExitMsg(this.userData);
                              this.getActiveUsers('');
                              this.deActivateUser(this.userData.uid);
                            } else {
                              if (data2.type == "entry") {
                                this.afs.collection("userLogs").doc(data2.id).update({
                                  lastUpdatedOut: new Date(),
                                  type: 'exit'
                                });
                                this.showExitMsg(this.userData);
                                this.getActiveUsers('');
                                this.deActivateUser(this.userData.uid)
                              }
                              else {
                                this.afs.collection("userLogs").doc(data2.id).update({
                                  lastUpdatedIn: new Date(),
                                  type: 'entry'
                                });
                                this.showEntryMsg(this.userData);
                                this.getActiveUsers('');
                                this.activateUser(this.userData.uid);
                              }
                            }
                            this.rfid = '';
                            this.isDisabled = false;
                          } else {
                            this.addCoachLogs(this.userData);
                          }
                        } else {
                          this.addCoachLogs(this.userData);
                        }
                      });
                    }
                  });
              } else {
                if (this.sessionsData.length > 0) {
                  // if ((this.sessionsData[0].slots == this.activeUsers) && !this.userData.isActive) {
                  //   this.toastService.error('All slots for this session are filled', 'Error');
                  //   this.soundError();
                  //   this.rfid = '';
                  //   this.isDisabled = false;
                  // } else {
                  if (this.userData.RFID && this.userData.RFID != "" && this.userData.RFID == rfid) {
                    this.pricingData = this.clubData.planDetails.planDetails.filter(a => a.plan == this.userData.group)[0];
                    let log_done = this.adminData.clubId + '_' + rfid
                    this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                      .subscribe(snapshot1 => {
                        if (snapshot1.empty) {
                          this.addLogs(this.userData);
                        } else {
                          snapshot1.forEach(doc => {
                            let data2 = doc.data();
                            data2.id = doc.id;
                            let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                            let tDay = new Date();
                            if (entry != null) {
                              if (entry.getDate() == tDay.getDate() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                                if (data2.exitTime == null) {
                                  this.afs.collection("userLogs").doc(doc.id).update({
                                    exitTime: new Date(),
                                    type: 'exit'
                                  }).then(res => {
                                    this.showExitMsg(this.userData);
                                    this.getActiveUsers('');
                                    this.deActivateUser(this.userData.uid)
                                  }).catch(err => {
                                    this.toastService.error(err, 'Error');
                                    this.soundError();
                                  });
                                } else {
                                  if (data2.type == "entry") {
                                    this.afs.collection("userLogs").doc(data2.id).update({
                                      lastUpdatedOut: new Date(),
                                      type: 'exit'
                                    }).then(res => {
                                      this.showExitMsg(this.userData);
                                      this.getActiveUsers('');
                                      this.deActivateUser(this.userData.uid)
                                    }).catch(err => {
                                      this.toastService.error(err, 'Error');
                                      this.soundError();
                                    });
                                  }
                                  else {
                                    let activeSession = this.sessionsData.filter(a => a.id == data2.sessionId);
                                    if (activeSession.length > 0) {
                                      this.afs.collection("userLogs").doc(data2.id).update({
                                        lastUpdatedIn: new Date(),
                                        type: 'entry'
                                      }).then(res => {
                                        this.showEntryMsg(this.userData);
                                        this.getActiveUsers('');
                                        this.activateUser(this.userData.uid);
                                      }).catch(err => {
                                        this.toastService.error(err, 'Error');
                                        this.soundError();
                                      });
                                    } else {
                                      this.isChooseSession = true;
                                      this.sessionMessage = 'Please select any one session to proceed.';
                                      this.soundError();
                                    }
                                  }
                                }
                                this.rfid = '';
                                this.isDisabled = false;
                              } else {
                                this.addLogs(this.userData);
                              }
                            } else {
                              this.addLogs(this.userData);
                            }
                          });
                        }
                      });
                  }
                  //}
                } else {
                  if (this.userData.RFID && this.userData.RFID != "" && this.userData.RFID == rfid) {
                    this.pricingData = this.clubData.planDetails.planDetails.filter(a => a.plan == this.userData.group)[0];
                    let log_done = this.adminData.clubId + '_' + rfid
                    this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                      .subscribe(snapshot1 => {
                        if (snapshot1.empty) {
                          this.toastService.error('Oops! Active session not available. Please visit again after some time.', 'Error');
                          this.soundError();
                          this.rfid = '';
                          this.isDisabled = false;
                        } else {
                          snapshot1.forEach(doc => {
                            let data2 = doc.data();
                            data2.id = doc.id;
                            let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                            let tDay = new Date();
                            if (entry != null) {
                              if (entry.getDate() == tDay.getDate() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                                if (data2.exitTime == null) {
                                  this.afs.collection("userLogs").doc(doc.id).update({
                                    exitTime: new Date(),
                                    type: 'exit'
                                  }).then(res => {
                                    this.showExitMsg(this.userData);
                                    this.getActiveUsers('');
                                    this.deActivateUser(this.userData.uid)
                                  }).catch(err => {
                                    this.toastService.error(err, 'Error');
                                    this.soundError();
                                  });
                                } else {
                                  if (data2.type == "entry") {
                                    this.afs.collection("userLogs").doc(data2.id).update({
                                      lastUpdatedOut: new Date(),
                                      type: 'exit'
                                    }).then(res => {
                                      this.showExitMsg(this.userData);
                                      this.getActiveUsers('');
                                      this.deActivateUser(this.userData.uid)
                                    }).catch(err => {
                                      this.toastService.error(err, 'Error');
                                      this.soundError();
                                    });
                                  }
                                  else {
                                    let activeSession = this.sessionsData.filter(a => a.id == data2.sessionId);
                                    if (activeSession.length > 0) {
                                      this.afs.collection("userLogs").doc(data2.id).update({
                                        lastUpdatedIn: new Date(),
                                        type: 'entry'
                                      }).then(res => {
                                        this.showEntryMsg(this.userData);
                                        this.getActiveUsers('');
                                        this.activateUser(this.userData.uid);
                                      }).catch(err => {
                                        this.toastService.error(err, 'Error');
                                        this.soundError();
                                      });
                                    } else {
                                      this.isChooseSession = true;
                                      this.sessionMessage = 'Please select any one session to proceed.';
                                      this.soundError();
                                    }
                                  }
                                }
                                this.rfid = '';
                                this.isDisabled = false;
                              } else {
                                this.toastService.error('Oops! Active session not available. Please visit again after some time.', 'Error');
                                this.soundError();
                                this.rfid = '';
                                this.isDisabled = false;
                              }
                            } else {
                              this.toastService.error('Oops! Active session not available. Please visit again after some time.', 'Error');
                              this.soundError();
                              this.rfid = '';
                              this.isDisabled = false;
                            }
                          });
                        }
                      });
                  }
                }
              }
            });
          }
        });
    }
  }

  async addCoachLogs(userData) {
    this.entryUserLogs(userData, this.sessionsData.length > 0 ? this.sessionsData[0].id : '', false);
    this.showEntryMsg(userData);
    this.rfid = '';
    this.isDisabled = false;
    this.activateUser(userData.uid);
  }

  async addLogs(userData) {
    let appoData = [];
    (await this.getAppointmentData(userData).toPromise()).forEach(e => {
      let sData = e.data();
      sData.id = e.id;
      sData.startDate = new Date(sData.startDate.seconds * 1000);
      sData.endDate = new Date(sData.endDate.seconds * 1000)
      if (this.sessionsData.length > 0) {
        let sess = this.sessionsData.filter(a => a.id == sData.sessionId);
        if (sess.length > 0) {
          appoData.push(sData);
        }
      }
    });
    this.confirmLogs(userData, appoData)
  }

  confirmLogs(userData, appointment) {
    if (appointment.length > 0) {
      this.paymentMsg = "Welcome To Your Booked Session."
      this.isLoggedIn = false;
      this.entryUserLogs(userData, appointment[0].sessionId, true);
      this.activateUser(userData.uid);
      this.updateAppointment(appointment[0].id);
      this.timeout();
    } else {
      if (this.sessionsData.length > 1) {
        this.isChooseSession = true;
        this.soundPinch();
        this.sessionMessage = 'Please select any one session to proceed.';
      } else {
        this.sessionPrice = this.sessionsData[0].amount ? this.sessionsData[0].amount : this.pricingData.price;
        if (userData.balance < this.sessionPrice) {
          this.isLoggedIn = true;
          this.toastService.success(userData.firstName + ' ' + userData.lastName, 'Welcome');
          this.soundSuccess();
          this.paymentMsg = "Not enough credit for entry. Please use below options.";
        }
        else {
          this.afs.collection("transactions").add({
            uid: userData.uid,
            name: userData.firstName + ' ' + userData.lastName,
            email: userData.email,
            RFID: userData.RFID,
            transactionDate: new Date(),
            stripeToken: '',
            paymentMode: 'wallet',
            paymentType: 'debit',
            status: 'success',
            amount: this.sessionPrice,
            openingBalance: userData.balance,
            closingBalance: userData.balance - this.sessionPrice,
            clubId: userData.clubId,
            sessionId: this.sessionsData[0].id,
            orderId: '',
            details: "Session booked"
          }).then(result => {
            this.emailService.postSendDebitMail(userData.email, userData.balance - this.sessionPrice, this.sessionPrice, userData.firstName + ' ' + userData.lastName)
            this.afs.collection("users").doc(userData.uid).update({
              balance: userData.balance - this.sessionPrice
            }).then(user1 => {
              userData.balance = userData.balance - this.sessionPrice;
              this.toastService.success(userData.firstName + ' ' + userData.lastName, 'Welcome');
              this.paymentMsg = "Your Payment Was Successfully Received."
              this.isLoggedIn = false;
              this.entryUserLogs(userData, this.sessionsData[0].id, true);
              this.activateUser(userData.uid);
              this.timeout();
            }).catch(err => {
              this.toastService.error(err, 'Error');
              this.soundError();
            });
          }).catch(err => {
            this.toastService.error(err, 'Error');
            this.soundError();
          });
        }
      }
    }
    this.isDisabled = false;
    this.rfid = '';
    this.userName = userData.firstName + ' ' + userData.lastName;
    this.userImage = userData.imageUrl ? userData.imageUrl : '';
  }

  chooseSession(sessionData) {
    this.selectedSession = sessionData;
    if (confirm("Are you sure you want to select this session?")) {
      this.isChooseSession = false;
      this.sessionPrice = this.selectedSession.amount ? this.selectedSession.amount : this.pricingData.price;
      if (this.userData.balance < this.sessionPrice) {
        this.isLoggedIn = true;
        this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
        this.soundSuccess();
        this.paymentMsg = "Not enough credit for entry. Please use below options.";
      }
      else {
        this.afs.collection("transactions").add({
          uid: this.userData.uid,
          name: this.userData.firstName + ' ' + this.userData.lastName,
          email: this.userData.email,
          RFID: this.userData.RFID,
          transactionDate: new Date(),
          stripeToken: '',
          paymentMode: 'wallet',
          paymentType: 'debit',
          status: 'success',
          amount: this.sessionPrice,
          openingBalance: this.userData.balance,
          closingBalance: this.userData.balance - this.sessionPrice,
          clubId: this.userData.clubId,
          sessionId: this.selectedSession.id,
          orderId: '',
          details: "Session booked"
        }).then(result => {
          this.emailService.postSendDebitMail(this.userData.email, this.userData.balance - this.sessionPrice, this.sessionPrice, this.userData.firstName + ' ' + this.userData.lastName);
          this.afs.collection("users").doc(this.userData.uid).update({
            balance: this.userData.balance - this.sessionPrice
          }).then(user1 => {
            this.userData.balance = this.userData.balance - this.sessionPrice;
            this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
            this.paymentMsg = "Your Payment Was Successfully Received."
            this.isLoggedIn = false;
            this.entryUserLogs(this.userData, this.selectedSession ? this.selectedSession.id : this.sessionsData[0].id, true);
            this.activateUser(this.userData.uid);
            this.timeout();
          }).catch(err => {
            this.toastService.error(err, 'Error');
            this.soundError();
          });
        }).catch(err => {
          this.toastService.error(err, 'Error');
          this.soundError();
        });
      }
    }
  }

  updateAppointment(id) {
    this.afs.collection("appointments").doc(id).update({
      status: true
    });
    this.bookingData.forEach(e => {
      if (e.id == id)
        e.status = true;
    });
  }

  getAppointmentData(user) {
    return this.afs.collection('appointments', ref => ref.where('uid', '==', user.uid)).get()
  }

  getSessionWiseActiveUsers() {
    this.sessionsData.forEach(e => {
      this.afs.collection('userLogs', ref => ref.where('clubId', '==', this.adminData.clubId).where('type', '==', 'entry'
      ).where('sessionId', '==', e.id)).get()
        .subscribe(snapshot => {
          let booked = 0;
          snapshot.forEach(e2 => {
            if (e2.data().role == 'clubUser') {
              let bData = this.bookingData.filter(a => a.uid == e2.data().uid && a.sessionId == e.id);
              if (bData.length == 0) {
                let mSlots = e.slots - e.bookedSlots;
                if (mSlots == 0)
                  e.remainingSlots = 0;
                else {
                  booked += 1;
                  e.remainingSlots = (e.slots - e.bookedSlots) - booked;
                }
              }
            }
          });
          e.activeUsers = snapshot.size;
        });
    });
  }

  payInCash() {
    if (this.selectedSession)
      this.sessionPrice = this.selectedSession.amount ? this.selectedSession.amount : this.pricingData.price;
    else
      this.sessionPrice = this.sessionsData[0].amount ? this.sessionsData[0].amount : this.pricingData.price;
    this.isLoggedIn = false;
    this.isDisabled = true;
    this.afs.collection("transactions").add({
      uid: this.userData.uid,
      name: this.userData.firstName + ' ' + this.userData.lastName,
      email: this.userData.email,
      RFID: this.userData.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'cash',
      paymentType: 'credit',
      status: 'success',
      amount: this.sessionPrice,
      openingBalance: this.userData.balance,
      closingBalance: this.userData.balance,
      clubId: this.userData.clubId,
      sessionId: this.sessionsData[0].id,
      orderId: '',
      details: "Session booked"
    });
    this.emailService.postSendDebitMail(this.userData.email, this.userData.balance, this.sessionPrice, this.userData.firstName + ' ' + this.userData.lastName);
    this.activateUser(this.userData.uid);
    this.entryUserLogs(this.userData, this.selectedSession ? this.selectedSession.id : this.sessionsData[0].id, true);
    this.paymentMsg = "Your Payment Was Successfully Received."
    this.toastService.success('Thank you', 'Welcome!');
    this.ngAfterViewInit();
    this.timeout();
  }

  payLater() {
    if (this.selectedSession)
      this.sessionPrice = this.selectedSession.amount ? this.selectedSession.amount : this.pricingData.price;
    else
      this.sessionPrice = this.sessionsData[0].amount ? this.sessionsData[0].amount : this.pricingData.price;
    this.isLoggedIn = false;
    this.isDisabled = true;
    this.afs.collection("transactions").add({
      uid: this.userData.uid,
      name: this.userData.firstName + ' ' + this.userData.lastName,
      email: this.userData.email,
      RFID: this.userData.RFID,
      transactionDate: new Date(),
      stripeToken: '',
      paymentMode: 'payLater',
      paymentType: 'debit',
      status: 'success',
      amount: this.sessionPrice,
      openingBalance: this.userData.balance,
      closingBalance: this.userData.balance - this.sessionPrice,
      clubId: this.userData.clubId,
      sessionId: this.sessionsData[0].id,
      orderId: '',
      details: "Session booked"
    });
    this.emailService.postSendDebitMail(this.userData.email, this.userData.balance - this.sessionPrice, this.sessionPrice, this.userData.firstName + ' ' + this.userData.lastName);
    this.afs.collection("users").doc(this.userData.uid).update({
      balance: this.userData.balance - this.sessionPrice
    }).then(user1 => {
      this.userData.balance = this.userData.balance - this.sessionPrice;
      this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
      this.paymentMsg = "Your Payment Was Successfully Received."
      this.isLoggedIn = false;
      this.entryUserLogs(this.userData, this.selectedSession ? this.selectedSession.id : this.sessionsData[0].id, true);
      this.activateUser(this.userData.uid);
      this.timeout();
    }).catch(err => {
      this.toastService.error(err, 'Error');
      this.soundError();
    });
  }

  goBack() {
    this.isLoggedIn = false;
    this.isDisabled = false;
    this.userName = '';
    this.userImage = '';
    this.paymentMsg = '';
    this.ngAfterViewInit();
  }

  topUpAmount(amount) {
    if (this.selectedSession)
      this.sessionPrice = this.selectedSession.amount ? this.selectedSession.amount : this.pricingData.price;
    else
      this.sessionPrice = this.sessionsData[0].amount ? this.sessionsData[0].amount : this.pricingData.price;
    if ((this.userData.balance + amount) < this.sessionPrice) {
      this.toastService.error("Not enough credit for entry", 'Error');
      this.soundError();
    } else {
      this.isDisabled = true;
      this.afs.collection("transactions").add({
        uid: this.userData.uid,
        name: this.userData.firstName + ' ' + this.userData.lastName,
        email: this.userData.email,
        RFID: this.userData.RFID,
        transactionDate: new Date(),
        stripeToken: '',
        paymentMode: 'cash',
        paymentType: 'credit',
        status: 'success',
        amount: amount,
        openingBalance: this.userData.balance,
        closingBalance: this.userData.balance + amount,
        clubId: this.userData.clubId,
        sessionId: this.sessionsData[0].id,
        orderId: '',
        details: "Top up amount added"
      }).then(result1 => {
        this.emailService.postSendCreditMail(this.userData.email, this.userData.balance + amount, amount, this.userData.firstName + ' ' + this.userData.lastName)
        this.afs.collection("users").doc(this.userData.uid).update({
          balance: this.userData.balance + amount
        }).then(user => {
          this.userData.balance = this.userData.balance + amount;
          this.afs.collection("transactions").add({
            uid: this.userData.uid,
            name: this.userData.firstName + ' ' + this.userData.lastName,
            email: this.userData.email,
            RFID: this.userData.RFID,
            transactionDate: new Date(),
            stripeToken: '',
            paymentMode: 'wallet',
            paymentType: 'debit',
            status: 'success',
            amount: this.sessionPrice,
            openingBalance: this.userData.balance,
            closingBalance: this.userData.balance - this.sessionPrice,
            clubId: this.userData.clubId,
            sessionId: this.sessionsData[0].id,
            orderId: '',
            details: "Session booked"
          }).then(result => {
            this.emailService.postSendDebitMail(this.userData.email, this.userData.balance - this.sessionPrice, this.sessionPrice, this.userData.firstName + ' ' + this.userData.lastName)
            this.afs.collection("users").doc(this.userData.uid).update({
              balance: this.userData.balance - this.sessionPrice
            }).then(user1 => {
              this.userData.balance = this.userData.balance - this.sessionPrice;
              this.toastService.success(this.userData.firstName + ' ' + this.userData.lastName, 'Welcome');
              this.userName = '';
              this.userImage = '';
              this.isLoggedIn = false;
              this.paymentMsg = "Your Payment Was Successfully Received.";
              this.entryUserLogs(this.userData, this.selectedSession ? this.selectedSession.id : this.sessionsData[0].id, true)
              this.activateUser(this.userData.uid);
              this.ngAfterViewInit();
              this.timeout();
              this.isDisabled = false;
            }).catch(err => {
              this.toastService.error(err, 'Error');
              this.soundError();
            });
          }).catch(err => {
            this.toastService.error(err, 'Error');
            this.soundError();
          });
        }).catch(err => {
          this.toastService.error(err, 'Error');
          this.soundError();
        });
      }).catch(err => {
        this.toastService.error(err, 'Error');
        this.soundError();
      });
    }
  }

  entryUserLogs(user, sessionId, type: Boolean) {
    this.afs.collection("userLogs").add({
      uid: user.uid,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      RFID: user.RFID,
      entryTime: new Date(),
      exitTime: null,
      clubId: user.clubId,
      log_done: user.clubId + '_' + user.RFID,
      type: 'entry',
      role: user.roles,
      sessionId: sessionId
    }).then(res => {
      this.getActiveUsers('');
      if (type) {
        this.soundSuccess();
        let warningMsg = '';
        warningMsg = "Details " + (user.accountDetailsAddedDate == null || user.medicalDetailsAddedDate == null || user.membershipDetailsAddedDate == null ? "Incompleted" : "Completed");
        if (user.accountDetailsAddedDate == null || user.medicalDetailsAddedDate == null || user.membershipDetailsAddedDate == null)
          this.toastService.info(warningMsg, 'Warning');
        let mc = user.mediCalled ? user.mediCalled : false;
        let me = user.medical ? user.medical : false;
        if ((mc && me) || me) {
          this.toastService.error('Red Wristband', '', {
            timeOut: 5000
          });
        } else {
          this.toastService.success('Green Wristband', '', {
            timeOut: 5000
          });
        }
        if (user.medicalDetailsAddedDate != null) {
          this.afs.collection('medicalHistory', ref => ref.where('userId', '==', user.uid)).get()
            .subscribe(snapshot => {
              if (!snapshot.empty) {
                snapshot.forEach(doc => {
                  let medData = doc.data();
                  let problems: string[] = [];
                  //warningMsg = "Reg. No : " + (medData.sportingDetails.regNo ? medData.sportingDetails.regNo : "NA");
                  if (medData.diseaseDetails.diabetes == "Yes" || medData.diseaseDetails.highBloodPressure == "Yes" || medData.diseaseDetails.chestLungProblems == "Yes" ||
                    medData.diseaseDetails.heartProblems == "Yes" || medData.diseaseDetails.asthma == "Yes" || medData.diseaseDetails.epilepsy == "Yes" || medData.diseaseDetails.faintingDizziness == "Yes") {
                    if (medData.diseaseDetails.diabetes == "Yes") problems.push('Diabetes');
                    if (medData.diseaseDetails.highBloodPressure == "Yes") problems.push('High Blood Pressure');
                    if (medData.diseaseDetails.chestLungProblems == "Yes") problems.push('Chest Lung Problems');
                    if (medData.diseaseDetails.heartProblems == "Yes") problems.push('Heart Problems');
                    if (medData.diseaseDetails.asthma == "Yes") problems.push('Asthma');
                    if (medData.diseaseDetails.epilepsy == "Yes") problems.push('Epilepsy');
                    if (medData.diseaseDetails.faintingDizziness == "Yes") problems.push('Fainting Dizziness');
                    this.toastService.error(problems.toString(), "Medical Conditions", {
                      closeButton: true,
                      disableTimeOut: true
                    });
                  }
                  // else {
                  //   this.toastService.success(warningMsg, "Medical Conditions : No", {
                  //     closeButton: true,
                  //     timeOut: 5000
                  //   });
                  // }
                });
              }
            });
        }
      }
    }).catch(err => {
      this.toastService.error(err, 'Error');
      this.soundError();
    });
  }

  guestCheckIn() {
    let form = this.guestFormGroup.value;
    form.clubId = this.adminData.clubId;
    form.createdDate = new Date();
    form.entryTime = new Date();
    form.type = 'entry';
    form.fee = form.fee == '' || form.fee == 0 ? 0 : form.fee;
    form.exitTime = null;
    form.role = 'guest';
    if (form.name != "" && form.name != null) {
      this.afs.collection("guest").add(form).then(user => {
        this.afs.collection("transactions").add({
          uid: user.id,
          name: form.name,
          email: form.email,
          RFID: '',
          transactionDate: new Date(),
          stripeToken: '',
          paymentMode: 'guest',
          paymentType: 'credit',
          status: 'success',
          amount: form.fee,
          openingBalance: 0,
          closingBalance: form.fee,
          clubId: form.clubId,
          sessionId: this.sessionsData.length > 0 ? this.sessionsData[0].id : '',
          details: this.sessionsData.length > 0 ? (form.name + " guest enter in club") : (form.name + " guest enter in club")
        }).then(log => {
          this.toastService.success('Guest entry approved', 'Success');
          this.soundSuccess();
          this.modalService.dismissAll();
          this.guestFormGroup.reset();
        })
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });
    } else
      this.toastService.error('Name is required', 'Error');
  }

  exitGuest(guest) {
    this.afs.collection("guest").doc(guest.id).update({
      type: 'exit',
      exitTime: new Date()
    }).then(rec => {
      this.toastService.warning(guest.name, 'Exit');
      this.soundExit();
      this.activeOtherRolesData = this.activeOtherRolesData.filter(a => a.id != guest.id);
    });
  }

  showEntryMsg(user) {
    let msg = '';
    if (user.imageUrl != null)
      msg = '<img src="' + user.imageUrl + '" width="80px" height="60px">';
    else
      msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px" >';
    this.toastService.success(msg, 'Welcome ' + user.firstName + ' ' + user.lastName, {
      enableHtml: true,
      closeButton: true,
      timeOut: 5000
    });
    let warningMsg = '';
    this.soundSuccess();
    warningMsg = "Details " + (user.accountDetailsAddedDate == null || user.medicalDetailsAddedDate == null || user.membershipDetailsAddedDate == null ? "Incompleted" : "Completed");
    if (user.accountDetailsAddedDate == null || user.medicalDetailsAddedDate == null || user.membershipDetailsAddedDate == null)
      this.toastService.info(warningMsg, 'Warning');
    let mc = user.mediCalled ? user.mediCalled : false;
    let me = user.medical ? user.medical : false;
    if ((mc && me) || me) {
      this.toastService.error('Red Wristband', '', {
        timeOut: 5000
      });
    } else {
      this.toastService.success('Green Wristband', '', {
        timeOut: 5000
      });
    }
    if (user.medicalDetailsAddedDate != null) {
      this.afs.collection('medicalHistory', ref => ref.where('userId', '==', user.uid)).get()
        .subscribe(snapshot => {
          if (!snapshot.empty) {
            snapshot.forEach(doc => {
              let medData = doc.data();
              let problems: string[] = [];
              if (medData.diseaseDetails.diabetes == "Yes" || medData.diseaseDetails.highBloodPressure == "Yes" || medData.diseaseDetails.chestLungProblems == "Yes" ||
                medData.diseaseDetails.heartProblems == "Yes" || medData.diseaseDetails.asthma == "Yes" || medData.diseaseDetails.epilepsy == "Yes" || medData.diseaseDetails.faintingDizziness == "Yes") {
                if (medData.diseaseDetails.diabetes == "Yes") problems.push('Diabetes');
                if (medData.diseaseDetails.highBloodPressure == "Yes") problems.push('High Blood Pressure');
                if (medData.diseaseDetails.chestLungProblems == "Yes") problems.push('Chest Lung Problems');
                if (medData.diseaseDetails.heartProblems == "Yes") problems.push('Heart Problems');
                if (medData.diseaseDetails.asthma == "Yes") problems.push('Asthma');
                if (medData.diseaseDetails.epilepsy == "Yes") problems.push('Epilepsy');
                if (medData.diseaseDetails.faintingDizziness == "Yes") problems.push('Fainting Dizziness');
                this.toastService.error(problems.toString(), "Medical Conditions", {
                  closeButton: true,
                  disableTimeOut: true
                });
              }
            });
          }
        });
    }
    this.afs.collection('membership', ref => ref.where('userId', '==', user.uid)).get()
      .subscribe(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            let memData = doc.data();
            if (!memData.photography.consent) {
              this.toastService.info("NO PHOTO", "", {
                closeButton: true,
                disableTimeOut: true
              });
            }
          });
        }
      });
  }

  showExitMsg(user) {
    // let msg = '';
    // if (user.imageUrl != null)
    //   msg = '<img src="' + user.imageUrl + '" width="80px" height="60px" >';
    // else
    //   msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px" >';
    this.toastService.warning('', 'Exit ' + user.firstName + ' ' + user.lastName, {
      enableHtml: true,
      closeButton: true,
      timeOut: 5000
    });
    this.soundExit();
  }

  activateUser(userId) {
    this.afs.collection("users").doc(userId).update({
      isActive: true
    });
    this.getSessionWiseActiveUsers();
  }

  deActivateUser(userId) {
    this.afs.collection("users").doc(userId).update({
      isActive: false
    });
    this.getSessionWiseActiveUsers();
  }

  timeout() {
    this.isDisabled = false;
    setTimeout(() => {
      this.userName = '';
      this.userImage = '';
      this.isLoggedIn = false;
      this.paymentMsg = ""
    }, 3000);
  }

  soundSuccess() {
    let audio = new Audio();
    audio.src = "../../assets/audio/success.wav";
    audio.load();
    audio.play();
  }

  soundError() {
    let audio = new Audio();
    audio.src = "../../assets/audio/error.wav";
    audio.load();
    audio.play();
  }

  soundExit() {
    let audio = new Audio();
    audio.src = "../../assets/audio/exit.wav";
    audio.load();
    audio.play();
  }

  soundPinch() {
    let audio = new Audio();
    audio.src = "https://res.cloudinary.com/dxfq3iotg/video/upload/v1557233563/warning.mp3";
    audio.load();
    audio.play();
  }

  async checkUserDetails(data) {
    data.checkMedical = await this.checkForMedical(data.userData);
    data.checkPhoto = await this.checkForPhoto(data.userData);
    this.activeUsersData.push(data);
  }

  checkForMedical(user) {

    return new Promise((resolve, reject) => {
      if (user.medicalDetailsAddedDate != null) {
        this.afs.collection('medicalHistory', ref => ref.where('userId', '==', user.uid).limit(1)).get()
          .subscribe(snapshot => {
            if (snapshot.empty) resolve("");
            else {
              snapshot.forEach(doc => {
                let medData = doc.data();
                let problems: string[] = [];
                if (medData.diseaseDetails.diabetes == "Yes" || medData.diseaseDetails.highBloodPressure == "Yes" || medData.diseaseDetails.chestLungProblems == "Yes" ||
                  medData.diseaseDetails.heartProblems == "Yes" || medData.diseaseDetails.asthma == "Yes" || medData.diseaseDetails.epilepsy == "Yes" || medData.diseaseDetails.faintingDizziness == "Yes") {
                  if (medData.diseaseDetails.diabetes == "Yes") problems.push('Diabetes');
                  if (medData.diseaseDetails.highBloodPressure == "Yes") problems.push('High Blood Pressure');
                  if (medData.diseaseDetails.chestLungProblems == "Yes") problems.push('Chest Lung Problems');
                  if (medData.diseaseDetails.heartProblems == "Yes") problems.push('Heart Problems');
                  if (medData.diseaseDetails.asthma == "Yes") problems.push('Asthma');
                  if (medData.diseaseDetails.epilepsy == "Yes") problems.push('Epilepsy');
                  if (medData.diseaseDetails.faintingDizziness == "Yes") problems.push('Fainting Dizziness');
                  return resolve(problems.toString());
                } else resolve("");
              });
            }
          })
      } else resolve("");
    });
  }

  checkForPhoto(user) {
    return new Promise((resolve, reject) => {
      this.afs.collection('membership', ref => ref.where('userId', '==', user.uid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) resolve("");
          else {
            snapshot.forEach(doc => {
              let memData = doc.data();
              if (!memData.photography.consent) {
                resolve("No Photo");
              } else resolve("");
            });
          }
        });
    });
  }
}
