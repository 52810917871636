import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductTags } from '../../product.model';

declare var $: any;

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  @Input() tagsFilters: ProductTags[] = [];
  @Output() tagFilters: EventEmitter<ProductTags[]> = new EventEmitter<ProductTags[]>();
  public checkedTagsArray: any[] = [];

  constructor() { }

  checkedFilter(event) {
    let index = this.checkedTagsArray.indexOf(event.target.value);
    if (event.target.checked)
      this.checkedTagsArray.push(event.target.value);
    else
      this.checkedTagsArray.splice(index, 1);
  }

  ngOnInit() {
    this.tagFilters.emit(this.checkedTagsArray);
  }



}
