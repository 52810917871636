import { Component, OnInit, Inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from './account.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { Jsonp } from '@angular/http';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [AccountService]
})
export class AccountComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  consent = false;
  gender = ['Male', 'Female'];
  meds = ['Yes', 'No'];
  userData = [];
  docId: any;
  newsLetter = true;
  selectedImage: any = null;
  image: any = null;
  uploadedImage: any;
  clubName = "";
  isFillingType = "";
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  options = {
    componentRestrictions: {
      country: ["UK"]
    }
  }

  constructor(private _formBuilder: FormBuilder, public dialog: MatDialog,
    @Inject(AngularFireStorage) private storage: AngularFireStorage, private jsonp: Jsonp,
    private service: AccountService, public toastService: ToastrService, private firestore: AngularFirestore) {
    this.firstFormGroup = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dob: ['', Validators.required],
      sex: ['', Validators.required],
      address: ['', Validators.required],
      postcode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      homeTelephoneNo: [''],
      mobileNo: ['', Validators.required],
      imageUrl: [''],
      volunteer: ['']
    });
    this.secondFormGroup = this._formBuilder.group({
      emergencyContactName: ['', Validators.required],
      emergencyRelationship: ['', Validators.required],
      emergencyContactAddress: ['', Validators.required],
      emergencyContactNo: ['', Validators.required]
    });
    this.clubName = localStorage.getItem('clubName');
  }

  ngOnInit() {
    this.getUserAccount();
  }

  addressChange(address, type) {
    if (type == 'current') {
      this.firstFormGroup.controls['address'].setValue(address.formatted_address);
      address.address_components.forEach(e => {
        if (e.types[0] == "postal_code") {
          this.firstFormGroup.controls['postcode'].setValue(e.long_name);
        }
      });
    } else if (type == 'emergency') {
      this.secondFormGroup.controls['emergencyContactAddress'].setValue(address.formatted_address);
    }
  }
  
  onAutocompleteSelected(result) {
    console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location) {
    console.log('onLocationSelected: ', location);
  }

  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.image = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.selectedImage = event.target.result;
      }
    }
  }

  save(accountId) {
    var name = this.image.name;
    let user = JSON.parse(localStorage.getItem('user'));
    const fileRef = this.storage.ref(user.uid);
    this.storage.upload(user.uid, this.image).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          this.firestore.doc('users/' + user.uid).update({
            imageUrl: url
          })
          this.firestore.doc('account/' + accountId).update({
            "personalDetails.imageUrl": url
          })
        })
      })
    ).subscribe();
  }

  getUserAccount() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.isFillingType = user.fillingType;
    if (user) {
      this.firestore.collection('account', ref => ref.where('userId', '==', user.uid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.firstFormGroup.setValue({
              firstName: this.userData.length > 0 ? this.userData[0].personalDetails.firstName : user.firstName,
              lastName: this.userData.length > 0 ? this.userData[0].personalDetails.lastName : user.lastName,
              dob: this.userData.length > 0 ? new Date(this.userData[0].personalDetails.dob.seconds * 1000) : '',
              sex: this.userData.length > 0 ? this.userData[0].personalDetails.sex : '',
              address: this.userData.length > 0 ? this.userData[0].personalDetails.address : '',
              postcode: this.userData.length > 0 ? this.userData[0].personalDetails.postcode : '',
              email: this.userData.length > 0 ? this.userData[0].personalDetails.email : user.email,
              homeTelephoneNo: this.userData.length > 0 ? this.userData[0].personalDetails.homeTelephoneNo : '',
              mobileNo: this.userData.length > 0 ? this.userData[0].personalDetails.mobileNo : (user.phoneNo ? user.phoneNo : ''),
              imageUrl: '',
              volunteer: this.userData.length > 0 ? (this.userData[0].personalDetails.volunteer ? this.userData[0].personalDetails.volunteer : '') : ''
            });
          } else {
            snapshot.forEach(doc => {
              this.docId = doc.id;
              this.userData.push(doc.data())
              this.uploadedImage = this.userData.length > 0 ? this.userData[0].personalDetails.imageUrl : '',
                this.firstFormGroup.setValue({
                  firstName: this.userData.length > 0 ? this.userData[0].personalDetails.firstName : user.firstName,
                  lastName: this.userData.length > 0 ? this.userData[0].personalDetails.lastName : user.lastName,
                  dob: this.userData.length > 0 ? new Date(this.userData[0].personalDetails.dob.seconds * 1000) : '',
                  sex: this.userData.length > 0 ? this.userData[0].personalDetails.sex : '',
                  address: this.userData.length > 0 ? this.userData[0].personalDetails.address : '',
                  postcode: this.userData.length > 0 ? this.userData[0].personalDetails.postcode : '',
                  email: this.userData.length > 0 ? this.userData[0].personalDetails.email : user.email,
                  homeTelephoneNo: this.userData.length > 0 ? this.userData[0].personalDetails.homeTelephoneNo : '',
                  mobileNo: this.userData.length > 0 ? this.userData[0].personalDetails.mobileNo : '',
                  imageUrl: null,
                  volunteer: this.userData.length > 0 ? (this.userData[0].personalDetails.volunteer ? this.userData[0].personalDetails.volunteer : '') : ''
                });
              this.secondFormGroup.setValue({
                emergencyContactName: this.userData.length > 0 ? this.userData[0].emergencyDetails.emergencyContactName : '',
                emergencyRelationship: this.userData.length > 0 ? this.userData[0].emergencyDetails.emergencyRelationship : '',
                emergencyContactAddress: this.userData.length > 0 ? this.userData[0].emergencyDetails.emergencyContactAddress : '',
                emergencyContactNo: this.userData.length > 0 ? this.userData[0].emergencyDetails.emergencyContactNo : ''
              });

            });
          }
        });
    }
  }

  submit() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.email != null && user.email != '') {
      if (this.userData.length == 0) {
        this.service.AddUserAccount(this.firstFormGroup.value, this.secondFormGroup.value)
          .then(
            res => {
              this.getUserAccount();
              if (this.image != null)
                this.save(res.id)
              if (this.newsLetter) {
                var url = 'https://boxtec.us19.list-manage.com/subscribe/post-json?u=8f0d97c0456c9a2fcba8447a6&id=09380827ad&subscribe=Subscribe&EMAIL=' + user.email + '&c=JSONP_CALLBACK';
                this.jsonp.request(url, { method: 'Get' })
                  .subscribe((res) => {
                    let data = res.json();
                  });

              }
              this.toastService.success('Details successfully saved!', 'Success');
              this.parentFun.emit();
            }
          )
      } else {
        this.firstFormGroup.value.imageUrl = this.uploadedImage;
        this.service.UpdateUserAccount(this.firstFormGroup.value, this.secondFormGroup.value, this.docId)
          .then(
            res => {
              if (this.image != null)
                this.save(this.docId)
              this.toastService.success('Details successfully updated!', 'Success');
              this.parentFun.emit();
            }
          )
      }
    } else {
      if (this.userData.length == 0) {
        this.service.AddUserAccount(this.firstFormGroup.value, this.secondFormGroup.value)
          .then(
            res => {
              this.getUserAccount();
              if (this.image != null)
                this.save(res.id)
              if (this.newsLetter) {
                this.firestore.doc('users/' + user.uid).update({
                  email: this.firstFormGroup.value.email,
                })
                var url = 'https://boxtec.us19.list-manage.com/subscribe/post-json?u=8f0d97c0456c9a2fcba8447a6&id=09380827ad&subscribe=Subscribe&EMAIL=' + this.firstFormGroup.value.email + '&c=JSONP_CALLBACK';
                this.jsonp.request(url, { method: 'Get' })
                  .subscribe((res) => {
                    let data = res.json();
                  });
              }
              this.toastService.success('Details successfully saved!', 'Success');
              this.parentFun.emit();
            }
          )
      } else {
        this.firstFormGroup.value.imageUrl = this.uploadedImage;
        this.service.UpdateUserAccount(this.firstFormGroup.value, this.secondFormGroup.value, this.docId)
          .then(
            res => {
              this.firestore.doc('users/' + user.uid).update({
                email: this.firstFormGroup.value.email,
              })
              if (this.image != null)
                this.save(this.docId)
              this.toastService.success('Details successfully updated!', 'Success');
              this.parentFun.emit();
            }
          )
      }
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogCodeOfConduct);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'dialog-code',
  templateUrl: 'dialog.html',
})
export class DialogCodeOfConduct { }