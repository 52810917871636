import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { EmailService } from '../../shared/service/email.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  providers: [EmailService]
})
export class BookingComponent implements OnInit {
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  data: any[] = [];
  appointmentData: any[] = [];
  cols = [];
  loading: boolean;
  adminData: any;
  clubName = "";
  clubData: any;
  pricingData: any;
  isDisabled = false;
  sessionPrice = 0;
  clubURL = "";
  warningMsg = false;
  googleUrl: any; icalUrl: any; outlookUrl: any; yahooUrl: any;
  tabDayData = [];
  tabWeekData = [];
  tabMonthData = [];
  finalData = [];
  filter = [{ lable: "Filter by Date", value: "day" }, { lable: "Filter by Week", value: "week" },
  { lable: "Filter by Month", value: "month" }];
  selectedFilterType = "week";
  selectedFilterSubType = "";

  constructor(public firestore: AngularFirestore, private _snackBar: MatSnackBar, private emailService: EmailService,
    public route: ActivatedRoute, public router: Router,
    public toastService: ToastrService, private modalService: NgbModal, private sanitization: DomSanitizer) {
    this.adminData = JSON.parse(sessionStorage.getItem('user'));
    this.route.params.subscribe(params => {
      if (params['clubName']) {
        let clubname = params['clubName'];
        this.clubURL = clubname;
        if (clubname) {
          this.clubName = clubname.replace(/-/g, ' ');
          if (this.adminData) {
            this.firestore.collection("clubs").doc(this.adminData.clubId).get()
              .subscribe(snapshot => {
                this.clubData = snapshot.data();
              });
            this.getAppointmentData();
            this.getNotificationData(this.adminData.clubId);
            if (this.adminData.roles != 'clubUser' && this.adminData.roles != 'coach' && this.adminData.roles != 'one2one')
              this.isDisabled = true;
          } else {
            this.data = []; this.finalData = [];
            this.loading = true;
            if (this.clubName) {
              this.firestore.collection("clubs").get()
                .subscribe(snapshot => {
                  snapshot.forEach(e => {
                    let data = e.data();
                    data.id = e.id;
                    if (data.clubDetails.name.toString().toLowerCase() == this.clubName.toString().toLowerCase()) {
                      this.clubData = data;
                      this.getNotificationData(data.id);
                      this.firestore.collection('sessions').get()
                        .subscribe(snapshot => {
                          snapshot.forEach(doc => {
                            let sData = doc.data();
                            if (sData.clubId != "I9Z6nLJJt1Wm1uPtaYPk") {
                              if (this.clubName) {
                                if (sData.clubId == this.clubData.id) {
                                  sData.startDate = new Date(sData.startDate.seconds * 1000);
                                  sData.endDate = new Date(sData.endDate.seconds * 1000);
                                  sData.id = doc.id;
                                  if (new Date(sData.startDate) >= new Date()) {
                                    sData.booked = false;
                                    sData.bookingDate = '';
                                    sData.filterDay = sData.startDate.getDate() + ' ' + this.monthNames[sData.startDate.getMonth()].toString().substring(0, 3);
                                    sData.filterMonth = this.monthNames[sData.startDate.getMonth()];
                                    sData.filterWeek = this.getWeekDays(sData.startDate);
                                    this.data.push(sData);
                                    this.finalData.push(sData);
                                  }
                                }
                              }
                            }
                          });
                          this.data = this.data.sort(function (a, b) { return a.startDate - b.startDate });
                          this.finalData = this.finalData.sort(function (a, b) { return a.startDate - b.startDate });
                          this.getDateLabel();
                          this.selectedFilterSubType = this.finalData[0].filterWeek;
                          this.data = this.finalData.filter(a => a.filterWeek == this.selectedFilterSubType);
                          this.loading = false;
                        });
                    }
                  });
                });
            } else {
              this.firestore.collection('sessions').get()
                .subscribe(snapshot => {
                  snapshot.forEach(doc => {
                    let sData = doc.data();
                    if (sData.clubId != "I9Z6nLJJt1Wm1uPtaYPk") {
                      sData.startDate = new Date(sData.startDate.seconds * 1000);
                      sData.endDate = new Date(sData.endDate.seconds * 1000);
                      sData.id = doc.id;
                      if (new Date(sData.startDate) >= new Date()) {
                        sData.booked = false;
                        sData.bookingDate = '';
                        sData.filterDay = sData.startDate.getDate() + ' ' + this.monthNames[sData.startDate.getMonth()].toString().substring(0, 3);
                        sData.filterMonth = this.monthNames[sData.startDate.getMonth()];
                        sData.filterWeek = this.getWeekDays(sData.startDate);
                        this.data.push(sData);
                        this.finalData.push(sData);
                      }
                    }
                  });
                  this.data = this.data.sort(function (a, b) { return a.startDate - b.startDate });
                  this.finalData = this.finalData.sort(function (a, b) { return a.startDate - b.startDate });
                  this.getDateLabel();
                  this.selectedFilterSubType = this.finalData[0].filterWeek;
                  this.data = this.finalData.filter(a => a.filterWeek == this.selectedFilterSubType);
                  this.loading = false;
                });
            }
          }
        }
      } else {
        this.firestore.collection('sessions').get()
          .subscribe(snapshot => {
            snapshot.forEach(doc => {
              let sData = doc.data();
              if (sData.clubId != "I9Z6nLJJt1Wm1uPtaYPk") {
                sData.startDate = new Date(sData.startDate.seconds * 1000);
                sData.endDate = new Date(sData.endDate.seconds * 1000);
                sData.id = doc.id;
                if (new Date(sData.startDate) >= new Date()) {
                  sData.booked = false;
                  sData.bookingDate = '';
                  sData.filterDay = sData.startDate.getDate() + ' ' + this.monthNames[sData.startDate.getMonth()].toString().substring(0, 3);
                  sData.filterMonth = this.monthNames[sData.startDate.getMonth()];
                  sData.filterWeek = this.getWeekDays(sData.startDate);
                  this.data.push(sData);
                  this.finalData.push(sData);
                }
              }
            });
            this.data = this.data.sort(function (a, b) { return a.startDate - b.startDate });
            this.finalData = this.finalData.sort(function (a, b) { return a.startDate - b.startDate });
            this.getDateLabel();
            this.selectedFilterSubType = this.finalData[0].filterWeek;
            this.data = this.finalData.filter(a => a.filterWeek == this.selectedFilterSubType);
            this.loading = false;
          });
      }
    });
  }

  getWeekDays(d) {
    let date = new Date(d);
    var firstday = new Date(date.setDate(date.getDate() - date.getDay()));
    var lastday = new Date(date.setDate(date.getDate() - date.getDay() + 6));
    return firstday.getDate() + ' ' + this.monthNames[firstday.getMonth()].toString().substring(0, 3) +
      ' - ' + lastday.getDate() + ' ' + this.monthNames[lastday.getMonth()].toString().substring(0, 3);
  }

  filterData(type) {
    this.selectedFilterSubType = '';
    this.selectedFilterType = type;
    this.data = this.finalData;
  }

  filterTypeData(type) {
    this.selectedFilterSubType = type;
    if (this.selectedFilterType == "day")
      this.data = type == '' ? this.finalData : this.finalData.filter(a => a.filterDay == type);
    else if (this.selectedFilterType == "week")
      this.data = type == '' ? this.finalData : this.finalData.filter(a => a.filterWeek == type);
    else if (this.selectedFilterType == "month")
      this.data = type == '' ? this.finalData : this.finalData.filter(a => a.filterMonth == type);
    else
      this.data = this.finalData;
  }

  ngOnInit(): void {
  }

  getDateLabel() {
    this.data.forEach(e => {
      if (this.tabDayData.indexOf(e.filterDay) > -1) { }
      else this.tabDayData.push(e.filterDay);
      if (this.tabMonthData.indexOf(e.filterMonth) > -1) { }
      else this.tabMonthData.push(e.filterMonth);
      if (this.tabWeekData.indexOf(e.filterWeek) > -1) { }
      else this.tabWeekData.push(e.filterWeek);
    });
  }

  getNotificationData(clubId) {
    this.firestore.collection('notifications', ref => ref.where('clubId', '==', clubId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.startDate = new Date(sData.startDate.seconds * 1000);
          sData.endDate = new Date(sData.endDate.seconds * 1000);
          sData.id = doc.id;
          if (new Date(sData.startDate) <= new Date() && new Date(sData.endDate) >= new Date()) {
            if (sData.role == 'all')
              this.openSnackBar(sData.notification);
            if (sData.role == this.adminData.roles)
              this.openSnackBar(sData.notification);
          }
        });
      });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Cancel", {
      duration: 20000,
    });
  }

  getAppointmentData() {
    this.appointmentData = [];
    this.firestore.collection('appointments', ref => ref.where('uid', '==', this.adminData.uid)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.startDate = new Date(sData.startDate.seconds * 1000);
          sData.endDate = new Date(sData.endDate.seconds * 1000);
          sData.id = doc.id;
          if (new Date(sData.startDate) >= new Date())
            this.appointmentData.push(sData);
        });
        this.getEventData();
      });
  }

  getEventData() {
    this.data = [];
    this.finalData = [];
    this.loading = true;
    this.firestore.collection('sessions', ref => ref.where('clubId', '==', this.adminData.clubId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.startDate = new Date(sData.startDate.seconds * 1000);
          sData.endDate = new Date(sData.endDate.seconds * 1000);
          sData.id = doc.id;
          if (new Date(sData.startDate) >= new Date()) {
            let fData = this.appointmentData.filter(a => a.sessionId == sData.id);
            sData.booked = fData.length > 0 ? true : false;
            if (new Date(sData.startDate).getDate() == new Date().getDate() &&
              new Date(sData.startDate).getMonth() == new Date().getMonth() &&
              new Date(sData.startDate).getFullYear() == new Date().getFullYear())
              sData.cancel = false;
            else {
              sData.cancel = fData.length > 0 ? true : false;
              sData.appointment = fData.length > 0 ? fData[0] : '';
            }
            sData.bookingDate = fData.length > 0 ? new Date(fData[0].bookingDate.seconds * 1000) : '';
            sData.filterDay = sData.startDate.getDate() + ' ' + this.monthNames[sData.startDate.getMonth()].toString().substring(0, 3);
            sData.filterMonth = this.monthNames[sData.startDate.getMonth()];
            sData.filterWeek = this.getWeekDays(sData.startDate);
            this.data.push(sData);
            this.finalData.push(sData);
          }
        });
        this.data = this.data.sort(function (a, b) { return a.startDate - b.startDate });
        this.finalData = this.finalData.sort(function (a, b) { return a.startDate - b.startDate });
        this.getDateLabel();
        this.selectedFilterSubType = this.finalData[0].filterWeek;
        this.data = this.finalData.filter(a => a.filterWeek == this.selectedFilterSubType);
        this.loading = false;
      });
  }

  filterSession() {
    return this.clubData ? this.data.filter(a => a.clubId == this.clubData.id) : this.data;
  }

  bookNow(event) {
    if (this.adminData) {
      if (this.adminData.RFID != '' && this.adminData.group != '') {
        if (confirm("Are you sure you want to book this session?")) {
          this.isDisabled = true;
          this.firestore.collection('users', ref => ref.where('uid', '==', this.adminData.uid).limit(1)).get()
            .subscribe(snapshot => {
              snapshot.forEach(doc => {
                let sData = doc.data();
                if (sData.balance > 0) {
                  this.sessionPrice = event.amount ? event.amount : this.clubData.planDetails.planDetails.filter(a => a.plan == sData.group)[0].price;
                  this.addLogs(sData, event);
                } else {
                  this.toastService.error("You do not have enough credit. To add money to your account please go to the wallet and top up.", 'Error');
                  this.isDisabled = false;
                }
              });
            });
        }
      } else {
        this.toastService.error("You are not authorised for booking. Please contact administrator.", 'Error');
      }
    } else
      this.router.navigate(["/login"]);
  }

  addLogs(data, eventData) {
    if (data.balance < this.sessionPrice) {
      this.toastService.error("Not enough credit in your wallet. Please recharge it.", 'Error');
      this.isDisabled = false;
    }
    else {
      this.firestore.collection("appointments").add({
        uid: data.uid,
        sessionId: eventData.id,
        startDate: eventData.startDate,
        endDate: eventData.endDate,
        eventName: eventData.name,
        clubId: data.clubId,
        type: 'appointment',
        bookingDate: new Date(),
      }).then(result => {
        this.firestore.collection("transactions").add({
          uid: data.uid,
          name: data.firstName + ' ' + data.lastName,
          email: data.email,
          RFID: data.RFID,
          transactionDate: new Date(),
          stripeToken: '',
          paymentMode: 'wallet',
          paymentType: 'debit',
          status: 'success',
          amount: this.sessionPrice,
          openingBalance: data.balance,
          closingBalance: data.balance - this.sessionPrice,
          clubId: data.clubId,
          sessionId: eventData.id,
          orderId: '',
          details: "Session booked"
        }).then(result => {
          this.firestore.collection("users").doc(data.uid).update({
            balance: data.balance - this.sessionPrice
          }).then(user1 => {
            data.balance = data.balance - this.sessionPrice;

            this.toastService.success("Booking Done! Your Payment Was Successfully Received.", 'Welcome');
            if (data.accountDetailsAddedDate == null || data.medicalDetailsAddedDate == null || data.membershipDetailsAddedDate == null) {
              this.warningMsg = true;
            }
            this.emailService.postBookingSuccessMail(data.email, data.firstName + ' ' + data.lastName, eventData.name,
              this.getDateTime(eventData.startDate) + ' - ' + this.getDateTime(eventData.endDate), eventData.location, this.getDateTime(new Date()));
            this.emailService.postSendDebitMail(data.email, data.balance - this.sessionPrice, this.sessionPrice, data.firstName + ' ' + data.lastName);

            this.firestore.collection("sessions").doc(eventData.id).set({
              bookedSlots: eventData.bookedSlots != null ? eventData.bookedSlots + 1 : 1
            }, { merge: true });
            this.isDisabled = false;
            this.getAppointmentData();
          }).catch(err => {
            this.toastService.error(err, 'Error');
          });
        }).catch(err => {
          this.toastService.error(err, 'Error');
        });
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });

    }
  }

  cancelBooking(event) {
    if (confirm("Are you sure you want to cancel this session?")) {
      this.firestore.collection("appointments").doc(event.appointment.id).delete().then(data => {
        this.firestore.collection('users', ref => ref.where('uid', '==', this.adminData.uid).limit(1)).get()
          .subscribe(snapshot => {
            snapshot.forEach(doc => {
              let sData = doc.data();
              this.firestore.collection("transactions").add({
                uid: sData.uid,
                name: sData.firstName + ' ' + sData.lastName,
                email: sData.email,
                RFID: sData.RFID,
                transactionDate: new Date(),
                stripeToken: '',
                paymentMode: 'wallet',
                paymentType: 'credit',
                status: 'success',
                amount: event.amount,
                openingBalance: sData.balance,
                closingBalance: sData.balance + event.amount,
                clubId: sData.clubId,
                sessionId: event.appointment.sessionId,
                orderId: '',
                details: "Session booking cancelled"
              }).then(log => {
                this.firestore.collection("users").doc(sData.uid).update({
                  balance: sData.balance + event.amount
                })
                this.firestore.collection("sessions").doc(event.id).set({
                  bookedSlots: event.bookedSlots != null ? event.bookedSlots - 1 : 1
                }, { merge: true });
                this.emailService.postBookingCancelMail(sData.email, sData.firstName + ' ' + sData.lastName, event.name,
                  this.getDateTime(event.startDate) + ' - ' + this.getDateTime(event.endDate), event.location, this.getDateTime(new Date()));
                this.emailService.postSendCreditMail(sData.email, sData.balance + event.amount, event.amount, sData.firstName + ' ' + sData.lastName);
                this.toastService.success("Booking cancelled! Your refund successfully processed.", 'Success');
                this.getAppointmentData();
              }).catch(err => {
                this.toastService.error(err, 'Error');
              });
            });
          });
      }).catch(err => {
        this.toastService.error(err, 'Error');
      });
    }
  }

  getDateTime(date: Date) {
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  addToCalendar(content, item) {
    let singleEventArgs = {
      title: item.name,
      start: item.startDate,
      end: item.endDate,
      location: item.location,
      description: 'Event description',
      isAllDay: false,
    };
    let singleEvent = new (window as any).Add2Calendar(singleEventArgs);
    // to get actual url
    this.googleUrl = singleEvent.getGoogleUrl();
    this.icalUrl = this.sanitization.bypassSecurityTrustResourceUrl(singleEvent.getICalUrl());
    this.outlookUrl = this.sanitization.bypassSecurityTrustResourceUrl(singleEvent.getOutlookUrl());
    this.yahooUrl = singleEvent.getYahooUrl();

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
}
