import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-club-events',
  templateUrl: './club-events.component.html',
  styleUrls: ['./club-events.component.scss']
})
export class ClubEventsComponent implements OnInit {
  data: any[] = [];
  cols = [];
  loading: boolean;
  boxingUrl = "assets/images/logo/boxing.png";
  userData: any;
  bookingForm: FormGroup;
  totalAmount = 0;
  selectedEvent: any;
  currentUrl = '';

  constructor(public firestore: AngularFirestore, private http: HttpClient, private _formBuilder: FormBuilder,
    public toastService: ToastrService, private route: ActivatedRoute, private modalService: NgbModal) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.bookingForm = this._formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      contact: ['', Validators.required],
    });
    this.getEventData();

  }

  async getEventData() {
    this.data = [];
    this.loading = true;
    (await this.firestore.collection('club_events', ref => ref.where('clubId', '==', localStorage.getItem('clubId'))).get().toPromise()).forEach(e => {
      let sData = e.data();
      sData.startDate = new Date(sData.startDate.seconds * 1000);
      sData.endDate = new Date(sData.endDate.seconds * 1000)
      sData.id = e.id;
      if (new Date(sData.startDate) >= new Date()) {
        sData.active = true;
        this.data.push(sData);
      }
    });
    this.loading = false;
  }

  openModel(content, eventData) {
    let ticket = eventData.tickets.filter(a => a.quantity > 0);
    if (ticket.length == 0)
      return this.toastService.error('Choose ticket', 'Error');
    else {
      this.totalAmount = 0;
      ticket.forEach(e => {
        this.totalAmount += Number(e.quantity) * Number(e.amount);
      });
      this.selectedEvent = eventData;
      this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(`Dismissed ${this.getDismissReason(reason)}`);
      });
    }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  bookTicket() {
    if (this.totalAmount <= 0)
      return this.toastService.error('Choose ticket', 'Error');
    else {
      let form = this.bookingForm.value;
      if (form.name == '')
        this.toastService.error('Your name is required', 'Error');
      else if (form.email == '')
        this.toastService.error('Your email is required', 'Error');
      else if (form.contact == '')
        this.toastService.error('Your contact is required', 'Error');
      else {
        let checkOutId = this.randomString(20);
        this.currentUrl = `https://app.pamit.co.uk/` + this.route.snapshot.paramMap.get('clubName');
        let body = {
          "price": this.totalAmount * 100,
          "clubUrl": this.route.snapshot.paramMap.get('clubName'),
          "checkoutId": checkOutId,
          "customerEmail": form.email,
        }
        let bookingId = 'TCK' + this.randomStringTicket(7);
        let bookingInfo = {
          email: form.email,
          name: form.name,
          contact: form.contact,
          transactionId: null,
          amount: this.totalAmount,
          status: "created",
          userId: this.userData != null ? this.userData.uid : '',
          clubId: localStorage.getItem('clubId'),
          eventId: this.selectedEvent.id,
          bookingId: bookingId,
          tickets: this.selectedEvent.tickets,
          createdDate: new Date(),
          isUsed: false
        }
        this.http.post(environment.prodApiLink + "event/create-checkout-session", body).subscribe(response => {
          var res: any = response;
          if (res.error) {
            this.toastService.error(res.error.message, 'Error');
          } else {
            this.firestore.collection("payments").doc(checkOutId.toString()).set({
              payment_intent: res.payment_intent,
              checkoutId: checkOutId,
              transactionId: null,
              userId: this.userData != null ? this.userData.uid : '',
              status: "created",
              createdDate: new Date(),
              clubId: localStorage.getItem('clubId'),
              bookingId: bookingId,
              updatedDate: null
            }).then(resData => {
              this.firestore.collection("bookings").doc(checkOutId.toString()).set(bookingInfo).then(resData => {
                window.location.href = res.url;
              })
            })
          }
        });
      }
    }
  }

  randomStringTicket(length) {
    var result = '';
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  randomString(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
