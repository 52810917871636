<div class="img-wrapper text-center">
  <div class="front">
    <a>
      <img alt="" class="img-fluid" (click)="redirectUrl('/shop/product/' + products?.id)" routerLinkActive="active"
        [src]="products?.images[0]?.src" style="height:200px!important;"
        onerror="this.src='../../assets/images/no-image.png';">
    </a>
  </div>
</div>
<div class="product-detail">
  <a href="product-page(no-sidebar).html">
    <h6>{{products?.title}}</h6>
  </a>
  <h4>{{products?.price | currency:'GBP'}} <span class="avalibility text-success pt-0 float-right"
      *ngIf="counter <= products.stock">
      <span>In Stock</span></span>
    <span class="avalibility text-danger pt-0 float-right" *ngIf="counter > products.stock"><span>Out of
        Stock</span></span>
  </h4>
  <div class="qty-box mb-2">
    <div class="input-group" style="justify-content: left;">
      <span class="input-group-prepend">
        <button type="button" (click)="decrement()" class="btn quantity-left-minus" data-type="minus" data-field="">
          <i class="fa fa-chevron-left"></i>
        </button>
      </span>
      <input type="text" name="quantity" disabled class="form-control form-control-sm input-number" value="{{counter}}">
      <span class="input-group-prepend">
        <button type="button" (click)="increment()" class="btn quantity-right-plus" data-type="plus" data-field="">
          <i class="fa fa-chevron-right"></i>
        </button>
      </span>
    </div>
  </div>

  <a [class.disabled]="counter > products?.stock" (click)="buyNow(products, counter)"
    class="btn btn-default btn-block primary-btn radius-0 p-2">BUY
    NOW
  </a>
  <a (click)="redirectUrl('/shop/product/' + products?.id)"
    class="btn btn-block mt-2 btn-outline-info btn-sm radius-0 p-2">view
    detail</a>
</div>