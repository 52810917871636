import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedicalInfoService } from './medical-info.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-medical-info',
  templateUrl: './medical-info.component.html',
  styleUrls: ['./medical-info.component.scss'],
  providers: [MedicalInfoService]
})
export class MedicalInfoComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  gender = ['Male', 'Female'];
  meds = ['Yes', 'No'];
  userData = [];
  docId: any;
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  type = ['Amateur', 'Professional'];
  option = ['Yes', 'No'];
  options = {
    componentRestrictions: {
      country: ["UK"]
    }
  }

  constructor(private _formBuilder: FormBuilder, private service: MedicalInfoService, public toastService: ToastrService, private firestore: AngularFirestore) {
    this.firstFormGroup = this._formBuilder.group({
      doctorName: ['', Validators.required],
      doctorAddress: ['', Validators.required],
      doctorNo: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      visual: ['', Validators.required],
      hearing: ['', Validators.required],
      physical: ['', Validators.required],
      learning: ['', Validators.required],
      other: ['', Validators.required],
      details: ['']
    });
    this.thirdFormGroup = this._formBuilder.group({
      boxing: ['', Validators.required],
      martialArts: ['', Validators.required],
      football: ['', Validators.required],
      rugby: ['', Validators.required],
      hockey: ['', Validators.required],
      tennis: ['', Validators.required],
      other: ['', Validators.required],
      details: [''],
      regNo: [''],
      clubName: [''],
      previousSurname: [''],
      previousSports: [''],
      type: [''],
      isEnglandBoxing: [''],
      isOtherBoxing: [''],
      organisation: [''],
      win: [''],
      loss: [''],
      fullSemiContact: [''],
      englandBoxingEmail: ['']
    });
    this.fourthFormGroup = this._formBuilder.group({
      diabetes: ['', Validators.required],
      highBloodPressure: ['', Validators.required],
      heartProblems: ['', Validators.required],
      chestLungProblems: ['', Validators.required],
      asthma: ['', Validators.required],
      epilepsy: ['', Validators.required],
      faintingDizziness: ['', Validators.required],
      details: [''],
      medications: ['']
    });
    this.fifthFormGroup = this._formBuilder.group({
      medicalCondition: [''],
      currentTreatment: [''],
      currentMedication: [''],
      allergies: [''],
      details: [''],
      consent: ['', Validators.requiredTrue],
      parentsName: ['']
    });
  }

  ngOnInit() {
    this.getMedicalHistory();
  }

  addressChange(address) {
    this.firstFormGroup.controls['doctorAddress'].setValue(address.formatted_address);
  }

  getMedicalHistory() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.firestore.collection('medicalHistory', ref => ref.where('userId', '==', user.uid).limit(1)).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          this.docId = doc.id;
          this.userData.push(doc.data());
          this.firstFormGroup.setValue({
            doctorName: this.userData.length > 0 ? this.userData[0].doctorDetails.doctorName : '',
            doctorAddress: this.userData.length > 0 ? this.userData[0].doctorDetails.doctorAddress : '',
            doctorNo: this.userData.length > 0 ? this.userData[0].doctorDetails.doctorNo : ''
          });
          this.secondFormGroup.setValue({
            visual: this.userData.length > 0 ? this.userData[0].disability.visual : '',
            hearing: this.userData.length > 0 ? this.userData[0].disability.hearing : '',
            physical: this.userData.length > 0 ? this.userData[0].disability.physical : '',
            learning: this.userData.length > 0 ? this.userData[0].disability.learning : '',
            other: this.userData.length > 0 ? this.userData[0].disability.other : '',
            details: this.userData.length > 0 ? this.userData[0].disability.details : ''
          });
          this.thirdFormGroup.setValue({
            boxing: this.userData.length > 0 ? this.userData[0].sportingDetails.boxing : '',
            martialArts: this.userData.length > 0 ? this.userData[0].sportingDetails.martialArts : '',
            football: this.userData.length > 0 ? this.userData[0].sportingDetails.football : '',
            rugby: this.userData.length > 0 ? this.userData[0].sportingDetails.rugby : '',
            hockey: this.userData.length > 0 ? this.userData[0].sportingDetails.hockey : '',
            tennis: this.userData.length > 0 ? this.userData[0].sportingDetails.tennis : '',
            other: this.userData.length > 0 ? this.userData[0].sportingDetails.other : '',
            details: this.userData.length > 0 ? this.userData[0].sportingDetails.details : '',
            regNo: this.userData.length > 0 ? (this.userData[0].sportingDetails.regNo ? this.userData[0].sportingDetails.regNo : '') : '',
            clubName: this.userData.length > 0 ? (this.userData[0].sportingDetails.clubName ? this.userData[0].sportingDetails.clubName : '') : '',
            previousSurname: this.userData.length > 0 ? (this.userData[0].sportingDetails.previousSurname ? this.userData[0].sportingDetails.previousSurname : '') : '',
            previousSports: this.userData.length > 0 ? (this.userData[0].sportingDetails.previousSports ? this.userData[0].sportingDetails.previousSports : '') : '',
            type: this.userData.length > 0 ? (this.userData[0].sportingDetails.type ? this.userData[0].sportingDetails.type : '') : '',
            isEnglandBoxing: this.userData.length > 0 ? (this.userData[0].sportingDetails.isEnglandBoxing ? this.userData[0].sportingDetails.isEnglandBoxing : '') : '',
            isOtherBoxing: this.userData.length > 0 ? (this.userData[0].sportingDetails.isOtherBoxing ? this.userData[0].sportingDetails.isOtherBoxing : '') : '',
            organisation: this.userData.length > 0 ? (this.userData[0].sportingDetails.organisation ? this.userData[0].sportingDetails.organisation : '') : '',
            win: this.userData.length > 0 ? (this.userData[0].sportingDetails.win ? this.userData[0].sportingDetails.win : '') : '',
            loss: this.userData.length > 0 ? (this.userData[0].sportingDetails.loss ? this.userData[0].sportingDetails.loss : '') : '',
            fullSemiContact: this.userData.length > 0 ? (this.userData[0].sportingDetails.fullSemiContact ? this.userData[0].sportingDetails.fullSemiContact : '') : '',
            englandBoxingEmail: this.userData.length > 0 ? (this.userData[0].sportingDetails.englandBoxingEmail ? this.userData[0].sportingDetails.englandBoxingEmail : '') : '',
          });
          this.fourthFormGroup.setValue({
            diabetes: this.userData.length > 0 ? this.userData[0].diseaseDetails.diabetes : '',
            highBloodPressure: this.userData.length > 0 ? this.userData[0].diseaseDetails.highBloodPressure : '',
            heartProblems: this.userData.length > 0 ? this.userData[0].diseaseDetails.heartProblems : '',
            chestLungProblems: this.userData.length > 0 ? this.userData[0].diseaseDetails.chestLungProblems : '',
            asthma: this.userData.length > 0 ? this.userData[0].diseaseDetails.asthma : '',
            epilepsy: this.userData.length > 0 ? this.userData[0].diseaseDetails.epilepsy : '',
            faintingDizziness: this.userData.length > 0 ? this.userData[0].diseaseDetails.faintingDizziness : '',
            details: this.userData.length > 0 ? this.userData[0].diseaseDetails.details : '',
            medications: this.userData.length > 0 ? this.userData[0].diseaseDetails.medications : ''
          });
          this.fifthFormGroup.setValue({
            medicalCondition: this.userData.length > 0 ? this.userData[0].medicalDetails.medicalCondition : '',
            currentTreatment: this.userData.length > 0 ? this.userData[0].medicalDetails.currentTreatment : '',
            currentMedication: this.userData.length > 0 ? this.userData[0].medicalDetails.currentMedication : '',
            allergies: this.userData.length > 0 ? this.userData[0].medicalDetails.allergies : '',
            details: this.userData.length > 0 ? this.userData[0].medicalDetails.details : '',
            consent: this.userData.length > 0 ? this.userData[0].medicalDetails.consent : '',
            parentsName: this.userData.length > 0 ? this.userData[0].medicalDetails.parentsName : ''
          });
        });
      });
  }

  submit() {
    if (this.userData.length == 0) {
      this.service.AddUserMedicalInfo(this.firstFormGroup.value, this.secondFormGroup.value, this.thirdFormGroup.value, this.fourthFormGroup.value, this.fifthFormGroup.value)
        .then(
          res => {
            this.getMedicalHistory();
            this.toastService.success('Details successfully saved!', 'Success');
            this.parentFun.emit();
          }
        )
    } else {
      this.service.UpdateUserMedicalInfo(this.firstFormGroup.value, this.secondFormGroup.value, this.thirdFormGroup.value, this.fourthFormGroup.value, this.fifthFormGroup.value, this.docId)
        .then(
          res => {
            this.toastService.success('Details successfully updated!', 'Success');
            this.parentFun.emit();
          }
        )
    }
  }

}
