<!--section offline start-->
<div class="bg-purple">
    <div class="section-404">
        <div class="stars" [ngStyle]="{'background-image': 'url(assets/images/404/overlay_stars.svg)'}">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-12">
                        <div class="central-body">
                            <h1>OFFLINE</h1>
                            <p class="text-404 text-white text-center">Looks like you're not connected to a network. Check your settings and try again.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="objects">
            <img class="object_rocket" src="assets/images/404/rocket.svg">
            <div class="earth-moon">
                <img class="object_earth" src="assets/images/404/earth.svg">
                <img class="object_moon" src="assets/images/404/moon.svg">
            </div>
            <div class="box_astronaut">
                <img class="object_astronaut" src="assets/images/404/astronaut.svg">
            </div>
        </div>
        <div class="glowing_stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
        </div>
    </div>
</div>
<!--section offline end-->