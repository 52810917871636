<div class="p-2 p-b-100">
    <div class="p-b-100 theme-card">
        <div *ngIf="isAccount && isMembership && isMedical" class="alert alert-info mb-2 text-center">
            Thanks For Filling In All Of Your Required Information. You Can View & Download Your Details Here.
            <a class="text-danger" (click)="openDialog()"><i class="fa fa-download fa-2x"></i></a>
        </div>
        <div *ngIf="!isAccount || !isMembership || !isMedical" class="alert alert-danger mb-2 text-center">
            Please fill in your membership details to proceed.
        </div>
        <p class="mt-3 mb-3">
            <ngb-progressbar type="{{type}}" [striped]="true" [animated]="true" [value]="progress"><b>{{progressStatus}}</b>
            </ngb-progressbar>
        </p>
        <p-accordion [(activeIndex)]="index">
            <p-accordionTab>
                <p-header>
                    <i class="fas fa-user"></i> ACCOUNT <i *ngIf="isAccount"
                        class="fas fa-check-circle text-success"></i>

                </p-header>
                <app-account (parentFun)="parentFun()"></app-account>
            </p-accordionTab>
            <p-accordionTab>
                <p-header>
                    <i class="fas fa-id-card"></i> MEMBERSHIP <i *ngIf="isMembership"
                        class="fas fa-check-circle text-success"></i>
                </p-header>
                <app-membership (parentFun)="parentFun()"></app-membership>
            </p-accordionTab>
            <p-accordionTab>
                <p-header>
                    <i class="fas fa-briefcase-medical"></i> MEDICAL HISTORY & SPORTING DETAILS <i *ngIf="isMedical"
                        class="fas fa-check-circle text-success"></i>
                </p-header>
                <app-medical-info (parentFun)="parentFun()"></app-medical-info>
            </p-accordionTab>
            <p-accordionTab>
                <p-header>
                    <i class="fas fa-plus"></i> ADDITIONAL <i *ngIf="isQuestion"
                        class="fas fa-check-circle text-success"></i>
                </p-header>
                <app-questionnaire (parentFun)="parentFun()"></app-questionnaire>
            </p-accordionTab>
            <p-accordionTab>
                <p-header>
                    <i class="fas fa-signature"></i> SIGNATURE <i *ngIf="isSignature"
                        class="fas fa-check-circle text-success"></i>
                </p-header>
                <app-digital-signature (parentFun)="parentFun()"></app-digital-signature>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>