<!-- section start -->
<section class="section-b-space" style="padding:25px 0px!important;">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <!-- Collection Sidebar -->
        <a class="overlay-sidebar" [class.overlay-sidebar-open]="productsService.filterBar"
          (click)="closeOverlay()"></a>
        <div class="col-sm-3 collection-filter" [class.filterBar]="productsService.filterBar">
          <div class="coll_sidebar_sticky">
            <div class="collection-filter-block">
              <app-categories [categoriesFilters]="category" (categoryFilters)="updateCategoryFilters($event)"
                *ngIf="category.length"></app-categories>
            </div>
            <div class="collection-filter-block">
              <app-brand [tagsFilters]="tags" (tagFilters)="updateTagFilters($event)" *ngIf="tags.length"></app-brand>
              <app-color [colorsFilters]="colors" (colorFilters)="updateColorFilters($event)" *ngIf="colors.length">
              </app-color>
              <app-price (priceFilters)="updatePriceFilters($event)"></app-price>
            </div>
          </div>
        </div>
        <!-- End Collection Sidebar -->
        <div class="collection-content col">
          <div class="page-main-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="collection-product-wrapper">
                    <!-- Filter Block Products -->
                    <div class="product-top-filter">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="col-xl-12">
                            <div class="filter-main-btn">
                              <span class="filter-btn btn btn-default primary-btn radius-0" (click)="toggleFilter()">
                                <i class="fa fa-filter" aria-hidden="true"></i> Filter
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="product-filter-content">
                              <div class="search-count">
                                <h5>Showing Products 1-{{filterItems().length}}</h5>
                              </div>
                              <div class="collection-view">
                                <ul>
                                  <li><i class="fa fa-th grid-layout-view" (click)="grid4()"></i></li>
                                  <li><i (click)="listOpen()" class="fa fa-list-ul list-layout-view"></i></li>
                                </ul>
                              </div>
                              <div class="collection-grid-view" *ngIf="gridOptions">
                                <ul>
                                  <li>
                                    <img alt="" class="product-2-layout-view" src="assets/images/icon/2.png"
                                      (click)="grid2()">
                                  </li>
                                  <li>
                                    <img alt="" class="product-3-layout-view" src="assets/images/icon/3.png"
                                      (click)="grid3()">
                                  </li>
                                  <li>
                                    <img alt="" class="product-4-layout-view" src="assets/images/icon/4.png"
                                      (click)="grid4()">
                                  </li>
                                </ul>
                              </div>
                              <div class="product-page-filter">
                                <select (change)="onChangeSorting($event.target.value)">
                                  <option selected value="asc">Sorting items</option>
                                  <option value="asc">Ascending Order</option>
                                  <option value="desc">Descending Order</option>
                                  <option value="a-z">Alphabetically, A-Z</option>
                                  <option value="z-a">Alphabetically, Z-A</option>
                                  <option value="low">Price, low to high</option>
                                  <option value="high">Price, high to low</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Block Products -->
                    <!-- Display Products -->
                    <div class="product-wrapper-grid" [class.list-view]="listView">
                      <div class="container-fluid">
                        <div class="row">
                          <div [class.col-xl-2]="col_xl_2" [class.col-xl-12]="col_xl_12" [class.col-xl-3]="col_xl_3"
                            [class.col-xl-4]="col_xl_4" [class.col-md-6]="col_md_6" class="col-grid-box"
                            *ngFor="let product of filterItems() | orderBy:sortByOrder">
                            <div class="product-box mt-2 mb-2">
                              <app-product-box class="list-product" [products]="product"></app-product-box>
                            </div>
                          </div>
                          <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                            *ngIf="!filterItems().length">
                            <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                            <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                            <p>Please check if you have misspelt something or try searching with other words.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- section End -->