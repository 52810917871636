import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-club-sponsors',
  templateUrl: './club-sponsors.component.html',
  styleUrls: ['./club-sponsors.component.scss']
})
export class ClubSponsorsComponent implements OnInit {

  sponsorData = [];
  constructor(public firestore: AngularFirestore,) { }

  ngOnInit(): void {
    this.getCoachData();
  }

  getCoachData() {
    this.firestore.collection("sponsors", ref => ref.where('clubId', '==', localStorage.getItem("clubId"))).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          this.sponsorData.push(e.data());
        });
      });
  }

}
