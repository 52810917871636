import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss']
})
export class NoticeBoardComponent implements OnInit {
  userData: any;
  data: any[] = [];
  clubId: any;

  constructor(public firestore: AngularFirestore) {
  }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    if (this.userData)
      this.getNoticeBoardData(this.userData.clubId);
    else {
      if (localStorage.getItem("clubId"))
        this.getNoticeBoardData(localStorage.getItem("clubId"));
    }
  }

  getNoticeBoardData(clubId) {
    this.data = [];
    this.firestore.collection('noticeBoard', ref => ref.where('clubId', '==', clubId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let d = e.data()
          d.id = e.id;
          d.startDate = new Date(d.startDate.seconds * 1000);
          d.endDate = new Date(d.endDate.seconds * 1000);
          if (new Date() < d.endDate)
            this.data.push(d);
        });
      });
  }
}
