import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  questionnaireData = [];
  images: any[] = [];
  ques: any[] = [];
  data: any;
  isShow = true;
  
  constructor(@Inject(AngularFireStorage) private storage: AngularFireStorage,
    private firestore: AngularFirestore, public toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('user'));
    this.getQuestionnaireData();
  }

  getQuestionnaireData() {
    let index = 0;
    this.firestore.collection('questionnaire', ref => ref.where('clubId', '==', this.data.clubId)).get()
      .subscribe(snapshot => {
        if (snapshot.empty) {
          this.isShow = false;
        } else {
          snapshot.forEach(e => {
            let data = e.data();
            data.id = e.id;
            if (this.data.quastionnaire) {
              for (let i = 0; i < this.data.quastionnaire.length; i++) {
                if (this.data.quastionnaire[i].question == data.question)
                  this.ques[index] = this.data.quastionnaire[i].answer;
              }
            }
            index++;
            this.questionnaireData.push(data);
            this.isShow = this.questionnaireData.filter(a => a.role == this.data.roles).length > 0 ? true : false;
          });
        }
      });
  }

  getQuestions() {
    let fData = this.questionnaireData.filter(a => a.role == this.data.roles);
    return fData;
  }

  async saveQuestionnaire() {
    let qData = [];
    for (let i = 0; i < this.questionnaireData.filter(a => a.role == this.data.roles).length; i++) {
      if (this.questionnaireData[i].inputType == 'file') {
        let data = {
          question: this.questionnaireData[i].question,
          answer: await this.saveImageDataInfo(this.data.uid)
        }
        qData.push(data);
      } else if (this.questionnaireData[i].inputType == 'boolean') {
        let data = {
          question: this.questionnaireData[i].question,
          answer: this.ques[i] ? (this.ques[i] == '' ? false : true) : false
        }
        qData.push(data);
      } else {
        let data = {
          question: this.questionnaireData[i].question,
          answer: this.ques[i] ? this.ques[i] : ''
        }
        qData.push(data);
      }
      if (i == (this.questionnaireData.filter(a => a.role == this.data.roles).length - 1)) {
        this.firestore.doc('users/' + this.data.uid).update({ quastionnaire: qData, quastionnaireLastUpdate: new Date() });
        this.toastrService.success('Data saved successfully!', 'Success');
        this.firestore.collection('users').doc(this.data.uid).get()
          .subscribe(snapshot => {
            sessionStorage.setItem('user', JSON.stringify(snapshot.data()));
            this.parentFun.emit();
          });
      }
    }
  }

  async saveImageDataInfo(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.images.forEach(e => {
        const fileRef = this.storage.ref(e.index + e.type + userId);
        this.storage.upload(e.index + e.type + userId, e.file).snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              resolve(url)
            })
          })
        ).subscribe();
      })
    });
  }

  showPreviewInfo(event: any, type, index) {
    if (event.target.files && event.target.files[0]) {
      let imageData = {
        file: event.target.files[0],
        type: type,
        index: index
      }
      this.images.push(imageData);
    }
  }
}
