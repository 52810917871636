<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="center-text">
                    <div class="text-center">
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text mb-0">
                            <p class="p-light about-para" [innerHtml]="data?.description?.description">
                                <!-- Opening a club or gym can be a tiresome task. Whilst the rewards are tenfold, your time
                                is often spent on tedious Admin tasks such as policies, ensuring all students/clients
                                have filled in all the paperwork requirements before they can begin.<br> Having worked
                                in
                                the industry and running a local Boxing Gym, we understood that pain and decided to
                                implement an system that would help us reduce that stress and offer a paper free
                                environment so that we could do what we loved... Teach!<br> PamIT is a system
                                implemented in
                                the Boxing industry with a number features that have been applied to a number of
                                different sports and fitness clientele.<br> Sign up today to learn more! -->
                            </p>
                        </div>
                        <!-- <div class="link-horizontal mt-3">
                            <ul class="justify-content-center">
                                <li><button (click)="addClub()" class="btn btn-dark rounded-pill"><span>register as a member</span></button>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->

<!--service app1 start-->
<section class="app1 services bg bg-dark">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span
                                style="color:white;">Clubs</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 p-2 text-center">
                <a [routerLink]="['/Attleborough-Boxing-Club']" class="btn btn-default rounded-pill mr-3">Attleborough Boxing
                    Club</a>
                <a [routerLink]="['/Norwich-Lads-Amateur-Boxing-Club']" class="btn btn-default rounded-pill">Norwich Lads Amateur Boxing Club</a>
            </div>
        </div>
    </div>
</section>
<!--service app1 end-->

<!--service app1 start-->
<section class="app1 services bg bg-light">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0">
                            <span>services</span></h2>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of data?.services?.serviceProvide" class="col-sm-4 p-2">
                <div class="service pt-3 pb-3  text-center card" data-tilt="" data-tilt-max="6" data-tilt-speed="400"
                    data-tilt-perspective="500"
                    style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                    <div class="img-block">
                        <i [class]="'fa fa-4x ' + item?.icon?.name"></i>
                        <!-- <img src="assets/images/app_landing1/icons/1-1.png" alt="" class="service-img img-fluid"> -->
                    </div>
                    <div class="service-feature" style="min-height: 170px;">
                        <h4 class="feature-text text-center">{{item?.name}}</h4>
                        <p>{{item?.description}}</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--service app1 end-->

<!-- <section class="event testimonial bg-white set-relative bg about-page">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0">
                            <span>testimonial</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1 margin-b">
                <owl-carousel-o [options]="aboutTestimonialCarouselOptions" class="testimonial">
                    <ng-container *ngFor="let user of aboutTestimonial">
                        <ng-template carouselSlide>
                            <div class="p-t-30">
                                <div class="col-lg-10 offset-lg-1 col-12">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-4">
                                            <div class="testimonial-msg set-relative">
                                                <img [src]="user.img" alt="" class="img-fluid avtar-img">
                                                <div class="msg-box">
                                                    <div class="center-content">
                                                        <img src="assets/images/gym/testimonial/message.png" alt=""
                                                            class="img-fluid set-abs">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-9 col-sm-8">
                                            <div class="quotes set-relative m-b-30">
                                                <div class="quote-text">
                                                    <h5>"{{user.text}}"</h5>
                                                </div>
                                            </div>
                                            <div class="rating align-items-center">
                                                <div class="stars">
                                                    <ul>
                                                        <li>
                                                            <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                            <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                            <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                            <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                            <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <h5 class="name">{{user.name}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="resume counter bg-pink">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/gym/all/1.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">1510</h3>
                        <h5 class="count-desc">Satisfied Customers</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/gym/all/2.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">58,000</h3>
                        <h5 class="count-desc">User Uploads</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/gym/all/3.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">24/7</h3>
                        <h5 class="count-desc">Customer Support</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/gym/all/4.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">90%</h3>
                        <h5 class="count-desc">Reduction In Admin</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!--accordion1 section start-->
<!-- <section class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span
                                class="text-uppercase">FAQ</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="faq-block">
                    <div class="w-100">
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        How Much Does It Cost?
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    Signing up is completely FREE!!! Thats right there are no monthly fees involved
                                    meaning if you take no payments, then there are no fees.<br>Transactions on PamIT
                                    include a 5% transaction fee which enables us to use a Card Gateway that offers
                                    unlimited possibilities.<br>This fee will be taken from your monthly earnings on
                                    payout.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        What are the Benefits?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Many clubs in todays modern age require a number of paperwork, which leads to admin
                                    hours! Why spend hours in the office with admin when you could be training or
                                    teaching classes?<br>This is where PamIT comes in. Alongside this key benefit there
                                    are a list of features as seen above.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        Is it only for Boxing Clubs?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Not at all! We are continuously growing our capabilities and will be adding
                                    additional requirements to our Club enrolment platform.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        <a>
                                            <div class="fa fa-angle-right rotate"></div>
                                        </a>
                                        Is there a limit?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Unlike many other systems, there is no limit on students you can enrol. A
                                    keyhighlight that is often overlooked causing a costly outcome. We wipe this out
                                    with our open unlimited policy.
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/gym/about.png" style="height: 400px;" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--accordion1 section end-->