<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="">
                    <div class="text-center">
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">Privacy Policy</h3>
                        </div>
                        <div class="format-sub-text mb-0">
                            <p class="p-light about-para" [innerHtml]="data?.privacyPolicy?.privacyPolicy">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->