import { Injectable, EventEmitter } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { User } from '../user';

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    userData: any; // Save logged in user data

    constructor(
        public afAuth: AngularFireAuth,
        public afs: AngularFirestore, public toastService: ToastrService, public router: Router, // Inject Firebase auth service
    ) {
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        })
    }

    // Sign in with Facebook
    facebookAuth() {
        return this.authLogin(new auth.FacebookAuthProvider());
    }

    // Sign in with Google
    googleAuth() {
        return this.authLogin(new auth.GoogleAuthProvider());
    }

    // Sign in with Email and Password
    signWithEmailAndPassword(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then((result) => {
                if (this.afAuth.auth.currentUser.emailVerified) {
                    this.afs.collection('users').doc(result.user.uid).get()
                        .subscribe(snapshot => {
                            if (snapshot.exists) {
                                let data = snapshot.data();
                                if (data.isDelete) {
                                    this.toastService.error('Your account is removed.', 'Success');
                                } else {
                                    sessionStorage.setItem('user', JSON.stringify(snapshot.data()));
                                    if (!snapshot.data().emailVerified) {
                                        this.afs.collection('users').doc(result.user.uid).set({ "emailVerified": true }, {
                                            merge: true
                                        });
                                    }
                                    this.afs.collection("clubs").doc(data.clubId).get()
                                        .subscribe(snapshot1 => {
                                            let clubData = snapshot1.data();
                                            let clubName = snapshot1.data().clubDetails.name.replace(/ /g, '-')
                                            localStorage.setItem('clubName', clubName);
                                            localStorage.setItem("clubId", snapshot1.id);
                                            localStorage.setItem("logoUrl", clubData.clubDetails.logoUrl);
                                            if (data.roles == "clubAdmin")
                                                this.router.navigate([clubName + '']);
                                            else if (data.roles != "clubAdmin" && data.roles != "superAdmin")
                                                this.router.navigate([clubName + '/book-session']);
                                            this.toastService.success('You have been successfully logged in!', 'Success');
                                        });
                                }
                            } else {
                                this.toastService.error("Something went wrong. Please try again.", 'Error');
                                this.removeUserFromFirebase();
                            }
                        });
                } else {
                    this.afs.collection('users').doc(result.user.uid).get()
                        .subscribe(snapshot => {
                            if (snapshot.exists) {
                                let data = snapshot.data();
                                if (data.isDelete) {
                                    this.toastService.error('Your account is removed.', 'Error');
                                } else {
                                    this.toastService.info("Email not verified. We have sent a confirmation email to verify your email.", 'Error');
                                    this.afAuth.auth.currentUser.sendEmailVerification();
                                    sessionStorage.setItem('user', JSON.stringify(snapshot.data()));
                                    if (!snapshot.data().emailVerified) {
                                        this.afs.collection('users').doc(result.user.uid).set({ "emailVerified": true }, {
                                            merge: true
                                        });
                                    }
                                    this.afs.collection("clubs").doc(data.clubId).get()
                                        .subscribe(snapshot1 => {
                                            let clubData = snapshot1.data();
                                            let clubName = clubData.clubDetails.name.replace(/ /g, '-');
                                            localStorage.setItem('clubName', clubName);
                                            localStorage.setItem("clubId", snapshot1.id);
                                            localStorage.setItem("logoUrl", clubData.clubDetails.logoUrl);
                                            if (data.roles == "clubAdmin")
                                                this.router.navigate([clubName + '']);
                                            else if (data.roles != "clubAdmin" && data.roles != "superAdmin")
                                                this.router.navigate([clubName + '/book-session']);
                                            this.toastService.success('You have been successfully logged in!', 'Success');
                                        });
                                }
                            } else {
                                this.toastService.error("Something went wrong. Please try again.", 'Error');
                                this.removeUserFromFirebase();
                            }
                        });
                }
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }

    removeUserFromFirebase() {
        var user = this.afAuth.auth.currentUser;
        user.delete().then(function () {
        }).catch(function (error1) {
            this.toastService.error(error1.message, 'Error');
        });
    }

    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification()
            .then(() => {
                this.router.navigate(['verify-email']);
                this.toastService.success('Verfication email send, check your inbox.', 'Success');
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }

    // Auth logic to run auth providers
    authLogin(provider) {
        return this.afAuth.auth.signInWithPopup(provider)
            .then((result) => {
                this.SetUserData(result.user);
                result.user.updateEmail('tez@xploited.media')
                this.toastService.success('You have been successfully logged in!', 'Success');
                this.router.navigate(['/settings']);
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }

    SetUserData(user) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        let userS = JSON.parse(sessionStorage.getItem('user'));
        const userData: User = {
            title: user.title ? user.title : '',
            uid: user.uid,
            email: user.email,
            phoneNo: '',
            firstName: user.firstName,
            lastName: user.lastName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            roles: 'clubUser',
            clubId: userS.clubId,
            RFID: '',
            norfid: 0,
            group: '',
            groupType: '',
            club_rfid: '',
            balance: 0,
            currency: 'GBP',
            over18: false,
            parentsName: '',
            isDelete: false,
            isActive: false,
            fillingType: "boxer",
            accountDetailsAddedDate: null,
            accountDetailsUpdatedDate: null,
            medicalDetailsAddedDate: null,
            medicalDetailsUpdatedDate: null,
            membershipDetailsAddedDate: null,
            membershipDetailsUpdatedDate: null,
            createdDate: new Date(),
            deletedDate: null
        }
        return userRef.set(userData, {
            merge: true
        })
    }

    SignOut() {
        return this.afAuth.auth.signOut().then(() => {
            localStorage.removeItem("user");
            sessionStorage.removeItem("user");
            //window.location.href = "http://localhost:4200/" + localStorage.getItem("clubName");
            window.location.href = "https://app.pamit.co.uk/" + localStorage.getItem("clubName");
        })
    }

    resetPassword() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.afAuth.auth.sendPasswordResetEmail(user.email)
            .then((result) => {
                this.toastService.success('A password reset link has been sent to your email address', 'Success');
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }
}