import { Component, OnInit } from '@angular/core';
import { ColorScssService } from '../shared/service/color-scss.service'

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {


  ngOnInit() {
    this.colorPicker.setColorScheme('inner-page');
  }

  constructor(public colorPicker: ColorScssService,
   
  ) {

   
  }
}
