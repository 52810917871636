import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorFilter, Products } from '../product.model';
import { ProductsService } from '../products.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './productList.component.html',
  styleUrls: ['./productList.component.scss'],
})
export class ProductListComponent implements OnInit {
  public products: Products[] = [];
  public items: Products[] = [];
  public allItems: Products[] = [];
  public colorFilters: ColorFilter[] = [];
  public tagsFilters: any[] = [];
  public tags: any[] = [];
  public categoryFilters: any[] = [];
  public category: any[] = [];
  public colors: any[] = [];
  public sortByOrder: string = '';   // sorting
  public animation: any;   // Animation

  lastKey = ''      // key to offset next query from
  finished = false  // boolean when end of data is reached

  sidebaron: boolean = false;
  show: boolean = false;
  open: boolean = false;
  public listView: boolean = false;
  //Grid 4
  public col_xl_3: boolean = true;
  //Grid 3
  public col_xl_4: boolean = true;
  //Grid 2
  public col_md_6: boolean = true;
  //List View
  public col_xl_12: boolean = false;
  public gridOptions: boolean = true;
  public active: boolean = false;
  userData: any;

  constructor(private route: ActivatedRoute, private router: Router,
    public productsService: ProductsService) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit() {
    this.productsService.getProducts().subscribe(products => {
     
      this.items = products;
      console.log(this.items)
      this.products = products.slice(0, 8)
      this.getTags(products)
      this.getColors(products)
      this.getCategory(products)
    })
  }

  // Get current product tags
  public getCategory(products) {
    var uniqueCategory = []
    var itemCategory = Array();
    products.map((product, index) => {
      if (product.category) {
        const index = uniqueCategory.indexOf(product.category);
        if (index === -1) uniqueCategory.push(product.category);
      }
    });
    for (var i = 0; i < uniqueCategory.length; i++) {
      itemCategory.push({ category: uniqueCategory[i] })
    }
    this.category = itemCategory;
  }

  // Get current product tags
  public getTags(products) {
    var uniqueBrands = []
    var itemBrand = Array();
    products.map((product, index) => {
      if (product.brand) {
        const index = uniqueBrands.indexOf(product.brand);
        if (index === -1) uniqueBrands.push(product.brand);
      }
    });
    for (var i = 0; i < uniqueBrands.length; i++) {
      itemBrand.push({ brand: uniqueBrands[i] })
    }
    this.tags = itemBrand
  }

  // Get current product colors
  public getColors(products) {
    var uniqueColors = []
    var itemColor = Array();
    products.map((product, index) => {
      if (product.colors) {
        product.colors.map((color) => {
          const index = uniqueColors.indexOf(color);
          if (index === -1) uniqueColors.push(color);
        })
      }
    });
    for (var i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] })
    }
    this.colors = itemColor
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    let pricemin = price[0];
    let maxPrice = price[1];
    let items: any[] = [];
    this.products.filter((item: Products) => {
      if (item.price >= pricemin && item.price <= maxPrice) {
        items.push(item); // push in array
      }
    });
    this.items = items;
  }

  // Initialize filetr Items
  public filterItems(): Products[] {
    return this.items.filter((item: Products) => {
      return (this.tagsFilters.length > 0 ? this.tagsFilters.indexOf(item.brand) > -1 : true) && (this.categoryFilters.length > 0 ? this.categoryFilters.indexOf(item.category) > -1 : true);
    });
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
  }

  // Update category filter
  public updateCategoryFilters(categories: any[]) {
    this.categoryFilters = categories;
  }

  // Update color filter
  public updateColorFilters(colors: ColorFilter[]) {
    this.colorFilters = colors;
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
  }

  openFilter() {
    if (this.show == true && this.sidebaron == true) {
      this.show = false
      this.sidebaron = false
    }
    else {
      this.show = true
      this.sidebaron = true
    }
  }

  openMediaFilter() {
    if (this.show == false && this.sidebaron == false && this.open == false) {
      this.show = true
      this.sidebaron = true
      this.open = true
    } else {
      this.show = false
      this.sidebaron = false
      this.open = false
    }
  }

  listOpen() {
    this.gridOptions = false
    this.listView = true;
    this.col_xl_12 = true;
  }

  grid2() {
    this.gridOptions = true;
    this.col_md_6 = true;
    this.col_xl_3 = false;
    this.listView = false
    this.col_xl_4 = false;
    this.col_xl_12 = false;

  }

  grid3() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_4 = true;
    this.col_xl_3 = false;
    this.col_md_6 = true;
    this.col_xl_12 = false;
  }

  grid4() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_3 = true;
    this.col_md_6 = true;
    this.col_xl_4 = false;
    this.col_xl_12 = false;
  }


  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar
  }

  //Close sidebar on click on outside the sidebar
  closeOverlay() {
    this.productsService.filterBar = false;
  }



}
