import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  data: any;
  constructor(public router: Router, public firestore: AngularFirestore) {
    this.firestore.collection("aboutUs").doc("WzPQBWfBuqW3BOwbt9ag").get().subscribe(snapshot => {
      this.data = snapshot.data();
    });
  }

  ngOnInit(): void {
  }

}
