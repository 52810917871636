import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from "../login/login.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as firebase from 'firebase';
import { environment } from '../../../environments/environment.prod';
import { EmailService } from '../../shared/service/email.service';
export interface DialogData {
  action: string;
}
@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  providers: [LoginService, EmailService]
})
export class DeleteAccountComponent implements OnInit {
  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openDialog(action) {
    const dialogRef = this.dialog.open(DialogUserPassword, {
      data: {
        action: action
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'dialog-user-password',
  templateUrl: 'passwordDialog.html',
  providers: [LoginService]
})
export class DialogUserPassword {
  password: string = "";
  userData: any;
  title = "";

  constructor(public loginService: LoginService, public afAuth: AngularFireAuth,
    public afs: AngularFirestore, public toastService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private emailService: EmailService,) {
  }

  ngOnInit(): void {
    this.title = this.data.action == "restrict" ? "Restrict Account" : "Delete Account";
  }

  async deleteAccount() {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
    if (this.password == "")
      this.toastService.error('Password is required', 'Error');
    else {
      if (this.data.action == "restrict") {
        const user = this.afAuth.auth.currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, this.password);
        user.reauthenticateWithCredential(credential).then(() => {
          this.restrictUser();
          this.toastService.success('Account successfully restricted!', 'Success');
        }).catch(error => {
          this.toastService.error(error.message, 'Error');
        });
      } else {
        const user = this.afAuth.auth.currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, this.password);
        user.reauthenticateWithCredential(credential).then(() => {
          user.delete();
          this.deleteData();
          this.toastService.success('Account successfully deleted!', 'Success');
        }).catch(error => {
          this.toastService.error(error.message, 'Error');
        });
      }
    }
  }

  restrictUser() {
    this.afs.collection('users').doc(this.userData.uid).set({ "isDelete": true }, {
      merge: true
    }).then(() => {
      let description = '<h3>Dear User,</h3>' +

        '<p>Your account restricted successfully. ' +
        'For revival of your account please follow below instruction.</p>' +

        '<ul>' +
        '<li><strong>Contact site administrator or use contact us page.</strong></li>' +
        '</ul>' +

        '<p>Thank you for using our service. We hope to see you soon.<br>' +
        '<br>' +
        'Thanks,<br>' +
        'PamIT&nbsp;</p></body></html>';
      this.postUserMail(this.userData.email, "Your account restricted successfully", description);
    }).catch(error => {
      this.toastService.error(error.message, 'Error');
    });
  }

  deleteData() {
    this.afs.collection("users").doc(this.userData.uid).delete().then(() => {
      this.afs.collection('account', ref => ref.where('userId', '==', this.userData.uid).limit(1)).get()
        .subscribe(snapshot => {
          snapshot.forEach(e => {
            this.afs.collection("account").doc(e.id).delete()
          });
        })
      this.afs.collection('medicalHistory', ref => ref.where('userId', '==', this.userData.uid).limit(1)).get()
        .subscribe(snapshot1 => {
          snapshot1.forEach(e1 => {
            this.afs.collection("account").doc(e1.id).delete()
          });
        })
      this.afs.collection('membership', ref => ref.where('userId', '==', this.userData.uid).limit(1)).get()
        .subscribe(snapshot2 => {
          snapshot2.forEach(e2 => {
            this.afs.collection("account").doc(e2.id).delete()
          });
        })
      let description = '<h3>Dear User,</h3>' +

        '<p>Your account deleted successfully. ' +
        'Following information of your account deleted.</p>' +

        '<ul>' +
        '<li><strong>Account/Medical/Membership details</strong></li>' +
        '<li><strong>Profile details</strong></li>' +
        '<li><strong>Purchase history and logs</strong></li>' +
        '</ul>' +

        '<p>Thank you for using our service. We hope to see you soon.<br>' +
        '<br>' +
        'Thanks,<br>' +
        'PamIT&nbsp;</p></body></html>';
      this.postUserMail(this.userData.email, "Your account deleted successfully", description);
    }).catch(error => {
      this.toastService.error(error.message, 'Error');
    });
  }

  signout() {
    this.afAuth.auth.signOut().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '';
    })
  }

  postUserMail(email, subject, description) {
    this.emailService.postUserMail(email, subject, description);
    this.signout();
  }
}