<section class="music bg-video format">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="card profile block">
                    <div class="card-body">
                        <div class="profile-picture big-profile-picture clear">
                            <img *ngIf="sponsorshipData?.images.length > 0" height="150" width="150"
                                src="{{sponsorshipData?.images[0]}}" style="object-fit: scale-down;">
                            <img *ngIf="sponsorshipData?.images.length == 0" height="150" width="150"
                                src="{{accountData?.personalDetails?.imageUrl}}" style="object-fit: scale-down;"
                                onerror="this.src='../../assets/images/noimage.jpg';">

                        </div>
                        <h3 class="mt-2 text-center text-white">{{accountData?.personalDetails?.firstName}}
                            {{accountData?.personalDetails?.lastName}}</h3>
                        <div class="text-center">
                            <div [innerHtml]="sponsorshipData?.description"></div>
                            <!-- <p class="scnd-font-color text-normal">{{sponsorshipData?.description}}</p> -->
                        </div>
                    </div>
                    <div class="card-footer p-0">
                        <ul class="profile-options horizontal-list w-100">
                            <li class="w-33"><a class="views" title="WIN" style="color: #11a8ab;">
                                    <span class="fa-1x mr-2">Win</span> <span class="fa-3x">{{sponsorshipData?.wins ||
                                        0}}</span>
                                </a></li>
                            <li class="w-33"><a class="likes" title="LOSS" style="color: #e64c65;">
                                    <span class="fa-1x mr-2">Loss</span> <span class="fa-3x">{{sponsorshipData?.loss ||
                                        0}}</span>
                                </a></li>
                            <li class="w-33"><a class="comments" style="color: #fcb150;" title="TOTAL MATCH">
                                    <span class="fa-1x mr-2">Draw</span> <span class="fa-3x">{{sponsorshipData?.draw ||
                                        0}}</span>
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="sponsorshipData?.news" class="card block mt-3">
                    <div class="card-header titular text-center">
                        <h5 class="text-white">Latest News</h5>
                    </div>
                    <div class="card-body p-2 overflow-auto text-left" style="height: 335px;">
                        <div [innerHtml]="sponsorshipData?.news"></div>
                    </div>
                </div>
                <div *ngIf="sponsorshipData?.images?.length > 0" class="card block mt-3">
                    <owl-carousel-o [options]="pricingcarouselOptions" class="price-margin pricing-slider">
                        <ng-container *ngFor="let img of sponsorshipData?.images;let i =index">
                            <ng-template carouselSlide class="item">
                                <img [src]="img" class="img-b-main">
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <!-- <div class="mt-3 mb-4">
                    <ul class="social horizontal-list block w-100">
                        <li class="facebook p-3 text-center w-25 li-left">
                            <a [href]="sponsorshipData?.facebookLink ? sponsorshipData?.facebookLink : 'https://www.facebook.com/'"
                                target="_blank"><i class="fa fa-2x fa-facebook text-white"></i></a>
                        </li>
                        <li class="twitter  p-3 text-center w-25">
                            <a [href]="sponsorshipData?.otherLink ? sponsorshipData?.otherLink : 'https://www.twitter.com/'"
                                target="_blank"> <i class="fa fa-2x fa-twitter text-white"></i></a>
                        </li>
                        <li class="instagram  p-3 text-center w-25">
                            <a [href]="sponsorshipData?.instagramLink ? sponsorshipData?.instagramLink : 'https://www.instagram.com/'"
                                target="_blank"><i class="fa fa-2x fa-instagram text-white"></i></a>
                        </li>
                        <li class="mailbox  p-3 text-center w-25 li-right">
                            <a [href]="sponsorshipData?.websiteLink ? sponsorshipData?.websiteLink : 'https://www.google.com/'"
                                target="_blank"> <i class="fa fa-2x fa-link text-white"></i></a>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card block ">
                            <div class="card-header titular text-center">
                                <h5 class="text-white">Boxer Details</h5>
                            </div>
                            <div class="card-body p-0 overflow-auto text-left" style="height: 279px;">
                                <ul>
                                    <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl">account_circle</mat-icon> Age : {{accountData?.age}}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl">height</mat-icon> Height (ft) :
                                            {{sponsorshipData?.height}}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl">monitor_weight</mat-icon> Weight (kg) :
                                            {{sponsorshipData?.weight}}
                                        </a>
                                    </li>

                                    <!-- <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl">place</mat-icon> From :
                                            {{accountData?.personalDetails?.address}}
                                        </a>
                                    </li> -->
                                    <!-- <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl">outlined_flag</mat-icon> Fighting out of : United
                                            Kingdom
                                        </a>
                                    </li> -->
                                    <li>
                                        <a class="menu-box-tab pl-2 text-white">
                                            <mat-icon class="v-mdl"> fitness_center</mat-icon> Strengths :
                                            {{sponsorshipData?.strengths}}
                                        </a>
                                    </li>
                                    <li class="form-inline">
                                        <mat-icon class="v-mdl ml-2 mr-2 text-white">share</mat-icon>
                                        <div id="socialshare"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card block mt-3">
                            <div class="card-header titular text-center">
                                <h5 class="text-white">Sponsors</h5>
                            </div>
                            <div class="card-body p-0 overflow-auto text-left" style="height: 335px;">
                                <ul>
                                    <li>
                                        <a href="https://app.pamit.co.uk/{{clubName}}" target="_blank"
                                            class="menu-box-tab p-2 text-white">
                                            <img height="40" width="40" class="mr-2 rounded-circle" style="object-fit: scale-down;"
                                                [src]="clubData?.clubDetails?.logoUrl">
                                            <span>{{clubData?.clubDetails?.name}}
                                            </span> <span *ngIf="clubData?.clubDetails?.description != ''"
                                                class="float-right">
                                                <a #tooltip="matTooltip"
                                                    matTooltip="{{clubData?.clubDetails?.description}}"
                                                    matTooltipPosition="above">
                                                    <mat-icon matPrefix>message</mat-icon>
                                                </a>
                                            </span>
                                        </a>
                                    </li>
                                    <li *ngFor="let item of sponsorsData">
                                        <a [href]="item.websiteLink" target="_blank"
                                            class="menu-box-tab p-2 text-white">
                                            <img height="40" width="40" style="object-fit: scale-down;" class="mr-2 rounded-circle" [src]="item?.logo">
                                            <span>{{item?.name}}
                                            </span> <span *ngIf="item?.description != ''" class="float-right">
                                                <a #tooltip="matTooltip" matTooltip="{{item?.description}}"
                                                    matTooltipPosition="above">
                                                    <mat-icon matPrefix>message</mat-icon>
                                                </a>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="donorData.length > 0" class="card block mt-3">
                            <div class="card-header titular text-center">
                                <h5 class="text-white">Top Sponsors </h5>
                            </div>
                            <div class="card-body overflow-auto p-0 text-left" style="height: 335px;">
                                <ul>
                                    <li *ngFor="let donor of donorData"
                                        class="border-bottom m-0 pb-2 pt-2 row text-white">
                                        <div class="col-md-12"><b class="text-capitalize">{{donor?.name}}</b> donated
                                            <b>£{{donor?.amount}}</b>
                                            <span *ngIf="donor?.message != ''" class="float-right">
                                                <a #tooltip="matTooltip" matTooltip="{{donor?.message}}"
                                                    matTooltipPosition="above">
                                                    <mat-icon matPrefix>message</mat-icon>
                                                </a>
                                            </span><br>
                                            <p class="" style="font-size: 12px;">{{donor?.createdDate | date:'dd MMMM,
                                                yyyy'}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card block ">
                            <div class="card-header text-center border-bottom-0">
                                <p class="text-success">
                                    <mat-icon matPrefix>currency_pound</mat-icon>
                                    <span class="fa-4x">{{totalAmount}}/</span><span
                                        class="fa-2x">{{sponsorshipData?.amount}}</span>
                                </p>
                                <p>Amount Raised</p>
                            </div>
                            <div class="card-body text-center">
                                <div class="mb-2">
                                    <button (click)="selectAmount(15)" [class.active]="selectedAmount == 15"
                                        class="btn btn-group btn-outline-info  text-white mr-2">
                                        <mat-icon matPrefix>currency_pound</mat-icon> 15
                                    </button>
                                    <button (click)="selectAmount(50)" [class.active]="selectedAmount == 50"
                                        class="btn btn-group btn-outline-info text-white mr-2">
                                        <mat-icon matPrefix>currency_pound</mat-icon> 50
                                    </button>
                                    <button (click)="selectAmount(100)" [class.active]="selectedAmount == 100"
                                        class="btn btn-group btn-outline-info  text-white mr-2">
                                        <mat-icon matPrefix>currency_pound</mat-icon> 100
                                    </button>
                                </div>
                                <div class="mb-2 text-center">
                                    <input (focus)="focusFunction()" [(ngModel)]="selectedAmount"
                                        [class.bg-info]="selectedAmount != 15 && selectedAmount != 50 && selectedAmount != 100"
                                        type="number" placeholder="Other Amount" class="form-control m-auto"
                                        style="width:200px;">
                                </div>
                                <a [routerLink]="" (click)="openModel(donerSummary)"
                                    [attr.data-target]="'#transactionDetails-view'" data-toggle="modal" title="Details"
                                    class="btn btn-block btn-lg subscribe text-white mb-3">Sponsor Now</a>
                            </div>
                        </div>

                        <div class="card block mt-3">
                            <div class="card-header titular text-center">
                                <h5 class="text-white">Sponsorship FAQ</h5>
                            </div>
                            <div class="card-body overflow-auto p-2 text-white">
                                <div [innerHtml]="sponsorshipFaq?.description"></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <div class="card block ">
                            <div class="row">
                                <div class="col-md-5">
                                    <img class="li-left w-100" src="../../../assets/images/share.png" height="200">
                                </div>
                                <div class="col-md-12 text-center m-auto">
                                    <p class="p-4">Every share will help raise more for this fundraiser. Please click
                                        any or all of
                                        the
                                        icons
                                        below, reach out to your family and friends and magnify your impact.</p>
                                    <div id="socialshare"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quick view Popup -->
<ng-template #donerSummary let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Payment Summary</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center mb-3">
            <h3>Mark the donation as anonymous <mat-slide-toggle (change)="changePaymentMethod($event)"
                    [(ngModel)]="isAnonymous">
                </mat-slide-toggle>
            </h3>
        </div>
        <div *ngIf="!isAnonymous">
            <form [formGroup]="donerForm" #f="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Email</mat-label>
                            <mat-icon matPrefix>email</mat-icon>
                            <input matInput placeholder="Email" formControlName="email" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name</mat-label>
                            <mat-icon matPrefix>person</mat-icon>
                            <input matInput placeholder="Name" formControlName="name" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Contact</mat-label>
                            <mat-icon matPrefix>phone</mat-icon>
                            <input matInput type="number" placeholder="Contact" formControlName="contact">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Message to fundraiser</mat-label>
                            <mat-icon matPrefix>message</mat-icon>
                            <input matInput placeholder="Message" formControlName="message">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Facebook</mat-label>
                            <mat-icon matPrefix>link</mat-icon>
                            <input matInput placeholder="Facebook" formControlName="facebookLink">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Instagram</mat-label>
                            <mat-icon matPrefix>link</mat-icon>
                            <input matInput placeholder="Instagram" formControlName="instagramLink">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Personal Website</mat-label>
                            <mat-icon matPrefix>link</mat-icon>
                            <input matInput placeholder="Personal Website" formControlName="websiteLink">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Twitter</mat-label>
                            <mat-icon matPrefix>link</mat-icon>
                            <input matInput placeholder="Twitter" formControlName="twitterLink">
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 mb-90 text-center ">
            <button (click)="donate()" class="btn btn-info">Continue to pay
                £{{selectedAmount}}</button>
        </div>
    </div>
</ng-template>