<!-- Login section -->
<section class="login-page section-b-space pb-3 pt-3">
    <div class="container">
        <div class="row">
            <div *ngIf="isChooseSession" class="col-lg-12 alert alert-danger text-center font-weight-bold">
                {{sessionMessage}}
            </div>
            <div class="col-lg-3">
                <div class="theme-card p-0">
                    <form class="theme-form">
                        <div *ngFor="let item of sessionsData" class="form-group">
                            <h5 class="bg-success text-center pb-0 mb-2 mt-0">Active Session</h5>
                            <h5 class="text-center">{{item.name}} - {{item?.amount | currency:'GBP'}}</h5>
                            <p style="font-size: smaller;" class="text-center">{{item.startDate | date:'dd/MM/yyyy h:mm
                                a'}} TO
                                {{item.endDate | date:'dd/MM/yyyy h:mm a'}}</p>
                            <h4 class="text-center text-danger">Pre Booked : <span
                                    class="font-weight-bold">{{item.bookedSlots}}</span></h4>
                            <p class="text-center mb-0">Maximum Capacity : <span
                                    class="font-weight-bold">{{item.slots}}</span></p>
                            <p class="text-center mb-0" style="font-size: 13px ;">Remaining Available Slots :
                                <span class="font-weight-bold">{{item.remainingSlots}}</span>
                            </p>
                            <p class="text-center mb-0">Total Scanned In : <span
                                    class="font-weight-bold">{{item.activeUsers}}</span></p>

                            <button *ngIf="isChooseSession" (click)="chooseSession(item)"
                                class="btn btn-block btn-dark btn-pill btn-sm">Select</button>
                        </div>
                        <div *ngIf="sessionsData.length == 0" class="alert alert-danger">
                            <p>Active session not available.</p>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-md-8">
                        <div class="theme-card">
                            <div *ngIf="isLoggedIn" class="mb-3">
                                <a (click)="goBack()"><i class="fa mr-2 fa-arrow-left"></i>Back</a>
                            </div>
                            <div *ngIf="userName != ''" class="alert alert-success text-center mb-1">
                                <img [src]="userImage" class="mb-2"
                                    onerror="this.src='../../assets/images/noimage.jpg';"
                                    style="border-radius: 50%;width:80px;height:80px;">
                                <h3 class="mb-0 text-center"> {{userName}}</h3>
                            </div>
                            <div *ngIf="paymentMsg != ''"
                                [ngClass]="isLoggedIn ? 'alert mb-1 alert-danger text-center' : 'alert mb-1 alert-success text-center'">
                                <h6 class="mb-1 "> {{paymentMsg}}</h6>
                                <!-- <h6>Details
                                    <span
                                        *ngIf="userData?.accountDetailsAddedDate != null && userData?.medicalDetailsAddedDate != null && userData?.membershipDetailsAddedDate != null">Completed</span>
                                    <span
                                        *ngIf="userData?.accountDetailsAddedDate == null || userData?.medicalDetailsAddedDate == null || userData?.membershipDetailsAddedDate == null">Incompleted</span>
                                    and <span *ngIf="!userData?.detailsVerified">Not Verified</span>
                                    <span *ngIf="userData?.detailsVerified">Verified</span>
                                </h6> -->
                            </div>
                            <div *ngIf="isLoggedIn" class="row mt-2 mb-4">
                                <div class="col-md-12">
                                    <h4>
                                        <span class="border border-dark float-left p-2 "><i
                                                class="fa fa-credit-card mr-2"></i>
                                            <span class="text-success">£{{userData?.balance}}</span> </span>
                                        <span class="border border-dark float-right  p-2">Fee: <span
                                                class="text-success">£{{sessionPrice}}</span></span>
                                    </h4>
                                </div>
                            </div>
                            <form *ngIf="!isLoggedIn" class="theme-form">
                                <div class="form-group">
                                    <h5 for="rfid" class="text-center">Enter RFID for IN Or OUT</h5>
                                    <input type="text" autocomplete="off" #RFID class="form-control text-uppercase mb-0"
                                        [(ngModel)]="rfid" name="rfid" placeholder="RFID" required>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                                        (click)="validateRFID(rfid)" [disabled]="isDisabled">Go</button>


                                </div>
                            </form>
                            <form *ngIf="isLoggedIn" class="theme-form">
                                <div class="mb-3">
                                    <form class="theme-form text-center">
                                        <div class="form-group">
                                            <label for="email">Top up Amount in CASH:</label>
                                        </div>
                                        <div class="mb-3">
                                            <a (click)="topUpAmount(5)" [disabled]="isDisabled"><span
                                                    class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                                    £5</span></a>
                                            <a (click)="topUpAmount(10)" [disabled]="isDisabled"><span
                                                    class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                                    £10</span></a>
                                            <a (click)="topUpAmount(20)" [disabled]="isDisabled"><span
                                                    class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                                    £20</span></a>
                                        </div>
                                    </form>
                                </div>
                                <hr class="hr-text" data-content="OR">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button (click)="payInCash()" [disabled]="isDisabled"
                                            class="btn btn-block btn-default bg-success primary-btn radius-0">Paid
                                            in
                                            Cash £{{sessionPrice}}</button>
                                    </div>
                                    <div class="col-md-6">
                                        <button (click)="payLater()" [disabled]="isDisabled"
                                            class="btn btn-block btn-default bg-danger primary-btn radius-0">Pay
                                            Later</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="theme-card">
                            <form class="theme-form">
                                <div class="form-group">
                                    <h5 for="email" class="text-center">Active Users</h5>
                                    <h3 class="text-center">{{activeUsers}}</h3>
                                    <a [routerLink]="" (click)="open(content,'view')" [attr.data-target]="'#quick-view'"
                                        data-toggle="modal" title="Quick View"
                                        class="btn btn-block btn-outline-dark btn-sm"><i class="fa fa-search"
                                            aria-hidden="true"></i> Quick View</a>
                                    <a [routerLink]="" (click)="open(guest,'guest')" [attr.data-target]="'#guest-view'"
                                        data-toggle="modal" title="Guest
                                        Check-In" class="btn btn-block btn-outline-info mt-3 btn-sm"><i
                                            class="fa fa-user" aria-hidden="true"></i> Guest
                                        Check-In</a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3" *ngIf="bookingData.length > 0">
                        <div class="theme-card p-1">
                            <p class="alert alert-info font-600 mb-1 mt-1 p-1">Booking list - Total :
                                {{bookingData.length}}</p>
                            <table class="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th class="p-1">Session</th>
                                        <th class="p-1">Timing</th>
                                        <th class="p-1">Name</th>
                                        <th class="p-1">RFID</th>
                                        <th class="p-1">Group</th>
                                        <th class="p-1">Entry</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 11px;">
                                    <tr *ngFor="let item of bookingData">
                                        <td class="p-1">
                                            {{item.eventName}}
                                        </td>
                                        <td class="p-1">
                                            {{item?.startDate | date:'dd/MM/yyyy h:mm a'}} -
                                            {{item?.endDate | date:'dd/MM/yyyy h:mm a'}}
                                        </td>
                                        <td class="p-1">
                                            {{item.userData.firstName}} {{item.userData.lastName}}
                                        </td>
                                        <td class="text-uppercase p-1">
                                            {{item.userData.RFID}}
                                        </td>
                                        <td class="text-uppercase p-1">
                                            {{item.userData.group}}
                                        </td>
                                        <td class="text-uppercase p-1">
                                            <span *ngIf="item.status" class="badge badge-pill badge-success">In</span>
                                            <span *ngIf="!item.status"
                                                class="badge badge-pill badge-danger">Pending</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end Login section -->

<!-- Quick view Popup -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Quick View</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <h6 class="mb-1 text-center text-success">Active Club Users : {{activeUsersData.length}}</h6>
            <table class="table table-sm table-striped fsmall ">
                <thead>
                    <tr>
                        <th class="p-1">Name</th>
                        <th style="width:200px;" class="p-1">Email</th>
                        <th class="p-1">RFID</th>
                        <th class="p-1">Group</th>
                        <th class="p-1">Entry Time</th>
                        <th class="p-1">Exit Time</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style="font-size: 11px;">
                    <tr *ngFor="let item of activeUsersData">
                        <td class="p-1">
                            {{item.name}}
                        </td>
                        <td class="p-1">
                            {{item.email}}
                        </td>
                        <td class="text-uppercase p-1">
                            {{item.RFID}}
                        </td>
                        <td class="p-1">
                            {{item.userData.group}}
                        </td>
                        <td class="text-uppercase p-1">
                            {{item.entryTime | date: 'dd/MM/yyyy hh:mm a'}}
                        </td>
                        <td class="text-uppercase p-1">
                            <span *ngIf="item.lastUpdatedOut != null">{{item.lastUpdatedOut | date: 'dd/MM/yyyy hh:mm
                                a'}}</span>
                            <span *ngIf="item.lastUpdatedOut == null">{{item.exitTime | date: 'dd/MM/yyyy hh:mm
                                a'}}</span>
                        </td>
                        <td>
                            <span *ngIf="item.checkMedical != ''"
                                class="badge badge-pill badge-danger">{{item.checkMedical}}</span>
                            <span *ngIf="item.checkPhoto != ''"
                                class="badge badge-pill badge-info ml-1">{{item.checkPhoto}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h6 class="mt-2 mb-1 text-center text-success">Other Active Users : {{activeOtherRolesData.length}}</h6>
            <table class="table table-sm table-striped fsmall ">
                <thead>
                    <tr>
                        <th class="p-1">Name</th>
                        <th style="width:200px;" class="p-1">Email</th>
                        <th class="p-1">RFID</th>
                        <th class="p-1">Role</th>
                        <th class="p-1">Entry Time</th>
                        <th class="p-1">Exit Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody style="font-size: 11px;">
                    <tr *ngFor="let item of activeOtherRolesData">
                        <td class="p-1">
                            {{item.name}}
                        </td>
                        <td class="p-1">
                            {{item.email}}
                        </td>
                        <td class="text-uppercase p-1">
                            {{item.RFID}}
                        </td>
                        <td class="p-1">
                            {{item.role}}
                        </td>
                        <td class="text-uppercase p-1">
                            {{item.entryTime | date: 'dd/MM/yyyy hh:mm a'}}
                        </td>
                        <td class="text-uppercase p-1">
                            <span *ngIf="item.role != 'guest' && item.lastUpdatedOut != null">{{item.lastUpdatedOut |
                                date: 'dd/MM/yyyy hh:mm
                                a'}}</span>
                            <span *ngIf="item.role != 'guest' && item.lastUpdatedOut == null">{{item.exitTime | date:
                                'dd/MM/yyyy hh:mm
                                a'}}</span>
                            <span *ngIf="item.role == 'guest' && item.exitTime != null">{{item.exitTime | date:
                                'dd/MM/yyyy hh:mm
                                a'}}</span>
                        </td>
                        <td>
                            <button *ngIf="item.role == 'guest'" class="btn btn-sm btn-outline-danger"
                                (click)="exitGuest(item)"><i class="fa fa-sign-out"></i> Exit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<!-- Quick view Popup -->
<ng-template #guest let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">Guest Check In Form</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <form [formGroup]="guestFormGroup" #guestForm class="theme-form">
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-danger" id="nameId">Name *</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Name" aria-describedby="nameId"
                                formControlName="name" required>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="emailId">Email</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Email" aria-describedby="emailId"
                                formControlName="email">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="contactId">Contact No.</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Contact Number"
                                aria-describedby="contactId" formControlName="contactNo">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="organizationId">Organization</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Organization"
                                aria-describedby="organizationId" formControlName="organization">
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="inputGroupSelect01">Gender</label>
                            </div>
                            <select class="custom-select" id="inputGroupSelect01" formControlName="gender">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Fee</span>
                                <span class="input-group-text">£</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Fee" formControlName="fee">
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Emergancy Contact No.</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Emergancy Contact Number"
                                formControlName="emergancyContactNo">
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Address</span>
                            </div>
                            <textarea class="form-control" aria-label="Address" formControlName="address"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                        <button class="btn btn-primary" (click)="guestCheckIn()">Save Guest</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>