<div *ngIf="isShow" class="row m-1">
    <div *ngFor="let item of getQuestions(); let i=index" class="col-md-6">
        <div class="mb-0 ">
            <div *ngIf="item.inputType == 'file'">
                <label class="font-weight-bold mb-0">{{item.question}}</label>
                <input class="form-control-file" type="file" (change)="showPreviewInfo($event,'additionalInfo',i)">
                <img *ngIf="item.inputType == 'file' && ques[i]" src="{{ques[i]}}" height="60px" width="100px"
                    class="rounded-circle mb-3">
            </div>
            <mat-form-field appearance="outline" class="w-100"
                *ngIf="item.inputType != 'boolean' && item.inputType != 'file'">
                <mat-label>{{item.question}}</mat-label>
                <input matInput placeholder="{{item.question}}" id="ques{{i}}" [(ngModel)]="ques[i]"
                    type="{{item.inputType}}" required>
            </mat-form-field>
            <div *ngIf="item.inputType == 'boolean'">
                <label class="font-weight-bold mb-0">{{item.question}}</label>
                <mat-slide-toggle class="example-margin form-control-plaintext" id="ques{{i}}" [(ngModel)]="ques[i]">
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-3">
        <button class="btn primary-btn btn-default radius-0" (click)="saveQuestionnaire()">Save</button>
    </div>
</div>
<div *ngIf="!isShow" class="m-2">
    <div class="alert alert-danger text-center">
        There are no additional questions to be answered
    </div>
</div>