<div class=" theme-card">
    <mat-vertical-stepper [linear]="isLinear" #stepper class="w-100">
        <mat-step [stepControl]="fourthFormGroup">
            <form [formGroup]="fourthFormGroup" #f4="ngForm">
                <ng-template matStepLabel>Ethnicity</ng-template>
                <div class="alert alert-info mb-2">In order to help our club monitor its membership please select
                    one of the following which is closest to your ethnic group</div>
                <div class="row">
                    <div class="col-lg-6 mb-3">
                        <select class="form-control" formControlName="ethnicity">
                            <option value="" hidden="hidden" disabled="disabled" selected="selected">-- Choose
                                Ethinicity --</option>
                            <option value="White British">White British</option>
                            <option value="White Albanian/Kosavan">White Albanian/Kosavan</option>
                            <option value="White Romanian">White Romanian</option>
                            <option value="Irish">White Irish</option>
                            <option value="White Bosnian">White Bosnian</option>
                            <option value="White Other">White Other</option>
                            <option value="White and Black - Caribbean">White and Black - Caribbean</option>
                            <option value="White and Black - African">White and Black - African</option>
                            <option value="White and Asian">White and Asian</option>
                            <option value="Asian and Black">Asian and Black</option>
                            <option value="Mixed - Other">Mixed - Other</option>
                            <option value="Indian">Indian</option>
                            <option value="Pakistani">Pakistani</option>
                            <option value="Bangladeshi">Bangladeshi</option>
                            <option value="Kashmiri">Kashmiri</option>
                            <option value="European">European</option>
                            <option value="Asian">Asian - Other</option>
                            <option value="Caribbean">Black Caribbean</option>
                            <option value="Black African">Black African</option>
                            <option value="Black - Other">Black - Other</option>
                            <option value="Chinese Other">Chinese Other</option>
                            <option value="Rather Not Say">Prefer not to say</option>
                        </select>
                        <div *ngIf="fourthFormGroup.get('ethnicity').hasError('required') && f4.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>

            </form>
        </mat-step>
        <mat-step [stepControl]="fifthFormGroup">
            <form [formGroup]="fifthFormGroup" #f5="ngForm">
                <ng-template matStepLabel>Photography And Video Recordings</ng-template>
                <div class="alert alert-info mb-2">Consent for video recording and or photographs to be taken. These
                    may be used for training aids, records of achievements, on the club website and social media and
                    in club publications.</div>
                <div class="mb-3">
                    <mat-checkbox class="example-margin ml-1" formControlName="consent">I consent to the above *
                    </mat-checkbox>
                </div>
                <div class="row">
                    <!-- <div class="col-lg-6">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Member Name</mat-label>
                                <input matInput placeholder="Member Name" formControlName="memberName" required>
                            </mat-form-field>
                        </div> -->
                    <div class="col-lg-12">
                        <p class="mb-2 font-600">Age *</p>
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                            formControlName="age">
                            <mat-radio-button class="example-radio-button mr-3" *ngFor="let g of age" [value]="g">
                                {{g}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="fifthFormGroup.get('age').hasError('required') && f5.submitted"
                            class="error text-danger">
                            Required
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn primary-btn btn-default radius-0" matStepperNext>Next</button>
                    </div>
                </div>

            </form>
        </mat-step>
        <mat-step [stepControl]="sixthFormGroup">
            <form [formGroup]="sixthFormGroup">
                <ng-template matStepLabel>Travelling</ng-template>
                <div class="alert alert-info mb-2">Travelling with the club may be either by car or minibus. All
                    transport has seatbelts fitted and should be used at all times to comply with the law</div>
                <div class="row">
                    <div class="col-lg-12">
                        <mat-checkbox class="example-margin ml-1" formControlName="consent">I consent to the above *
                        </mat-checkbox>
                    </div>
                    <!-- <div class="col-lg-12">
                            <mat-checkbox class="example-margin ml-1" formControlName="accept">I have read the rules for
                                Club Members and accept *
                            </mat-checkbox>
                        </div> -->
                    <div class="col-lg-12">
                        <button class="btn primary-btn btn-default radius-0" matStepperPrevious>Back</button>
                        <button class="btn btn-default btn-success radius-0" [disabled]="!sixthFormGroup.valid"
                            (click)="submit()">Submit</button>
                    </div>
                </div>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>