import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoginService } from "../login/login.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss'],
  providers: [LoginService]
})
export class ExitComponent implements OnInit {
  form: FormGroup;
  clubData: any;
  adminData: any;

  rfid: any;
  constructor(public service: LoginService, public afs: AngularFirestore, public toastService: ToastrService,) {
    this.adminData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    if (this.adminData) {
      this.afs.collection("clubs").doc(this.adminData.clubId).get()
        .subscribe(snapshot => {
          this.clubData = snapshot.data();
        });
    }
  }

  exit(rfid) {
    rfid = rfid.toString().toUpperCase();
    if (!rfid) {
      this.toastService.error('Enter RFID', 'Error');
      this.soundError();
    } else {
      this.afs.collection("users", ref => ref.where('club_rfid', '==', this.adminData.clubId + '_' + rfid).limit(1)).get()
        .subscribe(snapshot => {
          if (snapshot.empty) {
            this.toastService.error("User not found. Please contact site administrator.", 'Error');
            this.soundError();
            this.rfid = '';
          }
          else {
            snapshot.forEach(doc => {
              let data1 = doc.data();
              if (data1.roles == "coach") {
                let log_done = this.adminData.clubId + '_' + rfid
                this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                  .subscribe(snapshot1 => {
                    if (snapshot1.empty) {
                      this.toastService.error("Today's entry record not found for this user", 'Error');
                      this.soundError();
                      this.rfid = '';
                    } else {
                      snapshot1.forEach(doc => {
                        let data2 = doc.data();
                        let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                        let tDay = new Date();
                        if (entry != null) {
                          if (entry.getDay() == tDay.getDay() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                            this.afs.collection("userLogs").doc(doc.id).update({
                              exitTime: new Date(),
                              type: 'exit'
                            }).then(result => {
                              let msg = '';
                              if (data1.imageUrl != null)
                                msg = '<img src="' + data1.imageUrl + '" width="80px" height="60px">';
                              else
                                msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px">';
                              this.toastService.warning(msg, 'Exit ' + data1.firstName + ' ' + data1.lastName, {
                                enableHtml: true,
                                closeButton: true,
                                timeOut: 5000
                              });
                              this.soundExit();
                              this.rfid = '';
                            }).catch(err => {
                              this.toastService.error(err, 'Error');
                              this.soundError();
                              this.rfid = '';
                            });
                          } else {
                            this.toastService.error("Today's entry record not found for this user", 'Error');
                            this.soundError();
                            this.rfid = '';
                          }
                        } else {
                          this.toastService.error("Today's entry record not found for this user", 'Error');
                          this.soundError();
                          this.rfid = '';
                        }
                      });
                    }
                  });
              }
              else {
                if (data1.RFID && data1.RFID != "" && data1.RFID == rfid) {
                  let log_done = this.adminData.clubId + '_' + rfid
                  this.afs.collection("userLogs", ref => ref.where('log_done', '==', log_done).limit(1).orderBy('entryTime', 'desc')).get()
                    .subscribe(snapshot1 => {
                      if (snapshot1.empty) {
                        this.toastService.error("Today's entry record not found for this user", 'Error');
                        this.soundError();
                        this.rfid = '';
                      } else {
                        snapshot1.forEach(doc => {
                          let data2 = doc.data();
                          let entry = data2.entryTime != null ? new Date(data2.entryTime.seconds * 1000) : null;
                          let tDay = new Date();
                          if (entry != null) {
                            if (entry.getDay() == tDay.getDay() && entry.getMonth() == tDay.getMonth() && entry.getFullYear() == tDay.getFullYear()) {
                              this.afs.collection("userLogs").doc(doc.id).update({
                                exitTime: new Date(),
                                type: 'exit'
                              }).then(result => {
                                this.afs.collection("users").doc(data2.uid).update({
                                  isActive: false
                                });
                                let msg = '';
                                if (data1.imageUrl != null)
                                  msg = '<img src="' + data1.imageUrl + '" width="80px" height="60px">';
                                else
                                  msg = '<img src="assets/images/noimage.jpg" width="80px" height="60px">';
                                this.toastService.warning(msg, 'Exit ' + data1.firstName + ' ' + data1.lastName, {
                                  enableHtml: true,
                                  closeButton: true,
                                  timeOut: 5000
                                });
                                this.soundExit();
                                this.rfid = '';
                              }).catch(err => {
                                this.toastService.error(err, 'Error');
                                this.soundError();
                                this.rfid = '';
                              });
                            } else {
                              this.toastService.error("Today's entry record not found for this user", 'Error');
                              this.soundError();
                              this.rfid = '';
                            }
                          } else {
                            this.toastService.error("Today's entry record not found for this user", 'Error');
                            this.soundError();
                            this.rfid = '';
                          }
                        });
                      }
                    });
                }
              }
            });
          }
        });
    }
  }

  deactivateUser() {
  }

  soundSuccess() {
    let audio = new Audio();
    audio.src = "../../assets/audio/success.wav";
    audio.load();
    audio.play();
  }

  soundError() {
    let audio = new Audio();
    audio.src = "../../assets/audio/error.wav";
    audio.load();
    audio.play();
  }

  soundExit() {
    let audio = new Audio();
    audio.src = "../../assets/audio/exit.wav";
    audio.load();
    audio.play();
  }
}
