<div class="row">
    <div class="col-md-12 m-3">
        <img *ngIf="img" src="{{img.signature}}" width="320" height="150" class="border mb-2 border-dark"><br>
        <h5 class="mt-1">Add New Signature</h5>
        <div class="border mb-2 border-dark" style="width:320px;">
            <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()">
            </signature-pad>
        </div>
        <!-- <canvas #sigPad width="320" height="150" (mousedown)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)"></canvas> -->
        <br />
        <button class="btn primary-btn btn-default radius-0" (click)="clear()">clear</button>
        <button class="btn primary-btn btn-default radius-0 ml-2" (click)="save()">save</button>
    </div>
</div>