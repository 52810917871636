import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AccountComponent } from './account/account.component';
import { MembershipComponent } from './membership/membership.component';
import { MedicalInfoComponent } from './medical-info/medical-info.component';
import { EntryComponent } from './entry/entry.component';
import { ExitComponent } from './exit/exit.component';
import { WalletComponent } from './wallet/wallet.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SettingsComponent } from './settings/settings.component';
import { InOutComponent } from './in-out/in-out.component';
import { ScanComponent } from './scan/scan.component';
import { SuccessComponent } from './settings/success/success.component';
import { UsermgmtComponent } from './usermgmt/usermgmt.component';
import { BookingComponent } from './booking/booking.component';
import { ProductListComponent } from './shop/productList/productList.component';
import { ProductDetailsComponent } from './shop/product-detail/product-details.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { QuickPurchaseComponent } from './quick-purchase/quick-purchase.component';
import { ClubHomeComponent } from './club-home/club-home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { OfflineComponent } from './offline/offline.component';
import { PaymentSuccessComponent } from './wallet/payment-success/payment-success.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AuthGuard } from './auth.gaurd';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SponsorshipComponent } from './sponsorship/sponsorship.component';
import { ClubCoachesComponent } from './club-coaches/club-coaches.component';
import { ClubSponsorsComponent } from './club-sponsors/club-sponsors.component';
import { BookingSuccessComponent } from './club-events/booking-success/booking-success.component';
import { BookingCheckComponent } from './club-events/booking-check/booking-check.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', },
  { path: '404', component: PageNotFoundComponent },
  { path: 'offline', component: OfflineComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'contact-us', component: ContactUsComponent, data: { title: "Contact Us" } },
  { path: 'book-session', component: BookingComponent, data: { title: "Book Session" } },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: "Privacy Policy" } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { title: "Terms & Conditions" } },
  { path: 'verify-email', component: VerifyEmailComponent, data: { title: "Verify Email" } },
  { path: 'action', component: UsermgmtComponent },
  // { path: ':clubHome', component: ClubHomeComponent },
  {
    path: ':clubName',
    children: [
      { path: '', component: ClubHomeComponent },
      { path: 'booking/:checkoutid', component: BookingSuccessComponent, },
      { path: 'booking', component: BookingCheckComponent, data: { title: "Event Booking" } },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'contact-us', component: ContactUsComponent, data: { title: "Contact Us" } },
      { path: 'book-session', component: BookingComponent, data: { title: "Book Session" } },
      { path: 'profile/:id/:name', component: UserProfileComponent, data: { title: "Boxer Profile" } },
      { path: 'profile/:id/:name/:checkoutid', component: UserProfileComponent, data: { title: "Boxer Profile" } },
      { path: 'coaches', component: ClubCoachesComponent, data: { title: "Our Coaches" } },
      { path: 'sponsors', component: ClubSponsorsComponent, data: { title: "Our Sponsors" } },
      { path: 'sponsorship', component: SponsorshipComponent, canActivate: [AuthGuard], data: { title: "Sponsorship Settings" } },
      { path: 'user/account', component: AccountComponent, canActivate: [AuthGuard], data: { title: "User Account" } },
      { path: 'user/delete/account', component: DeleteAccountComponent, canActivate: [AuthGuard], data: { title: "Account Settings" } },
      { path: 'order/history', component: OrderHistoryComponent, canActivate: [AuthGuard] },
      { path: 'shop', component: ProductListComponent, canActivate: [AuthGuard] },
      { path: 'shop/product/:id', component: ProductDetailsComponent, canActivate: [AuthGuard] },
      { path: 'wallet', component: WalletComponent, canActivate: [AuthGuard], data: { title: "Wallet" } },
      { path: 'payment-success/:id', component: PaymentSuccessComponent, canActivate: [AuthGuard] },
      { path: 'quick-purchase', component: QuickPurchaseComponent, canActivate: [AuthGuard], data: { title: "Quick Purchase" } },
      { path: 'membership', component: MembershipComponent, canActivate: [AuthGuard], data: { title: "Club Membership" } },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: "User Membership Details" } },
      { path: 'settings/success', component: SuccessComponent, canActivate: [AuthGuard] },
      { path: 'medical-info', component: MedicalInfoComponent, canActivate: [AuthGuard], data: { title: "User Medical Details" } },
      { path: 'entry', component: EntryComponent, canActivate: [AuthGuard], data: { title: "User Entry" } },
      { path: 'exit', component: ExitComponent, canActivate: [AuthGuard], data: { title: "User Exit" } },
      { path: 'in-out', component: InOutComponent, canActivate: [AuthGuard], data: { title: "User In & Out" } },
      { path: 'scan', component: ScanComponent, canActivate: [AuthGuard], data: { title: "Check Details" } },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class PagesRoutingModule { }
