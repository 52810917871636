<!-- Login section -->
<section class="login-page section-b-space pt-3 pb-3">
    <div class="container" style="max-width: 500px;">
        <div class="row">
            <div class="col-lg-12">
                <!-- <div class="logo text-center">
                    <a class="navbar-brand" href="#"><img src="assets/images/logo/5.png" alt="logo"></a>
                </div> -->
                <h3 class="text-center">Forgot Password</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control mb-2" [(ngModel)]="email" name="email"
                                placeholder="Email" required>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-block btn-default mb-2 primary-btn radius-0"
                                (click)="rService.ForgotPassword(email)">Submit</button>
                            <span class="float-right">Already have account?<a class="text-primary" (click)="redirectUrl('/login')">
                                    Login</a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->