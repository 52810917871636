import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-usermgmt',
  templateUrl: './usermgmt.component.html',
  styleUrls: ['./usermgmt.component.scss']
})
export class UsermgmtComponent implements OnInit {
  newPassword: string;
  confirmPassword: string;

  mode: any;
  actionCode: any;
  continueUrl: any;
  lang: any;
  isReset = false;

  constructor(public afAuth: AngularFireAuth, private toastService: ToastrService,
    public router: Router, private route: ActivatedRoute) {
    this.mode = this.getParamValueQueryString('mode');
    this.actionCode = this.getParamValueQueryString('oobCode');
    this.continueUrl = this.getParamValueQueryString('continueUrl');
    this.lang = this.getParamValueQueryString('lang');
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  ngOnInit(): void {
    if (this.mode == 'verifyEmail')
      this.handleVerifyEmail(this.afAuth.auth, this.actionCode, this.continueUrl, this.lang);
    else if (this.mode == 'recoverEmail')
      this.handleRecoverEmail(this.afAuth.auth, this.actionCode, this.lang);
    else if (this.mode == 'resetPassword')
      this.handleResetPassword(this.afAuth.auth, this.actionCode, this.continueUrl, this.lang);
  }

  handleVerifyEmail(auth, actionCode, continueUrl, lang) {
    auth.applyActionCode(actionCode).then((result) => {
      this.toastService.success('Email address has been verified', 'Success');
      this.router.navigate(['/login']);
    }).catch((error) => {
      this.toastService.error('Code is invalid or expired. Ask the user to verify their email address again', 'Error');
    });
  }

  handleRecoverEmail(auth, actionCode, lang) {
    var restoredEmail = null;
    auth.checkActionCode(actionCode).then(function (info) {
      restoredEmail = info['data']['email'];
      return auth.applyActionCode(actionCode);
    }).then(function () {
      this.toastService.success('Account email reverted to restoredEmail', 'Success');
      auth.sendPasswordResetEmail(restoredEmail).then(function () {
        this.toastService.success('Password reset confirmation sent. Ask user to check their email.', 'Success');
      }).catch((error) => {
        this.toastService.error('Error encountered while sending password reset code.', 'Error');
      });
    }).catch((error) => {
      this.toastService.error('Invalid code', 'Error');
    });
  }

  handleResetPassword(auth, actionCode, continueUrl, lang) {
    auth.verifyPasswordResetCode(actionCode).then((result) => {
      this.isReset = true;
    }).catch((error) => {
      this.toastService.error('Invalid or expired action code. Ask user to try to reset the password again', 'Error');
    });
  }

  resetPassword() {
    if (this.newPassword == this.confirmPassword) {
      this.afAuth.auth.confirmPasswordReset(this.actionCode, this.newPassword).then((result) => {
        this.toastService.success('Password reset has been confirmed and new password updated', 'Success');
        this.router.navigate(['/login']);
      }).catch((error) => {
        this.toastService.error('Error occurred during confirmation. The code might have expired or the password is too weak.', 'Error');
      });
    }
    else {
      this.toastService.error('Passwords not match', 'Error');
    }
  }

}
