<!-- register section -->

<section class="register-page section-b-space pt-3 pb-5">
    <div class="container mb-5" style="max-width: 500px;">
        <div class="row">

            <div class="col-lg-12">
                <!-- <div class="logo text-center">
                    <a class="navbar-brand" href="#"><img src="assets/images/logo/5.png" alt="logo"></a>
                </div> -->
                <h3 class="text-center">Create account</h3>
                <div class="theme-card">
                    <!-- <div class="row">
                        <div class="col-md-12 mb-2">
                            <button (click)="lService.facebookAuth()" type="button"
                                class="btn btn-fb btn-block text-white"><i class="fa fa-2x fa-facebook pr-2 v-mdl"></i>
                                Continue With Facebook</button>
                        </div>
                        <div class="col-md-12 mb-2">
                            <button (click)="lService.googleAuth()" type="button"
                                class="btn btn-google btn-block text-white"><i
                                    class="fa fa-2x v-mdl fa-google pr-2"></i>
                                Continue
                                With Google</button>
                        </div>
                    </div>
                    <hr> -->
                    <!-- <div class="alert alert-info">Phone signup mode still in testing phase. Please bare with us for the
                        inconvenience. </div> -->
                    <form class="theme-form">
                        <div class="form-row">
                            <div class="col-md-12 mb-2">
                                <label for="title" class="mb-1">Title</label>
                                <select class="form-control mb-1  rounded-0" id="title" name="title"
                                    [(ngModel)]="title">
                                    <option disabled selected value="">-- Select Title --</option>
                                    <option *ngFor="let item of titleData" [value]="item.value">{{item.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-12 mb-2">
                                <label for="firstName" class="mb-1">Member First Name</label>
                                <input type="text" class="form-control mb-1" [(ngModel)]="firstName" name="firstName"
                                    id="firstName" placeholder="Member First Name">
                            </div>
                            <div class="col-md-12">
                                <label for="lastName" class="mb-1">Member Last Name</label>
                                <input type="text" class="form-control mb-1" id="lastName" [(ngModel)]="lastName"
                                    name="lastName" placeholder="Member Last Name">
                            </div>
                            <div class="col-md-12 mb-2 mt-2">
                                <mat-radio-group name="fillingType" [(ngModel)]="fillingType">
                                    <mat-radio-button [checked]="true" class="mr-3" value="boxer">Filling In As The
                                        Boxer
                                    </mat-radio-button>
                                    <mat-radio-button value="parent">Filling In By Parent Or Carer</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-md-12 mb-2">
                                <label for="club" class="mb-1">Club</label>
                                <select class="form-control mb-1  rounded-0" id="club" name="club" [(ngModel)]="club"
                                    [disabled]="isClubReg">
                                    <option disabled selected value="">-- Select Club --</option>
                                    <option *ngFor="let item of clubData" [value]="item.value">{{item.name}}</option>
                                </select>
                            </div>
                            <!-- <div class="col-md-12 mt-2 mb-2">
                                <mat-radio-group name="epoption" [(ngModel)]="option"
                                    (change)="changeOption($event.value)">
                                    <mat-radio-button [checked]="true" class="mr-3" value="email">Email
                                    </mat-radio-button>
                                    <mat-radio-button value="phone" >Phone</mat-radio-button>
                                </mat-radio-group>
                            </div> -->
                            <div class="col-md-12 mb-2" *ngIf="isEmail">
                                <div class="col-12 p-0 mb-2">
                                    <label for="email" class="mb-1">Email</label>
                                    <input type="text" class="form-control mb-1" [(ngModel)]="email" name="email"
                                        id="email" placeholder="Email">
                                </div>
                                <div class="col-12 p-0">
                                    <label for="password" class="mb-1">Password</label>
                                    <input type="password" class="form-control mb-1" id="password"
                                        [(ngModel)]="password" name="password" placeholder="Create a Password"
                                        autocomplete="on">
                                </div>
                                <div class="col-12 p-0">
                                    <label for="repeatPassword" class="mb-1">Confirm Password</label>
                                    <input type="password" class="form-control mb-1" id="repeatPassword"
                                        [(ngModel)]="repeatPassword" name="repeatPassword" placeholder="Confirm Password"
                                        autocomplete="on">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <mat-checkbox (change)="ageChecked($event.checked)" [(ngModel)]="over18" name="over18">I
                                    AM OVER 18?</mat-checkbox>
                            </div>
                            <div *ngIf="isParent" class="col-md-12 mb-3">
                                <label for="parentsName" class="mb-1">Parent/Guardian Name</label>
                                <input type="text" class="form-control mb-1" id="parentsName" [(ngModel)]="parentsName"
                                    name="parentsName" placeholder="Parent/Guardian Name">
                            </div>
                            <div class="col-md-12 mb-3" *ngIf="!isEmail">
                                <div class="col-12 p-0">
                                    <label for="phone" class="mb-1">Phone</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"
                                                style="height: 50px!important;">+44</span>
                                        </div>
                                        <input [disabled]="otpSent" type="number" style="height: 50px!important;"
                                            class="form-control mb-1 border" [(ngModel)]="phone" name="phone" id="phone"
                                            placeholder="Phone">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" id="recaptcha-container"></div>
                            <div class="col-md-12 mb-3" *ngIf="!isEmail && otpSent">
                                <div class="col-12 p-0">
                                    <p for="code" class="mb-1 font-weight-bold">Enter OTP <a (click)="sendLoginCode()"
                                            class="float-right text-primary">Resend OTP</a></p>
                                    <input type="text" class="form-control mb-1" [(ngModel)]="verificationCode"
                                        name="code" id="code" placeholder="code">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button *ngIf="isEmail" type="button" [disabled]="clicked"
                                    class="btn primary-btn btn-block mb-1 btn-default radius-0"
                                    (click)="submit()">Create
                                    Account</button>
                                <button *ngIf="!isEmail && !otpSent"
                                    class="btn primary-btn btn-block mb-1 btn-default radius-0"
                                    (click)="sendLoginCode()">SMS Text Login Code</button>
                                <button *ngIf="!isEmail && otpSent" [disabled]="!verificationCode"
                                    (click)="verifyLoginCode()"
                                    class="btn primary-btn btn-block mb-1 btn-default radius-0">Verify</button>
                                <span class="float-right">Already have account?<a class="text-primary"
                                        (click)="redirectUrl('/login')">
                                        Login</a></span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end register section -->