<!-- section start -->
<section class="section-b-space" style="padding:25px 0px!important;">
    <div class="collection-wrapper">
        <div class="container">
            <mat-horizontal-stepper linear #stepper>
                <mat-step>
                    <ng-template matStepLabel>Choose Product</ng-template>
                    <div class="row">
                        <!-- End Collection Sidebar -->
                        <div class="collection-content col">
                            <div class="page-main-content">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="collection-product-wrapper">
                                                <!-- Filter Block Products -->
                                                <!-- <div class="product-top-filter">
                                                    <div class="container-fluid p-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="product-filter-content">
                                                                    <div class="search-count">
                                                                        <h5>Showing Products 1-{{filterItems().length}}
                                                                        </h5>
                                                                    </div>
                                                                    <div class="collection-view">
                                                                        <ul>
                                                                            <li><i class="fa fa-th grid-layout-view"
                                                                                    (click)="grid4()"></i></li>
                                                                            <li><i (click)="listOpen()"
                                                                                    class="fa fa-list-ul list-layout-view"></i>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="collection-grid-view"
                                                                        *ngIf="gridOptions">
                                                                        <ul>
                                                                            <li>
                                                                                <img alt=""
                                                                                    class="product-2-layout-view"
                                                                                    src="assets/images/icon/2.png"
                                                                                    (click)="grid2()">
                                                                            </li>
                                                                            <li>
                                                                                <img alt=""
                                                                                    class="product-3-layout-view"
                                                                                    src="assets/images/icon/3.png"
                                                                                    (click)="grid3()">
                                                                            </li>
                                                                            <li>
                                                                                <img alt=""
                                                                                    class="product-4-layout-view"
                                                                                    src="assets/images/icon/4.png"
                                                                                    (click)="grid4()">
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="product-page-filter">
                                                                        <select
                                                                            (change)="onChangeSorting($event.target.value)">
                                                                            <option selected value="asc">Sorting items
                                                                            </option>
                                                                            <option value="asc">Ascending Order</option>
                                                                            <option value="desc">Descending Order
                                                                            </option>
                                                                            <option value="a-z">Alphabetically, A-Z
                                                                            </option>
                                                                            <option value="z-a">Alphabetically, Z-A
                                                                            </option>
                                                                            <option value="low">Price, low to high
                                                                            </option>
                                                                            <option value="high">Price, high to low
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <!-- End Block Products -->
                                                <!-- Display Products -->
                                                <div class="product-wrapper-grid" [class.list-view]="listView">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <div [class.col-xl-2]="col_xl_2"
                                                                [class.col-xl-12]="col_xl_12"
                                                                [class.col-xl-3]="col_xl_3" [class.col-xl-4]="col_xl_4"
                                                                [class.col-md-6]="col_md_6" class="col-grid-box"
                                                                *ngFor="let product of filterItems() | orderBy:sortByOrder">
                                                                <div class="product-box mt-2 mb-2">
                                                                    <div class="img-wrapper text-center">
                                                                        <div class="front">
                                                                            <a>
                                                                                <img alt="" class="img-fluid"
                                                                                    routerLinkActive="active"
                                                                                    [src]="product?.images[0]?.src"
                                                                                    style="height:200px!important;"
                                                                                    onerror="this.src='../../assets/images/no-image.png';">
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="product-detail">
                                                                        <a href="product-page(no-sidebar).html">
                                                                            <h6>{{product?.title}}</h6>
                                                                        </a>
                                                                        <h4>{{product?.price | currency:'GBP'}} <span
                                                                                class="avalibility text-success pt-0 float-right"
                                                                                *ngIf="product.stock > 0">
                                                                                <span>In Stock</span></span>
                                                                            <span
                                                                                class="avalibility text-danger pt-0 float-right"
                                                                                *ngIf="product.stock == 0"><span>Out
                                                                                    of
                                                                                    Stock</span></span>
                                                                        </h4>
                                                                        <!-- <div class="qty-box mb-2">
                                                                            <div class="input-group">
                                                                                <span class="input-group-prepend">
                                                                                    <button type="button"
                                                                                        (click)="decrement(product)"
                                                                                        class="btn quantity-left-minus"
                                                                                        data-type="minus" data-field="">
                                                                                        <i class="fa fa-minus"></i>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" name="quantity"
                                                                                    disabled
                                                                                    class="form-control form-control-sm input-number"
                                                                                    value="product.quantity">
                                                                                <span class="input-group-prepend">
                                                                                    <button type="button"
                                                                                        (click)="increment(product)"
                                                                                        class="btn quantity-right-plus"
                                                                                        data-type="plus" data-field="">
                                                                                        <i class="fa fa-plus"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div> -->
                                                                        <label *ngIf="product?.stock > 0"
                                                                            class="btn btn-default btn-block primary-btn radius-0 p-2">
                                                                            <input
                                                                                (change)="selectProduct(product,$event.target.checked)"
                                                                                [class.disabled]="product?.stock == 0"
                                                                                type="checkbox" name="options"
                                                                                id="option3" autocomplete="off"> Select
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                                *ngIf="!filterItems().length">
                                                                <img src="assets/images/empty-search.jpg"
                                                                    class="img-fluid mb-4">
                                                                <h3>Sorry! Couldn't find the product you were looking
                                                                    For!!! </h3>
                                                                <p>Please check if you have misspelt something or try
                                                                    searching with other words.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="filterItems().length > 0" class="mt-3 text-center">
                        <button class="btn btn-lg btn-primary" mat-button matStepperNext>Next</button>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Scan RFID & Submit</ng-template>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="theme-card card card-body">
                                <form class="theme-form">
                                    <div *ngIf="userData?.roles == 'clubAdmin'" class="form-group">
                                        <h5 for="email" class="text-center">Guest Checkout
                                        </h5>
                                        <a [routerLink]="" (click)="payInCashGuest()" [attr.data-target]="'#quick-view'"
                                            data-toggle="modal" title="Quick View"
                                            class="btn btn-block btn-outline-dark btn-sm"><i class="fa fa-money"
                                                aria-hidden="true"></i> Pay In Cash</a>
                                    </div>
                                    <div *ngIf="userData?.roles != 'clubAdmin'" class="form-group alert alert-info">
                                        Please contact club admin for pay in cash option
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div *ngIf="userData?.roles == 'clubAdmin'" class="theme-card card card-body">
                                <div *ngIf="isLoggedIn" class="mb-3">
                                    <a (click)="goBack()"><i class="fa mr-2 fa-arrow-left"></i>Back</a>
                                </div>
                                <div *ngIf="userName != ''" class="alert alert-success text-center">
                                    <img [src]="userImage" class="mb-2"
                                        onerror="this.src='../../assets/images/noimage.jpg';"
                                        style="border-radius: 50%;width:80px;height:80px;">
                                    <h3 class="mb-0 text-center"> {{userName}}</h3>
                                </div>
                                <div *ngIf="paymentMsg != ''"
                                    [ngClass]="isLoggedIn ? 'alert alert-danger text-center' : 'alert alert-success text-center'">
                                    <h5 class="pb-0 "> {{paymentMsg}}</h5>
                                </div>
                                <div *ngIf="isLoggedIn" class="row mt-2 mb-4">
                                    <div class="col-md-12">
                                        <h4>
                                            <span class="border border-dark float-left p-2 "><i
                                                    class="fa fa-credit-card mr-2"></i>
                                                <span class="text-success">£{{rfidUserData?.balance}}</span> </span>
                                            <span class="border border-dark float-right  p-2">Order Price: <span
                                                    class="text-success">£{{totalPrice}}</span></span>
                                        </h4>
                                    </div>
                                </div>
                                <form *ngIf="!isLoggedIn" class="theme-form">
                                    <div class="form-group">
                                        <h5 for="rfid" class="text-center">Enter RFID for IN Or OUT</h5>
                                        <input type="text" autocomplete="off" #RFID class="form-control text-uppercase"
                                            [(ngModel)]="rfid" name="rfid" placeholder="RFID" required>
                                    </div>
                                    <div>
                                        <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                                            (click)="submitDetails(rfid)" [disabled]="isDisabled">Go</button>
                                    </div>
                                </form>
                                <form *ngIf="isLoggedIn" class="theme-form">
                                    <div class="mb-3">
                                        <form class="theme-form text-center">
                                            <div class="form-group">
                                                <label for="email">Top up Amount in CASH:</label>
                                            </div>
                                            <div class="mb-3">
                                                <a *ngIf="totalPrice <= 10" (click)="topUpAmount(10)"
                                                    [disabled]="isDisabled"><span
                                                        class="badge badge-pill badge-info mr-3"
                                                        style="font-size: 20px;">+
                                                        £10</span></a>
                                                <a *ngIf="totalPrice <= 15" (click)="topUpAmount(15)"
                                                    [disabled]="isDisabled"><span
                                                        class="badge badge-pill badge-info mr-3"
                                                        style="font-size: 20px;">+
                                                        £15</span></a>
                                                <a *ngIf="totalPrice <= 20" (click)="topUpAmount(20)"
                                                    [disabled]="isDisabled"><span
                                                        class="badge badge-pill badge-info mr-3"
                                                        style="font-size: 20px;">+
                                                        £20</span></a>
                                            </div>
                                        </form>
                                    </div>
                                    <hr class="hr-text" data-content="OR">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button (click)="payInCash()" [disabled]="isDisabled"
                                                class="btn btn-block btn-default bg-success primary-btn radius-0">Paid
                                                in
                                                Cash £{{totalPrice}}</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button (click)="payLater()" [disabled]="isDisabled"
                                                class="btn btn-block btn-default bg-danger primary-btn radius-0">Pay
                                                Later </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div *ngIf="userData?.roles != 'clubAdmin'" class="theme-card card card-body">
                                <div *ngIf="paymentMsg != ''"
                                    [ngClass]="isLoggedIn ? 'alert alert-danger text-center' : 'alert alert-success text-center'">
                                    <h5 class="pb-0 "> {{paymentMsg}}</h5>
                                </div>
                                <div class="row mt-2 mb-4">
                                    <div class="col-md-12 mb-3">
                                        <h4>
                                            <span class="border border-dark float-left p-2 "><i
                                                    class="fa fa-credit-card mr-2"></i>
                                                <span class="text-success">£{{userData?.balance}}</span> </span>
                                            <span class="border border-dark float-right  p-2">Order Price: <span
                                                    class="text-success">£{{totalPrice}}</span></span>
                                        </h4>

                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn-block btn-default primary-btn radius-0"
                                            (click)="userOrderNow()">Order Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="theme-card card card-body">
                                <form class="theme-form">
                                    <div class="form-group">
                                        <h5 for="email" class="text-center">Order History</h5>
                                        <a [routerLink]="" (click)="openModel(content)"
                                            [attr.data-target]="'#quick-view'" data-toggle="modal" title="Quick View"
                                            class="btn btn-block btn-outline-dark btn-sm"><i class="fa fa-search"
                                                aria-hidden="true"></i> Quick View</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button class="btn btn-lg btn-primary" mat-button matStepperPrevious>Back</button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</section>
<!-- section End -->

<!-- Quick view Popup -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="col-md-12 text-center text-info">All Orders</h4>
        <button type="button" class="close" (click)="modal.close('close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="overflow: auto;height: 500px;">
        <div *ngIf="filterOrders().length == 0" class="col-12 text-center section-b-space no-found">
            <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
            <h3>Sorry! Couldn't find any order you are looking for!!! </h3>
        </div>

        <div  *ngIf="filterOrders().length > 0">
            <table class="table table-stripe table-sm">
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Name</th>
                        <th>RFID</th>
                        <th>Ordered Date</th>
                        <th>Price</th>
                        <th class="text-center">Total Units</th>
                        <th>Final Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rowData of filterOrders()">
                        <td> <img alt="" class="img-fluid" [src]="rowData?.product?.images[0]?.src"
                                style="height:50px!important;" onerror="this.src='../../assets/images/no-image.png';">
                        </td>
                        <td>{{rowData?.product?.title}}</td>
                        <td>{{rowData?.name}}</td>
                        <td>{{rowData?.RFID}}</td>
                        <td>{{rowData?.orderDate | date:'d/M/yyyy h:m a'}}</td>
                        <td>{{rowData?.product?.price | currency:'GBP' }}</td>
                        <td class="text-center">{{rowData?.totalQuantity }}</td>
                        <td>{{rowData?.totalAmount | currency:'GBP' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>