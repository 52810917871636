<!-- Login section -->
<section class="login-page section-b-space pb-3 pt-3">
    <div class="container" style="max-width: 500px;">
        <div class="row">
            <div class="col-lg-12">
                <div class="theme-card">
                    <div *ngIf="isLoggedIn" class="mb-3">
                        <a (click)="goBack()"><i class="fa mr-2 fa-arrow-left"></i>Back</a>
                    </div>
                    <div *ngIf="userName != ''" class="alert alert-success text-center">
                        <img [src]="userImage" class="mb-2" onerror="this.src='../../assets/images/noimage.jpg';"
                            style="border-radius: 50%;width:80px;height:80px;">
                        <h3 class="mb-0 text-center"> {{userName}}</h3>
                    </div>
                    <div *ngIf="paymentMsg != ''"
                        [ngClass]="isLoggedIn ? 'alert alert-danger text-center' : 'alert alert-success text-center'">
                        <h5 class="pb-0 "> {{paymentMsg}}</h5>
                    </div>
                    <div *ngIf="isLoggedIn" class="row mt-2 mb-4">
                        <div class="col-md-12">
                            <h4>
                                <span class="border border-dark float-left p-2 "><i class="fa fa-credit-card mr-2"></i>
                                    <span class="text-success">£{{userData?.balance}}</span> </span>
                                <span class="border border-dark float-right  p-2">Fee: <span
                                        class="text-success">£{{pricingData?.price}}</span></span>
                            </h4>
                        </div>
                    </div>
                    <form *ngIf="!isLoggedIn" class="theme-form">
                        <div class="form-group">
                            <h5 for="email" class="text-center">Enter RFID for Entry</h5>
                            <input type="text" autocomplete="off" #RFID class="form-control text-uppercase"
                                [(ngModel)]="rfid" name="rfid" placeholder="RFID" required>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-block btn-default primary-btn radius-0"
                                (click)="validateRFID(rfid)" [disabled]="isDisabled">Go</button>
                        </div>
                    </form>
                    <form *ngIf="isLoggedIn" class="theme-form">
                        <div class="mb-3">
                            <form class="theme-form text-center">
                                <div class="form-group">
                                    <label for="email">Top up Amount in CASH:</label>
                                </div>
                                <div class="mb-3">
                                    <a (click)="topUpAmount(5)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £5</span></a>
                                    <a (click)="topUpAmount(10)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £10</span></a>
                                    <a (click)="topUpAmount(20)" [disabled]="isDisabled"><span
                                            class="badge badge-pill badge-info mr-3" style="font-size: 20px;">+
                                            £20</span></a>
                                </div>
                            </form>
                        </div>
                        <hr class="hr-text" data-content="OR">
                        <div>
                            <button (click)="payInCash()" [disabled]="isDisabled"
                                class="btn btn-block btn-default bg-success primary-btn radius-0">Paid
                                in
                                Cash £{{pricingData?.price}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end Login section -->