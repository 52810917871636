<div class="container">
    <div class="row">
        <div class="col-md-6 mb-5 mt-5 mx-auto">
            <div class="payment">
                <div class="payment_header bg-dark">
                    <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                </div>
                <div class="content pt-5 text-center">
                    <h2>Payment Success!</h2>
                    <h5>Payment Reference Id : {{paymentId}}</h5>
                    <a class="btn btn-success mt-3 rounded-pill" routerLink="/{{clubName}}/wallet">Go to Wallet</a>
                </div>
            </div>
        </div>
    </div>
</div>