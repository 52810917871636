import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  userData: any;
  orderData = [];

  constructor(public toastService: ToastrService,
    private firestore: AngularFirestore,
  ) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.firestore.collection('orders', ref => ref.where('uid', '==', this.userData.uid).orderBy("orderDate", "desc")).get()
      .subscribe(snapshot => {
        snapshot.forEach(doc => {
          let sData = doc.data();
          sData.orderDate = new Date(sData.orderDate.seconds * 1000);
          sData.id = doc.id;
          this.orderData.push(sData);
        });
      });
  }

}
