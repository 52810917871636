import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-club-home',
  templateUrl: './club-home.component.html',
  styleUrls: ['./club-home.component.scss']
})
export class ClubHomeComponent implements OnInit {
  clubData: any;
  clubName = "";
  show = -1;
  club = "";
  isSignIn = false;
  icons = ["rocket-ship", "diamond", "pie-chart", "rocket-ship", "diamond", "pie-chart"];
  pricingcarouselOptions = {
    items: 4,
    margin: 10,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      480: {
        items: 2,
        margin: 10
      },
      992: {
        items: 4,
        margin: 10
      }
    }
  }

  trainercarouselOptions = {
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    center: true,
    loop: true,
    dots: true,
    margin: 0,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      576: {
        items: 3
      },
      768: {
        items: 4
      },
      1200: {
        items: 6
      }
    }
  }
  public qrdata: string = null;
  public elementType: "img" | "url" | "canvas" | "svg" = null;
  public level: "L" | "M" | "Q" | "H";
  public scale: number;
  public width: number;
  sponsorsData = [];
  sponsorshipUsersData = [];
  coachData = [];
  boxingUrl = "assets/images/logo/boxing.png";

  constructor(public firestore: AngularFirestore, public route: ActivatedRoute,
    public toastService: ToastrService, public router: Router
  ) {
    this.route.params.subscribe(params => {
      let clubname = params['clubName'];
      this.clubName = clubname;
      let name = clubname.replace(/-/g, ' ');
      this.club = name;
      this.getClubData(name, clubname);
      this.elementType = "img";
      this.level = "M";
      this.qrdata = "https://app.pamit.co.uk/" + clubname + "/register";
      this.scale = 1;
      this.width = 175;
    });

  }

  ngOnInit(): void {
    let user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      this.isSignIn = true;
    }
  }

  getClubData(clubname, clubUrl) {
    this.firestore.collection("clubs").get()
      .subscribe(snapshot => {
        for (let i = 0; i < snapshot.docs.length; i++) {
          let data = snapshot.docs[i].data();
          data.id = snapshot.docs[i].id;
          if (data.clubDetails.name.toString().toLowerCase() == clubname.toString().toLowerCase()) {
            this.clubData = data;
            localStorage.setItem("clubName", clubUrl);
            localStorage.setItem("clubId", data.id);
            localStorage.setItem("logoUrl", data.clubDetails.logoUrl);
            this.getSponsorshipUsers(data.id);
            this.getSponsors(data.id);
            break;
          } else {
            if (i == (snapshot.docs.length - 1))
              this.router.navigate(['/404']);
          }
        }
      });
  }

  getSponsorshipUsers(clubId) {
    this.firestore.collection("users", ref => ref.where('clubId', '==', clubId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          if (data.isDelete == false) {
            if (data.roles == "coach" && data.imageUrl != '' && data.imageUrl) {
              this.coachData.push(data);
            }

            if (data.sponsorship && data.sponsorship == true) {
              this.firestore.collection('sponsorship', ref => ref.where('userId', '==', data.uid).limit(1)).get()
                .subscribe(snapshot => {
                  if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                      let sData = doc.data();
                      data.publicUrl = sData.publicUrl;
                      data.height = sData.height;
                      data.weight = sData.weight;
                      var div = document.createElement("div");
                      div.innerHTML = sData.description;
                      var text = div.textContent || div.innerText || "";
                      data.description = text;
                      data.facebookLink = sData.facebookLink;
                      data.amount = sData.amount;
                      data.instagramLink = sData.instagramLink;
                      data.websiteLink = sData.websiteLink;
                      data.otherLink = sData.otherLink;
                      data.strengths = sData.strengths;
                      data.images = sData.images;
                    });
                  }
                  let raisedAmount = 0; let donorsCount = 0;
                  this.firestore.collection('donors', ref => ref.where('userId', '==', data.uid)).get()
                    .subscribe(snapshot => {
                      if (!snapshot.empty) {
                        snapshot.forEach(doc1 => {
                          let dData = doc1.data();
                          if (dData.status == "succeeded" || dData.status == "cash") {
                            dData.active = dData.active != undefined ? dData.active : true;
                            if (dData.active == true) {
                              raisedAmount += dData.amount;
                              donorsCount += 1;
                              data.raisedAmount = raisedAmount;
                              data.donorsCount = donorsCount;
                            }
                          }
                        });
                      }
                      this.sponsorshipUsersData.push(data);
                    });
                });
            }
          }
        });
      });
  }

  getSponsors(clubId) {
    this.firestore.collection("sponsors", ref => ref.where('clubId', '==', clubId)).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          this.sponsorsData.push(data);
        });
      });
  }

  saveShow(index) {
    this.show = this.show == index ? -1 : index;
  }
}
