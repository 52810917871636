import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-club-coaches',
  templateUrl: './club-coaches.component.html',
  styleUrls: ['./club-coaches.component.scss']
})
export class ClubCoachesComponent implements OnInit {
  coachData = [];
  constructor(public firestore: AngularFirestore,) { }

  ngOnInit(): void {
    this.getCoachData();
  }

  getCoachData() {
    this.firestore.collection("users", ref => ref.where('clubId', '==', localStorage.getItem("clubId"))).get()
      .subscribe(snapshot => {
        snapshot.forEach(e => {
          let data = e.data();
          if (data.isDelete == false) {
            if (data.roles == "coach" && data.imageUrl != '' && data.imageUrl) {
              this.coachData.push(data);
            }
          }
        });
      });
  }
}
