import { Injectable, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { User } from '../user';
@Injectable({
    providedIn: 'root'
})

export class RegisterService {
    userData: any;

    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public router: Router,
        public toastService: ToastrService,
        public ngZone: NgZone
    ) {
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        })
    }

    async SignUp(email, password, confirmPassword, firstName, lastName, clubId, fillingType, over18, parentsName, title) {
        let isAge = await this.checkAge(over18, parentsName);
        if (!title)
            this.toastService.error('Title is required', 'Error');
        else if (!firstName)
            this.toastService.error('First name is required', 'Error');
        else if (!lastName)
            this.toastService.error('Last name is required', 'Error');
        else if (!email)
            this.toastService.error('Email is required', 'Error');
        else if (!password)
            this.toastService.error('Password is required', 'Error');
        else if (!confirmPassword)
            this.toastService.error('Confirm Password is required', 'Error');
        else if (password != confirmPassword)
            this.toastService.error('Passwords not match', 'Error');
        else if (!clubId)
            this.toastService.error('Club name is required', 'Error');
        else if (isAge)
            this.toastService.error('Parent name is required', 'Error');
        else {
            this.afs.collection('clubs').doc(clubId).get()
                .subscribe(clubResult => {
                    if (clubResult.exists) {
                        return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
                            .then((result) => {
                                this.afs.collection('users', ref => ref.where('clubId', '==', clubId).limit(1).orderBy('norfid', 'desc')).get()
                                    .subscribe(snapshot => {
                                        if (snapshot.empty) {
                                            let userR = {
                                                RFID: 0
                                            }
                                            this.SetUserData(result.user, firstName, lastName, clubId, clubResult.data(), userR, fillingType, over18, parentsName, title);
                                        } else {
                                            snapshot.forEach(doc1 => {
                                                this.SetUserData(result.user, firstName, lastName, clubId, clubResult.data(), doc1.data(), fillingType, over18, parentsName, title);
                                            });
                                        }
                                    });

                            }).catch((error) => {
                                var errorCode = error.code;
                                var errorMessage = error.message;
                                if (errorCode == 'auth/email-already-in-use') {
                                    this.toastService.error(errorMessage, 'Error');
                                } else {
                                    this.toastService.error(errorMessage, 'Error');
                                }
                            })

                    } else {
                        this.toastService.error("Something went wrong. Please try again.", 'Error');
                    }
                });
        }
    }

    checkAge(over18, parentsName) {
        if (!over18) {
            if (!parentsName)
                return true;
            else return false;
        } else return false;
    }

    SendVerificationMail() {
        return this.afAuth.auth.currentUser.sendEmailVerification()
            .then(() => {
                this.router.navigate(['verify-email']);
                this.toastService.success('Verfication email send, check your inbox.', 'Success');
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }

    ForgotPassword(passwordResetEmail) {
        return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                this.toastService.success('Password reset email sent, check your inbox.', 'Success');
            }).catch((error) => {
                this.toastService.error(error.message, 'Error');
            })
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false) ? true : false;
    }

    SetUserData(user, firstName, lastName, clubId, clubdata, userR, fillingType, over18, parentsName, title) {
        let club = clubdata.planDetails.planDetails.filter(a => a.plan == "Incomplete");
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        this.afs.collection("users", ref => ref.where('clubId', '==', clubId)).get().subscribe(snapshot => {
            if (snapshot.empty) {
                this.toastService.error("Something went wrong. Please try again.", 'Error');
                this.removeUserFromFirebase();
            } else {
                const userData: User = {
                    title: title,
                    uid: user.uid,
                    email: user.email,
                    phoneNo: '',
                    firstName: firstName,
                    lastName: lastName,
                    photoURL: user.photoURL,
                    emailVerified: user.emailVerified,
                    roles: 'clubUser',
                    clubId: clubId,
                    RFID: (snapshot.size + 1) + '',
                    norfid: snapshot.size + 1,
                    group: club.length > 0 ? club[0].plan : clubdata.planDetails.planDetails[0].plan,
                    groupType: clubdata.groupType.groupType[0].name,
                    club_rfid: clubId + '_' + (snapshot.size + 1),
                    balance: 0,
                    currency: 'GBP',
                    isDelete: false,
                    isActive: false,
                    over18: over18,
                    parentsName: over18 ? '' : parentsName,
                    fillingType: fillingType,
                    accountDetailsAddedDate: null,
                    accountDetailsUpdatedDate: null,
                    medicalDetailsAddedDate: null,
                    medicalDetailsUpdatedDate: null,
                    membershipDetailsAddedDate: null,
                    membershipDetailsUpdatedDate: null,
                    createdDate: new Date(),
                    deletedDate: null
                }
                return userRef.set(userData, {
                    merge: true
                }).then((result) => {
                    this.toastService.success('Successfully registered', 'Success');
                    // this.afs.collection("wallet").add({
                    //     uid: user.uid,
                    //     balance: 0,
                    //     currency: "GBP"
                    // });
                    this.SendVerificationMail();
                }).catch((error) => {
                    this.toastService.error(error.message, 'Error');
                    this.removeUserFromFirebase();
                });
            }
        })
    }

    removeUserFromFirebase() {
        var user = this.afAuth.auth.currentUser;
        user.delete().then(function () {
        }).catch(function (error1) {
            this.toastService.error(error1.message, 'Error');
        });
    }
}