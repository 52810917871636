// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAehxPHDbNScSrsbLoh98gWmiYlDXebgmQ",
    authDomain: "boxing-frontend.firebaseapp.com",
    databaseURL: "https://boxing-frontend.firebaseio.com",
    projectId: "boxing-frontend",
    storageBucket: "boxing-frontend.appspot.com",
    messagingSenderId: "439486935948",
    appId: "1:439486935948:web:e7f82e8c29c0b370d1dca9",
    measurementId: "G-HG2Z0G7CTT"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
