<div class="container">
    <div class="alert alert-info mt-3">
        <!-- <p>Enable sponsorship settings and fill your personal, social media details. <span class="font-weight-bold">
                Activate sponsorship : <mat-slide-toggle (change)="userUpdate($event)" [(ngModel)]="sponsorship">
                </mat-slide-toggle></span></p> -->
        <p>Sponsorship settings form only available when admin enable sponsorship</p>
        <div id="socialshare">
        </div>
    </div>
    <form *ngIf="sponsorship" [formGroup]="sponsorshipForm" #f="ngForm">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Public Profile URL</mat-label>
                    <mat-icon matPrefix>link</mat-icon>
                    <input matInput placeholder="Public Profile URL" formControlName="publicUrl" readonly required
                        #userinput>
                    <mat-icon matSuffix (click)="copyInputMessage(userinput)">content_copy</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Height (ft)</mat-label>
                    <mat-icon matPrefix>height</mat-icon>
                    <input matInput type="number" placeholder="Height" formControlName="height" required>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Height (ft)</mat-label>
                    <mat-icon matPrefix>height</mat-icon>
                    <input matInput type="number" placeholder="Height" formControlName="height" required>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Weight (kg)</mat-label>
                    <mat-icon matPrefix>fitness_center</mat-icon>
                    <input matInput type="number" placeholder="Weight" formControlName="weight" required>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>How much amount you want to raise?</mat-label>
                    <mat-icon matPrefix>currency_pound</mat-icon>
                    <input matInput type="number" placeholder="" formControlName="amount" required>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Wins</mat-label>
                    <mat-icon matPrefix>emoji_events</mat-icon>
                    <input matInput type="number" placeholder="Wins" formControlName="wins">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Loss</mat-label>
                    <mat-icon matPrefix>emoji_events</mat-icon>
                    <input matInput type="number" placeholder="Loss" formControlName="loss">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Draw</mat-label>
                    <mat-icon matPrefix>emoji_events</mat-icon>
                    <input matInput type="number" placeholder="Draw" formControlName="draw">
                </mat-form-field>
            </div>
            <div class="col-md-12 mb-3">
                <p-fileUpload name="demo[]" (onSelect)="onUpload($event)" multiple="multiple" accept="image/*"
                    maxFileSize="1000000" [showUploadButton]="false" [showCancelButton]="false" customUpload="true">
                    <ng-template pTemplate="content">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let file of displayImages">
                                <img [src]="file" height="100">
                                <button (click)="removeImage(file)" class="btn btn-sm btn-outline-danger ml-2"><i
                                        class="fa fa-remove"></i></button>
                            </div>
                        </div>
                    </ng-template>
                </p-fileUpload>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>About yourself and why you want to raise money?</mat-label>
                    <textarea matInput placeholder="" formControlName="description" required></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>News or other additional information</mat-label>
                    <textarea matInput placeholder="" formControlName="news"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Strengths</mat-label>
                    <mat-icon matPrefix>sports_mma</mat-icon>
                    <input matInput placeholder="Strengths" formControlName="strengths">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Facebook</mat-label>
                    <mat-icon matPrefix>link</mat-icon>
                    <input matInput placeholder="Facebook" formControlName="facebookLink">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Instagram</mat-label>
                    <mat-icon matPrefix>link</mat-icon>
                    <input matInput placeholder="Instagram" formControlName="instagramLink">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Personal Website</mat-label>
                    <mat-icon matPrefix>link</mat-icon>
                    <input matInput placeholder="Personal Website" formControlName="websiteLink">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Twitter</mat-label>
                    <mat-icon matPrefix>link</mat-icon>
                    <input matInput placeholder="Twitter" formControlName="otherLink">
                </mat-form-field>
            </div>
            <div class="col-md-12 mb-90 text-center ">
                <img *ngIf="loading" src="../../assets/images/ajax-loader.gif" height="40">
                <button *ngIf="!loading" (click)="saveSponsorship()" [disabled]="!sponsorshipForm.valid"
                    class="btn btn-primary">Save
                    Details</button>
            </div>
        </div>
    </form>
</div>