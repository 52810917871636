import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-check',
  templateUrl: './booking-check.component.html',
  styleUrls: ['./booking-check.component.scss']
})
export class BookingCheckComponent implements OnInit {
  eventdata: any[] = [];
  bookingData: any[] = [];
  loading: boolean;
  userData: any;
  ticketNo: any;
  constructor(private firestore: AngularFirestore,
    public toastService: ToastrService,) {
    this.userData = JSON.parse(sessionStorage.getItem('user'));
  }

  ngOnInit(): void {
    this.getEventData();
  }

  async getEventData() {
    this.eventdata = [];
    this.bookingData = [];
    (await this.firestore.collection('club_events', ref => ref.where('clubId', '==', this.userData.clubId)).get().toPromise()).forEach(e => {
      let sData = e.data();
      sData.startDate = new Date(sData.startDate.seconds * 1000);
      sData.endDate = new Date(sData.endDate.seconds * 1000)
      sData.id = e.id;
      if (new Date(sData.startDate) >= new Date()) {
        sData.active = true;
        this.eventdata.push(sData);
        this.getBookingData(sData.id, sData.eventName);
      }
    });
  }

  async getBookingData(eventId, name) {
    (await this.firestore.collection('bookings', ref => ref.where('eventId', '==', eventId).where("status", "==", "succeeded").orderBy("isUsed", "desc")).get().toPromise()).forEach(e => {
      let sData = e.data();
      sData.id = e.id;
      sData.eventName = name;
      let totalTickets = 0;
      sData.tickets.forEach(e => {
        totalTickets += Number(e.quantity);
      });
      sData.createdDate = new Date(sData.createdDate.seconds * 1000);
      sData.totalTickets = totalTickets;
      this.bookingData.push(sData);
    });

  }

  async vallidate() {
    if (this.ticketNo == "")
      this.toastService.error('Please enter ticket no.', 'Error');
    else {
      (await this.firestore.collection('bookings', ref => ref.where('bookingId', '==', this.ticketNo.toString().toUpperCase()).where("status", "==", "succeeded")).get().toPromise()).forEach(e => {
        if (e.exists) {
          this.firestore.collection('bookings').doc(e.id).update({ isUsed: true });
          this.toastService.success('Booking details successfully validated', 'Success');
          this.getEventData();
          this.ticketNo = '';
        } else this.toastService.error('Booking details not found', 'Error');
      });
    }
  }

}
