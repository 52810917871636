import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isConnected = true;

  constructor(private connectionService: ConnectionService, private _router: Router) {
    this.connectionService.monitor().subscribe(isConnected => {
      if (!isConnected)
        this._router.navigateByUrl('/offline');
    })
  }
}
