import { Component, OnInit } from '@angular/core';
import { RegisterService } from "../register/register.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [RegisterService]
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  constructor(public rService: RegisterService,public router: Router) { }

  ngOnInit(): void {
  }

  redirectUrl(url) {
    if (localStorage.getItem("clubName"))
      this.router.navigate([localStorage.getItem("clubName") + url]);
    else
      this.router.navigate([url]);
  }

}
