<section class="section-b-space" style="padding:25px 0px!important;">
    <div class="collection-wrapper">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div *ngIf="orderData.length == 0" class="col-sm-12 text-center section-b-space mt-5 no-found">
                            <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                            <h3>Sorry! Couldn't find any order you are looking for!!! </h3>
                        </div>

                        <div *ngIf="orderData.length > 0" class="col-sm-12">
                            <table class="table table-stripe table-sm">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product
                                        </th>
                                        <th>Ordered Date
                                        </th>
                                        <th>Price
                                        </th>
                                        <th class="text-center">Total Unites
                                        </th>
                                        <th>Final Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rowData of orderData">
                                        <td> <img alt="" class="img-fluid" [src]="rowData?.product?.images[0]?.src"
                                                style="height:50px!important;"
                                                onerror="this.src='../../assets/images/no-image.png';"></td>
                                        <td>{{rowData?.product?.title}}</td>
                                        <td>{{rowData?.orderDate | date:'d/M/yyyy h:m a'}}</td>
                                        <td>{{rowData?.product?.price | currency:'GBP' }}</td>
                                        <td class="text-center">{{rowData?.totalQuantity }}</td>
                                        <td>{{rowData?.totalAmount | currency:'GBP' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>