import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class MembershipService {

    constructor(private firestore: AngularFirestore) { }

    AddUserMembership(ethnicity, photography, travelling) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'add');
        return this.firestore.collection('membership').add({
            userId: user.uid,
            ethnicity: {
                ethnicity: ethnicity.ethnicity
            },
            photography: {
                consent: photography.consent,
                //memberName: photography.memberName,
                age: photography.age
            },
            travelling: {
                consent: travelling.consent,
                //accept: travelling.accept
            },
            createdDate: new Date(),
            updatedDate: null
        })
    }

    UpdateUserMembership( ethnicity, photography, travelling, docid) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userUpdate(user.uid, 'update');
        return this.firestore.doc('membership/' + docid).update({
            ethnicity: {
                ethnicity: ethnicity.ethnicity
            },
            photography: {
                consent: photography.consent,
                //memberName: photography.memberName,
                age: photography.age
            },
            travelling: {
                consent: travelling.consent,
                //accept: travelling.accept
            },
            updatedDate: new Date()
        })
    }

    userUpdate(id, action) {
        if (action == 'add') {
            this.firestore.doc('users/' + id).update({
                membershipDetailsAddedDate: new Date(),
            })
        } else {
            this.firestore.doc('users/' + id).update({
                membershipDetailsUpdatedDate: new Date(),
                detailsVerified: false
            })
        }
    }
}